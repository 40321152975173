import {
  Close,
  Dangerous,
  Delete,
  Edit,
  Save,
  UpdateDisabled,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  createSvgIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as ProxySvg } from "../../../assets/proxyIcon.svg";

import { useState } from "react";
import api from "../../../services/api";

const styles = {
  cardContainer: {
    border: "1px solid #5b2e87",
    borderRadius: "5px",
    padding: "16px",
    gap: "8px",
    width: "620px",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  BoxCampo: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-start",
    gap: "2px",
    width: "293px",
    height: "48px",
    padding: "10px 0px",
  },

  TitleCampo: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "primary.main",
    display: "flex",
    alingItens: "center",
  },
  TextCampo: {
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
    marginTop: "6px",
    width: "fit-content",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardForm: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-end",
    gap: "4px",
    height: "48px",
    marginTop: "5px",
  },
  divderForm: { borderBottomWidth: 2, paddingY: "10px" },
  nomeDaMaquina: {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "32px",
    color: "primary.main",
  },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "error.main" },
  updateDisabledIcon: {
    color: "iconsAcesso.customizado",
    fontSize: "32px",
  },
};

export default function CardAcessos(props) {
  const { atualizarMaquinas, excluirMaquinas, maquina, clienteId, produtoId } =
    props;

  const [snackbar, setSnackbar] = useState(null);
  const [editable, setEditable] = useState(false);
  const [nomeDaMaquina, setNomeDaMaquina] = useState(maquina.nomeDaMaquina);
  const [tipo, setTipo] = useState(maquina.tipo);

  const [vm, setVm] = useState(maquina.vm);
  const [ip, setIp] = useState(maquina.ip);
  const [sshPort, setSshPort] = useState(maquina.sshPort);
  const [user, setUser] = useState(maquina.user);
  const [senhaVm, setSenhaVM] = useState(maquina.senhaVm);
  const [senhaRoot, setSenhaRoot] = useState(maquina.senhaRoot);
  const [acesso, setAcesso] = useState(maquina.acesso);
  const [acesso2, setAcesso2] = useState(maquina.acesso2);
  const [atualizacaoDeRisco, setAtualizacaoDeRisco] = useState(
    maquina?.classificacao?.atualizacaoDeRisco
  );

  const [disableButton, setDissableButton] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);

  //modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  function resetCard() {
    setNomeDaMaquina(maquina.nomeDaMaquina);
    setTipo(maquina.tipo);
    setVm(maquina.vm);
    setIp(maquina.ip);
    setSshPort(maquina.sshPort);
    setUser(maquina.user);
    setSenhaVM(maquina.senhaVm);
    setSenhaRoot(maquina.senhaRoot);
    setAcesso(maquina.acesso);
    setAcesso2(maquina.acesso2);
    setAtualizacaoDeRisco(maquina?.classificacao?.atualizacaoDeRisco);
  }

  const ProxyIcon = createSvgIcon(<ProxySvg />);

  async function handleApiUpdate() {
    try {
      setDissableButton(true);
      const response = await api.put("/putMaquina", {
        id: maquina.id,
        tipo: tipo,
        nomeDaMaquina,
        classificacao: { atualizacaoDeRisco },
        vm,
        ip,
        sshPort,
        user,
        senhaVm,
        senhaRoot,
        acesso,
        acesso2,
        acessoProduto: produtoId,
        acessoClientesId: clienteId,
      });
      setDissableButton(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        atualizarMaquinas(response.data);
        //resetCard();
        setEditable(false);

        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        setOpenEditModal(false);
      }
    } catch (error) {
      setDissableButton(false);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  async function handleApiDeleteMaquina() {
    try {
      setDissableButtonDelete(true);
      const response = await api.post("/deleteMaquina", {
        id: maquina.id,
      });
      //atualizar pagina
      setDissableButtonDelete(false);

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });

        excluirMaquinas(response.data);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      setDissableButton(false);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      setDissableButtonDelete(false);
      console.error(error);
    }
  }

  const Campo = (props) => {
    const { title, text } = props;
    return (
      <Box sx={styles.BoxCampo}>
        <Box sx={styles.TitleCampo}>{title}</Box>
        <Box sx={styles.TextCampo}>
          <Tooltip title="Clique para copiar">
            <Typography
              onClick={() => {
                handleCopy(text);
              }}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "290px",
              }}
            >
              {text}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  return (
    <Box sx={styles.cardContainer}>
      <Stack
        direction={"column"}
        gap={"10px"}
        display={"flex"}
        paddingBottom={"10px"}
      >
        <Stack direction={"row"} alignItems="center">
          {!editable && (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              width="586px"
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Box sx={styles.nomeDaMaquina}>{nomeDaMaquina}</Box>
                {maquina?.classificacao?.atualizacaoDeRisco && (
                  <Tooltip title="Atualização de risco">
                    <UpdateDisabled sx={styles.updateDisabledIcon} />
                  </Tooltip>
                )}
                {maquina?.acesso?.includes("-q -D8282") && (
                  <Tooltip title="Proxy HTTP/HTTPS">
                    <ProxyIcon
                      sx={{ color: "iconsAcesso.proritario", fontSize: "32px" }}
                    />
                  </Tooltip>
                )}
                {maquina?.acesso2?.includes("-q -D8282") && (
                  <Tooltip title="Proxy HTTP/HTTPS">
                    <ProxyIcon
                      sx={{ color: "iconsAcesso.proritario", fontSize: "32px" }}
                    />
                  </Tooltip>
                )}
              </Stack>
              <Box>
                <Tooltip
                  title={"Editar"}
                  onClick={() => {
                    setEditable(!editable);
                  }}
                >
                  <IconButton>
                    <Edit color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={"Excluir"}
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  <IconButton>
                    <Delete color={"secondary"} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          )}
          {editable && (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              width="586px"
            >
              <Stack direction={"column"}>
                <Box sx={styles.TitleCampo}>{"Nome"}</Box>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <TextField
                    variant="standard"
                    value={nomeDaMaquina}
                    onChange={(event) => {
                      setNomeDaMaquina(event.target.value);
                    }}
                  />
                  <Tooltip title="Atualização de risco">
                    <p>
                      <IconButton
                        onClick={() => {
                          setAtualizacaoDeRisco(!atualizacaoDeRisco);
                        }}
                      >
                        {atualizacaoDeRisco ? (
                          <UpdateDisabled sx={styles.updateDisabledIcon} />
                        ) : (
                          <UpdateDisabled
                            sx={{
                              fontSize: "32px",
                            }}
                            color="disabled"
                          />
                        )}
                      </IconButton>
                    </p>
                  </Tooltip>
                </Stack>
              </Stack>
              <Box>
                <Tooltip
                  title={"Salvar"}
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                >
                  <IconButton>
                    <Save color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={"Cancelar"}
                  onClick={() => {
                    resetCard();
                    setEditable(false);
                  }}
                >
                  <IconButton>
                    <Close color={"secondary"} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          )}
        </Stack>
        {!editable && (
          <>
            <Stack direction={"row"}>
              <Campo title={"Tipo"} text={maquina.tipo} />
              <Campo title={"Ip"} text={maquina.ip} />
            </Stack>
            <Stack direction={"row"}>
              <Campo title={"SSH port"} text={maquina.sshPort} />
              <Campo title={"User"} text={maquina.user} />
            </Stack>
            <Stack direction={"row"}>
              <Campo title={"Senha"} text={maquina.senhaVm} />
              <Campo title={"Senha root"} text={maquina.senhaRoot} />
            </Stack>
            {maquina.acesso2 ? (
              <Stack direction={"row"}>
                <Campo title={"Acesso"} text={maquina.acesso} />
                <Campo title={"Acesso 2"} text={maquina.acesso2} />
              </Stack>
            ) : (
              <Box
                sx={{
                  ...styles.cardForm,
                  height: "43.74px",
                }}
              >
                <Box sx={{ ...styles.BoxCampo, width: "100%" }}>
                  <Box sx={styles.TitleCampo}>Acesso</Box>
                  <Box sx={styles.TextCampo}>
                    <Tooltip title="Clique para copiar">
                      <Typography
                        onClick={() => {
                          handleCopy(maquina.acesso);
                        }}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "580px",
                        }}
                      >
                        {maquina.acesso}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        {editable && (
          <>
            <Stack direction={"row"}>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>Tipo da máquina</Box>
                <FormControl
                  sx={styles.TextField}
                  variant="standard"
                  style={{ width: "180px" }}
                >
                  <Select
                    value={tipo}
                    label="Tipo"
                    onChange={(event) => {
                      setTipo(event.target.value);
                    }}
                  >
                    <MenuItem value={"manager"}>Manager</MenuItem>
                    <MenuItem value={"database"}>Data Base</MenuItem>
                    <MenuItem value={"worker"}>Worker</MenuItem>
                    <MenuItem value={"proxyy"}>Proxy</MenuItem>
                    <MenuItem value={"outro"}>Outro</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Ip"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={ip}
                    onChange={(event) => {
                      setIp(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"SSH port"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={sshPort}
                    onChange={(event) => {
                      setSshPort(event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"User"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={user}
                    onChange={(event) => {
                      setUser(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Senha"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={senhaVm}
                    onChange={(event) => {
                      setSenhaVM(event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Senha root"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={senhaRoot}
                    onChange={(event) => {
                      setSenhaRoot(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Acesso"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={acesso}
                    onChange={(event) => {
                      setAcesso(event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Acesso 2"}</Box>
                <Box>
                  <TextField
                    variant="standard"
                    value={acesso2}
                    onChange={(event) => {
                      setAcesso2(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </>
        )}
      </Stack>
      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus onClick={handleApiUpdate} disabled={disableButton}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
      >
        <DialogTitle sx={styles.dialogText}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={styles.boxDialogs}>
              <Dangerous color={"error"} sx={styles.dangerousIcon} />
            </Box>
            <span style={styles.dialogText}>
              Uma vez após excluidos os dados não podem ser recuperados. Tem
              certeza que de deseja fazer essa exclusão ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={styles.deleButton}
            onClick={handleApiDeleteMaquina}
            disabled={disableButtonDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
