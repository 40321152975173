import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { io } from "socket.io-client";

//import Home from "./pages/home";
import Layout from "./pages/layout";
import DevPage from "./pages/devPage";
import JsonServices from "./pages/devPage/jsonServices";
import NocPage from "./pages/nocPage";
import Construcao from "./pages/construcao";
import Login from "./pages/login";
import Acessos from "./pages/Acessos";
import Perfil from "./pages/perfil";
//import Acessos from "./pages/Acessos";
import PrimeiroAcesso from "./pages/primeiroAcesso";
import AreaDoCliente from "./pages/areaDoCliente";
import Predefinicoes from "./pages/areaDoCliente/predefinicoes";
import Administracao from "./pages/administracao";
import Clientes from "./pages/nocPage/clientes";
import NocLinks from "./pages/nocPage/nocLinks";
import Relatorios from "./pages/nocPage/relatorios";
import Suporte from "./pages/nocPage/suporte";
import Chat from "./pages/nocPage/suporte/chat";
import DashboardPage from "./pages/nocPage/dashboard";
import EasyReport from "./pages/nocPage/easyReport";
import Execucao from "./pages/nocPage/execucao";
import RelatoriosFlow from "./pages/nocPage/relatoriosFlow";
import EasyFLow from "./pages/nocPage/easyFLow";
import IotAdministracao from "./pages/iot/iotAdministracao";
import IotPreview from "./pages/iot/iotPreview";
import MidiaGrafana from "./pages/nocPage/midiaGrafana";
import Iot from "./pages/iot";
import Zabbix from "./pages/nocPage/zabbix"
import GerenciamentoDeTags from "./pages/nocPage/zabbix/gerenciamentoDeTags"
import ChecaTemplate from "./pages/nocPage/zabbix/VerificaTemplates";
import Filtrohost from "./pages/nocPage/zabbix/filtroDeHost"
import SolicitarRedefinirSenha from "./pages/solicitarRedefinirSenha";
import RedefinirSenha from "./pages/redefinirSenha";
import AcompanhamentoNoc from "./pages/nocPage/acompanhamento";
//import CriaUsuario from "./pages/criaUsuario";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import { socket } from "./services/socket";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [checkLogin, setCheckLogin] = useState(false);
  const [colorMode, setColorMode] = useState(prefersDarkMode);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("3"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []);

  useEffect(() => {

    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(true);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }
    if (!checkLogin) {
      if (isConnected) socket.disconnect();
    }

    if (checkLogin) {
      socket.connect();
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("connect_error", connectErrorEvent);
      socket.io.on("reconnect_attempt", reconnectAttemptEvent);
      socket.io.on("reconnect_error", reconnectErrorEvent);
      socket.io.on("reconnect", reconnectEvent);
      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("connect_error", connectErrorEvent);
        socket.io.off("reconnect_attempt", reconnectAttemptEvent);
        socket.io.off("reconnect_error", reconnectErrorEvent);
        socket.io.off("reconnect", reconnectEvent);
      };
    }
  }, [checkLogin]);
  //temas
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: !colorMode ? "light" : "dark",
          primary: {
            main: !colorMode ? "#5B2E87" : "#A77AD2",
          },
          secondary: {
            main: "#e83750",
          },
          drawer: {
            chat: !colorMode ? "#fff " : "#000000",
          },
          background: {
            default: !colorMode ? "#F0F2F5" : "#141617",
            paper: !colorMode ? "#fff " : "#121212",
            paperChat: !colorMode ? "#F9FAFB" : "#353535",
            paperOutlined: !colorMode ? "#fff " : "#252525",
            paperNotification: !colorMode ? "#f1f1f1" : "#1d2021",
          },
          chip: {
            pending: !colorMode ? "#E83750" : "#A61328",
            inProgress: !colorMode ? "#ED6C02" : "#BE5602",
            completed: !colorMode ? "#2E7D32" : "#387F4D",
            waitingReview: !colorMode ? "#0288D1" : "#077CB1",
            waitingReturn: !colorMode ? "#5B2E87" : "#502876",
            arquive: !colorMode ? "#777777" : "#424242",
          },
          iconsAcesso: {
            proritario: !colorMode ? "#0288D1" : "#077CB1",
            exigente: !colorMode ? "#E83750" : "#A61328",
            customizado: !colorMode ? "#ED6C02" : "#BE5602",
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            smd: 800,
            md: 900,
            lg: 1200,
            xl: 1536,
            fh: 1920,
            qhd: 2560,
          },
        },
        components: colorMode && {
          MuiButton: {
            styleOverrides: {
              containedPrimary: { backgroundColor: "#5B2E87" },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: { backgroundColor: "#000000" },
            },
          },
        },
      }),
    [colorMode]
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/login"
              element={<Login />}
            />
            <Route
              exact
              path="/resetPassword/:token/:id"
              element={<RedefinirSenha />}
            />

            <Route
              exact
              path="/resetPassword"
              element={<SolicitarRedefinirSenha />}
            />
            <Route path="*" element={<Navigate replace to="/login" />} />
            <Route
              element={
                <Layout
                  socket={socket}
                  colorMode={colorMode}
                  setColorMode={setColorMode}
                  setCheckLogin={setCheckLogin}
                />
              }
            >
              <Route path="/dev" element={<DevPage />}>
                <Route path="/dev/jsonServices" element={<JsonServices />} />
              </Route>
              <Route path="/iot" element={<Iot />}>
                <Route
                  path="/iot/administracao"
                  element={<IotAdministracao />}
                />
                <Route
                  path="/iot/previewProduto/:id"
                  element={<IotPreview />}
                />
              </Route>
              <Route path="/vendar" element={<Construcao />} />
              <Route path="/financeiro" element={<Construcao />} />
              <Route path="/previewProduto/:id" element={<IotPreview />} />

              <Route element={<NocPage />} path={"/noc"}>
                <Route
                  path={"/noc" || "noc/*"}
                  element={<Navigate replace to="/noc/home" />}
                />
                <Route path="/noc/home" element={<NocLinks />} />
                <Route path="/noc/grafana" element={<Construcao />} />
                <Route path="/noc/zabbix" element={<Zabbix />} >
                  <Route path="/noc/zabbix/verificarTemplates" element={<ChecaTemplate />} />
                  <Route path="/noc/zabbix/gerenciamentoDeTags" element={<GerenciamentoDeTags />} />
                  <Route path="/noc/zabbix/filtroDeHosts" element={<Filtrohost />} />
                </Route>



                <Route path="/noc/midiaGrafana" element={<MidiaGrafana />} />
                <Route
                  path="/noc/acompanhamento"
                  element={<AcompanhamentoNoc />}
                />

                <Route path="/noc/clientes" element={<Clientes />}>
                  <Route path="/noc/clientes/acessos" element={<Acessos />} />
                  <Route
                    path="/noc/clientes/relatorios"
                    element={<Relatorios />}
                  />

                  <Route
                    path="/noc/clientes/suporte"
                    element={<Suporte socket={socket} />}
                  >
                    <Route
                      path="/noc/clientes/suporte/:chatId"
                      element={<Chat />}
                    />
                  </Route>

                  <Route
                    path="/noc/clientes/dashboard"
                    element={<DashboardPage />}
                  />
                  <Route
                    path="/noc/clientes/areaDoCliente"
                    element={<AreaDoCliente />}
                  />
                  <Route
                    path="/noc/clientes/easyReport"
                    element={<EasyReport />}
                  />
                </Route>
                <Route path="/noc/execucao" element={<Execucao />}>
                  <Route
                    path="/noc/execucao/centralDeInstrucoes"
                    element={<Predefinicoes />}
                  />
                </Route>
                <Route path="/noc/easyFlow" element={<EasyFLow />}>
                  <Route
                    path="/noc/easyFlow/relatoriosFlow"
                    element={<RelatoriosFlow />}
                  />
                </Route>
              </Route>
              <Route path="/administracao" element={<Administracao />} />
              <Route path="/perfil" element={<Perfil />} />
            </Route>
            <Route path="/primeiroAcesso" element={<PrimeiroAcesso />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
