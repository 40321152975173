import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function Iot(params) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/iot/administracao");
  }, []);
  return (
    <Box sx={{ mt: 5 }}>
      <Outlet />
    </Box>
  );
}
