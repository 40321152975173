import {
  InputAdornment,
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Chip,
  Link,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import imgEmpty from "../../../../assets/wallet.svg";

import SearchIcon from "@mui/icons-material/Search";

import { Tooltip } from "@mui/material";
import { Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Lista from "./list";
import CachedIcon from "@mui/icons-material/Cached";

export default function InterfaceExibicao({
  data = [],
  link,
  selectTemplate,
  templateSeleted,
  setUpdate,
}) {
  const [list, setList] = useState([]);
  const [funcoes, setFuncoes] = useState([]);
  const [fabricantes, setFabricantes] = useState([]);
  const [funcaoSelected, setFuncaoSelected] = useState("todos");
  const [fabricanteSelected, setFabricanteSelected] = useState("todos");
  const [rows, setRows] = useState(null); // essa variavel recebe de list, porém se eu user ela diretramente em map, não renderiza
  const [row, setRow] = useState(null); // por isso eu uso rows para pegar os dados e salvo em row
  const [typeData, setTypedata] = useState("");
  const [searchText, setSearchText] = useState(templateSeleted || "");
  const [checkList, setcheckList] = useState([]);
  const [checkFull, setcheckFull] = useState(false);
  const theme = useTheme();

  const styles = {
    backgroundTagCard: {
      background: theme.palette.mode == "dark" ? "rgba(0,0,0,0.5)" : "#5B2E87",
      color: "white",
    },
    tagTipoTemplate: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      fontSize: "11px",
      borderRadius: "3px",
    },
    itemContentTemplate: {
      color: "GrayText",
      fontSize: "13px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "240px",
    },
    itemTituloTemplate: {
      mt: 1,
      fontSize: "13px",
      color: "invert(100%)",
      width: "100%",
      textAlign: "center",
    },
    itemTituloKey: {
      fontSize: "13px",
      color: "invert(100%)",
      width: "100%",
      textAlign: "left",
    },
    contentItens: {
      display: "flex",
      flexDirection: "column",
      height: "590px",
      mt: 1.5,
      ml: 2.5,

      width: "75%",
    },
    contentItensCards: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      overflow: "auto",
      gap: 1.5,
      maxHeight: "530px",
    },
    chipAlertItem: {
      bgcolor: "chip.inProgress",
      color: "white",

      height: "20px",
    },
    chipAlertVersion: {
      bgcolor: "chip.waitingReview",
      color: "white",

      height: "20px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  };
  function updateList() {
    let lists = data.map((item) => ({ nome: item.name, ...item }));
    setList(lists);
    return lists;
  }
  function addFullChecklist(lists) {
    if (data[0]?.items != undefined) {
      setcheckList(
        lists
          .filter((l) => l.status === 1 || l.status === 3)
          .map((item) => ({ idDefault: item.idDefault, name: item.name }))
      );
    } else if (data[0]?.templates != undefined) {
      let listTemp = lists
        .filter((l) => l.status === 1)
        ?.map((item) => ({
          hostId: item.hostId,
          templates: item.templates,
        }));

      setcheckList(listTemp);
    }
    setcheckFull(true);
  }
  function removeFullChecklist() {
    setcheckList([]);
  }
  useEffect(() => {
    // isso garante que ao filtar os dadso o array que enviado para a atualização tb seja atualizado

    setcheckList(
      filterList.filter((item) => item.status == 1 || item.status == 3)
    );
    setcheckFull(true);
  }, [searchText, funcaoSelected, fabricanteSelected]);

  useEffect(() => {
    if (data.length > 0) {
      if (data[0]?.items != undefined) {
        setTypedata("Templates");
      } else if (data[0]?.templates != undefined) {
        setTypedata("Hosts");
      }

      let lista = updateList();
      addFullChecklist(lista);
    }
  }, [data]);

  useEffect(() => {
    if (typeData == "Hosts") {
      let funcoesDisp = [];
      let fabDisp = [];
      list?.map((host) => {
        if (!funcoesDisp.some((fun) => fun == host.funcao)) {
          funcoesDisp.push(host.funcao);
        }
        if (!fabDisp.some((fab) => fab == host.fabricante)) {
          fabDisp.push(host.fabricante);
        }
      });
      setFuncoes(funcoesDisp);
      setFabricantes(fabDisp);
    }
  }, [list]);

  const buscar = (name) => {
    setSearchText(name);
  };
  const filterList = list.filter((row) =>
    searchText.length > 2
      ? row?.nome?.toLowerCase().indexOf(searchText.toLowerCase()) != -1
      : true &&
        (funcaoSelected != "todos" ? funcaoSelected == row?.funcao : true) &&
        (fabricanteSelected != "todos"
          ? fabricanteSelected == row?.fabricante
          : true)
  );

  useEffect(() => {
    if (typeData === "Hosts") {
      let row = rows || {};
      let templates = [];

      templates = row?.templates?.map((temp, index) => {
        return {
          id: index,
          idCliente: temp.idCliente,
          name: temp.name,
          statusTemp: temp.statusTemp,
          error: temp.error != undefined || row.status < 2,
          status:
            temp.status == 0
              ? ["Nenhuma", "chip.completed"]
              : temp.status == 1 || temp.status == 3
              ? temp.versaoCliente !== "Não encontrado"
                ? ["Atualização Disponível", "chip.waitingReview"]
                : ["Vinculação", "chip.inProgress"]
              : ["Verifique o template", "chip.inProgress"],

          versaoCliente:
            typeof temp.versaoCliente === "string"
              ? temp.versaoCliente != ""
                ? [
                    temp.versaoCliente.split("-").pop().replace("V", ""),
                    "chip.waitingReturn",
                  ]
                : ["Não disponível", "chip.waitingReturn"]
              : [temp.versaoCliente.erro, "chip.inProgress"],
          versaoPredefinicao:
            temp.versaoPredefinicao !== undefined
              ? [
                  temp.versaoPredefinicao.split("-").pop().replace("V", ""),
                  "chip.waitingReturn",
                ]
              : ["Não encontrado", "chip.inProgress"],
        };
      });
      row["templatesMod"] = templates;
      setRow(row);
    } else if (typeData === "Templates") {
      let row = rows || {};
      let todos = [];

      rows?.macros?.map((macro) => {
        todos.push({
          type: "MACRO",
          cliente: macro.macro_cliente,
          default: macro.macro_default,
        });
      });

      rows?.items?.map((item) => {
        todos.push({
          type: "ITEM",
          name: item.name,
          cliente: item.key_cliente,
          default: item.key_default,
        });
      });

      rows?.triggers?.map((item) => {
        todos.push({
          type: "TRIGGER",
          cliente: item.trigger_cliente,
          default: item.trigger_default,
        });
      });

      rows?.discoveries?.map((item) => {
        todos.push({
          type: "REGRA",
          cliente: item.discovery_cliente,
          default: item.discovery_default,
        });
      });

      todos.sort((a, b) => {
        if (
          (a.cliente !== a.default && b.cliente === b.default) ||
          (a.cliente !== a.default && b.cliente === b.default)
        ) {
          return -1; // Move a para o topo
        } else if (
          (a.cliente === a.default && b.cliente !== b.default) ||
          (a.cliente === a.default && b.cliente !== b.default)
        ) {
          return 1; // Move b para o topo
        } else {
          return 0; // Mantém a ordem atual
        }
      });

      row["merge"] = todos;
      setRow(row);
    }
  }, [searchText, rows]);

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };
  const [tabSelect, setTabSelect] = React.useState("1");

  useEffect(() => {
    // verifica o template/host buscado em qual das listas está e o direciona ate lá
    if (searchText.length < 3) return;
    let status;
    if (templateSeleted) {
      status = list?.filter(
        (row) => row?.name?.toLowerCase() === searchText.toLowerCase()
      )[0]?.status;
    } else {
      status = list?.filter(
        (row) =>
          row?.name?.toLowerCase().indexOf(searchText.toLowerCase()) != -1
      )[0]?.status;
    }
    if (status == undefined) return;

    if (status !== 1 && status !== 3) setTabSelect("" + status);
    else setTabSelect("1");
  }, [searchText, list]);

  const updateListChecked = (row) => {
    if (
      checkList.some((check) =>
        typeData !== "Hosts"
          ? row.idDefault === check.idDefault
          : row.hostId === check.hostId
      )
    ) {
      let checkListCopy = [];
      checkListCopy = checkList.filter((check) =>
        typeData !== "Hosts"
          ? row.idDefault !== check.idDefault
          : row.hostId !== check.hostId
      );
      setcheckList(checkListCopy);
    } else {
      setcheckList((value) => [
        ...value,
        typeData !== "Hosts"
          ? { idDefault: row.idDefault, name: row.name }
          : { hostId: row.hostId, templates: row.templates },
      ]);
    }
  };

  return (
    <Stack direction="row">
      <Paper
        sx={{
          height: "590px",
          mt: 1.5,
          width: "30%",
          padding: "10px 20px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            mt={1}
            justifyContent={"space-between"}
          >
            <Typography color="primary" fontSize="22px">
              {typeData}
            </Typography>
            <Chip
              size="small"
              label={list.length + " encontrados"}
              sx={[
                {
                  ml: 1,
                  height: "25px",
                },
                styles.chipAlertItem,
              ]}
            />
          </Stack>

          <TextField
            sx={{ mt: "2px", mb: "10px" }}
            label="Buscar"
            variant="standard"
            //disabled={}
            id="outlined-adornment-amount"
            value={searchText}
            onChange={(e) => buscar(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {typeData === "Hosts" && (
            <Stack direction="row" spacing="10px" mt={1.3} mb={1}>
              <FormControl variant="standard" sx={{ width: "50%", mt: "0px" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Agrupe por fabricante:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={fabricanteSelected}
                  onChange={(value) =>
                    setFabricanteSelected(value.target.value)
                  }
                  label="Age"
                >
                  <MenuItem value={"todos"}>Todos</MenuItem>
                  {fabricantes.map((func, i) => (
                    <MenuItem key={func + i} value={func}>
                      {func}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ width: "50%", mt: "0px" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Agrupe por função:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={funcaoSelected}
                  onChange={(value) => setFuncaoSelected(value.target.value)}
                  label="Age"
                >
                  <MenuItem value={"todos"}>Todas</MenuItem>
                  {funcoes.map((func, i) => (
                    <MenuItem key={func + i} value={func}>
                      {func}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            sx={{ mb: "5px" }}
            value={tabSelect}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              value="1"
              label={
                <Stack direction="row">
                  <Typography>Atualizaveis</Typography>
                  <Chip
                    size="small"
                    label={
                      filterList.filter(
                        (item) => item.status == 1 || item.status == 3
                      ).length
                    }
                    sx={{
                      ml: 1,

                      height: "22px",
                    }}
                  />
                </Stack>
              }
            />
            <Tab
              value="2"
              label={
                <Stack direction="row">
                  <Typography>Alertas</Typography>
                  <Chip
                    size="small"
                    label={filterList.filter((item) => item.status == 2).length}
                    sx={{
                      ml: 1,

                      height: "22px",
                    }}
                  />
                </Stack>
              }
            />
            <Tab
              value="0"
              label={
                <Stack direction="row">
                  <Typography>Atualizados</Typography>
                  <Chip
                    size="small"
                    label={filterList.filter((item) => item.status == 0).length}
                    sx={{
                      ml: 1,

                      height: "22px",
                      padding: "0px",
                    }}
                  />
                </Stack>
              }
            />
          </Tabs>

          {tabSelect == "0" && (
            <Lista
              key={"lista2"}
              filterList={filterList.filter((item) => item.status == 0)}
              typeData={typeData}
              rowSelected={(rows) => {
                setRows(rows);
              }}
            />
          )}
          {tabSelect == "1" && (
            <Lista
              key={"lista1"}
              filterList={filterList.filter(
                (item) => item.status == 1 || item.status == 3
              )}
              typeData={typeData}
              rowSelected={(rows) => {
                setRows(rows);
              }}
              updateListChecked={updateListChecked}
              listRowscheckeds={checkList}
            />
          )}
          {tabSelect == "2" && (
            <Lista
              key={"lista3"}
              filterList={filterList.filter(
                (item) => item.status == 2 || item.status == 5
              )}
              typeData={typeData}
              rowSelected={(rows) => {
                setRows(rows);
              }}
            />
          )}

          <FormGroup sx={{ marginLeft: "11px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  label="Selecionar todos"
                  checked={checkFull}
                  onChange={() => {
                    setcheckFull(!checkFull);
                    if (!checkFull) addFullChecklist(list);
                    else removeFullChecklist();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Selecionar todos"
            />
          </FormGroup>

          <Button
            variant="contained"
            onClick={() => setUpdate(checkList)}
            sx={{ mt: 1 }}
            disabled={
              filterList.filter((item) => item.status == 1 || item.status == 3)
                .length == 0
            }
          >
            Atualizar {typeData} selecionados
          </Button>
        </Stack>
      </Paper>

      <Box sx={styles.contentItens}>
        <Stack direction="row" alignItems="center">
          {row?.nome ? (
            <>
              <Typography color="primary" fontSize="20px">
                <Tooltip title="Acessar o Zabbix">
                  <>
                    {typeData.slice(0, -1) + " : "}
                    <Link
                      href={
                        link +
                        (typeData === "Templates"
                          ? "/templates.php?filter_name=" +
                            rows?.nome?.replace(/ /g, "+") +
                            "&filter_set=1"
                          : "/hosts.php?filter_host=" +
                            rows?.nome?.replace(/ /g, "+") +
                            "&filter_set=1")
                      }
                      underline="hover"
                      target="blank_"
                    >
                      {rows?.nome}
                    </Link>
                  </>
                </Tooltip>
              </Typography>

              {typeData === "Templates" && (
                <>
                  {row?.descriptionClient != undefined &&
                    row?.descriptionDefault != undefined && (
                      <>
                        <Chip
                          label={
                            "De v" +
                            row?.descriptionClient
                              ?.split("-")
                              .pop()
                              .replace("V", "")
                              .replace(" ", "")
                          }
                          sx={styles.chipAlertVersion}
                        />

                        {row?.descriptionClient != row?.descriptionDefault && (
                          <>
                            <CachedIcon />
                            <Chip
                              label={
                                "Para v" +
                                row?.descriptionDefault
                                  ?.split("-")
                                  .pop()
                                  .replace("V", "")
                                  .replace(" ", "")
                              }
                              sx={styles.chipAlertVersion}
                            />
                          </>
                        )}
                      </>
                    )}

                  {row?.descriptionClient == undefined && (
                    <Chip
                      label={"Não consta no cliente"}
                      sx={styles.chipAlertVersion}
                    />
                  )}
                  {row?.descriptionDefault == undefined && (
                    <Chip
                      label={"Não consta no Default"}
                      sx={styles.chipAlertVersion}
                    />
                  )}
                </>
              )}
            </>
          ) : null}
        </Stack>

        <Box sx={styles.contentItensCards} mt="10px">
          {((typeData === "Hosts" &&
            (row?.templatesMod?.length == 0 ||
              row?.templatesMod?.length == undefined)) ||
            (typeData === "Templates" && row?.merge?.length == 0)) && (
            <Box
              width="100%"
              height="520px"
              sx={{
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                filter: "opacity(0.6)",
              }}
            >
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Vazio!
              </Typography>
            </Box>
          )}

          {typeData === "Templates" && rows != null && (
            <>
              {row?.merge.map((d, index) => {
                return (
                  <Card
                    key={d.cliente + index}
                    sx={{
                      width: "32%",
                      minWidth: "290px",
                      height: "140px",
                      display: "flex",
                      flexDirection: "rows",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          d.cliente != d.default
                            ? d.default == "Não encontrado"
                              ? "secondary.main"
                              : "chip.waitingReview"
                            : "chip.completed",
                        height: "150px",
                        width: "5px",
                      }}
                    />
                    <Box sx={{ padding: "10px", width: "98%" }}>
                      <Stack
                        direction="row"
                        justifyContent="end"
                        height="30px"
                        mb="-15px"
                      >
                        <Box
                          sx={[
                            styles.backgroundTagCard,
                            styles.tagTipoTemplate,
                          ]}
                        >
                          {d.type}
                        </Box>
                      </Stack>
                      {d.name != undefined && (
                        <Typography
                          sx={{
                            fontSize: "18px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            mt: "-30px",
                            mb: "5px",
                            maxWidth: "230px",
                          }}
                        >
                          {d.name}
                        </Typography>
                      )}

                      <Stack direction="column">
                        <Box>
                          <Typography sx={styles.itemTituloKey}>
                            Default:
                          </Typography>
                          <Chip label={d.default} sx={styles.chipAlertItem} />
                        </Box>
                        <Box>
                          <Typography sx={styles.itemTituloKey}>
                            Cliente:
                          </Typography>
                          <Chip label={d.cliente} sx={styles.chipAlertItem} />
                        </Box>
                      </Stack>
                    </Box>
                  </Card>
                );
              })}
            </>
          )}
          {typeData === "Hosts" &&
            row != null &&
            row?.templatesMod?.map((d, index) => {
              return (
                <Card
                  key={d.name + index}
                  sx={{
                    width: "99%",
                    height: "95px",
                    display: "flex",
                    flexDirection: "rows",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: d.status[1],
                      height: "150px",
                      width: "5px",
                    }}
                  />
                  <Box sx={{ padding: "10px 15px", width: "98%" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      height="30px"
                    >
                      <Typography sx={{ fontSize: "19px" }}>
                        <Link
                          underline="none"
                          onClick={() => {
                            selectTemplate(d.name);
                          }}
                          href="#"
                        >
                          {d.name}
                        </Link>
                      </Typography>

                      <Box
                        sx={[
                          styles.backgroundTagCard,
                          styles.tagTipoTemplate,
                          { width: "100px" },
                        ]}
                      >
                        TEMPLATE
                      </Box>
                    </Stack>
                    <Stack direction="row" gap={5} mt="-7px">
                      <Box>
                        <Typography sx={styles.itemTituloTemplate}>
                          Versão no Host:
                        </Typography>
                        <Chip
                          label={d.versaoCliente[0]}
                          sx={{
                            bgcolor: d.versaoCliente[1],
                            color: "white",
                            width: "150px",
                            height: "22px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={styles.itemTituloTemplate}>
                          Versão na Predefinição:
                        </Typography>
                        <Chip
                          label={d.versaoPredefinicao[0]}
                          sx={{
                            bgcolor: d.versaoPredefinicao[1],
                            color: "white",
                            width: "150px",
                            height: "22px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={styles.itemTituloTemplate}>
                          Ação Necessária:
                        </Typography>

                        <Chip
                          label={d.status[0]}
                          sx={{
                            bgcolor: d.status[1],
                            color: "white",

                            height: "22px",
                            width: "150px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={styles.itemTituloTemplate}>
                          Status do Template:
                        </Typography>

                        <Chip
                          label={
                            d.statusTemp == 0 || d.statusTemp == undefined
                              ? "Atualizado"
                              : d.statusTemp == 1 || d.statusTemp == 3
                              ? "Atualização disponível"
                              : "Divergências"
                          }
                          sx={{
                            bgcolor:
                              d.statusTemp == 0 || d.statusTemp == undefined
                                ? "chip.completed"
                                : d.statusTemp == 1 || d.statusTemp == 3
                                ? "chip.waitingReview"
                                : "chip.inProgress",

                            color: "white",

                            height: "22px",
                            width: "160px",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Card>
              );
            })}
        </Box>
      </Box>
    </Stack>
  );
}
