import { Add, Delete, Edit } from "@mui/icons-material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import DeleteDialog from "../../../../componentes/deleteDialog";
import TreeItemFuncao from "./treeItemFuncao";
import api from "../../../../services/api";
import { TreeItem } from "@mui/x-tree-view";

export default function TreeItemFabricante(props) {
  const {
    nodeId,
    label,
    node,
    updateFabricantes,
    deleteFabricante,
    setPredefinicaoId,
    setNomeModelo,
    createPredefinicoes,
    expandeNovoModelo,
    expandeNovaFuncao,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [editNode, setEditNode] = useState(null);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  //
  const [fabricante, setFabricante] = useState(label);
  const [funcao, setFuncao] = useState("");
  const [funcoes, setFuncoes] = useState([...node.Funcoes]);
  //
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  function createFuncoes(novaFuncao) {
    const novoArray = [...funcoes];
    novoArray.push(novaFuncao);
    setFuncoes(novoArray);
    expandeNovaFuncao(node);
  }

  function updateFuncoes(novaFuncao) {
    const novoArray = funcoes.map((obj) => {
      if (obj.id === novaFuncao.id) {
        return { ...obj, ...novaFuncao };
      } else {
        return obj;
      }
    });
    setFuncoes(novoArray);
  }

  function deleteFuncao(removeFuncao) {
    const novoArray = funcoes.map((obj) => {
      if (obj.id !== removeFuncao.id) {
        return obj;
      } else {
        return { id: null, funcao: null, Modelos: [] };
      }
    });
    setFuncoes(novoArray);
  }
  const handleApiDelete = async () => {
    try {
      const response = await api.post("/deleteFabricante", {
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setOpenDeleteModal(false);
        setSnackbar({
          children: "Error: Não foi possível excluir o fabricante",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setFabricante(null);
        setOpenDeleteModal(false);
        deleteFabricante(nodeId);
        //deleteFuncao(funcoes);
      }
    } catch (error) {
      console.log(error);
      setOpenDeleteModal(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };

  const handleApiEdit = async () => {
    try {
      const response = await api.put("/putFabricante", {
        fabricante,
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível editar o Fabricante",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updateFabricantes(response.data);
      }
    } catch (error) {
      console.log(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };
  const handleApiCriaFuncao = async () => {
    try {
      const response = await api.post("/postFuncao", {
        funcao,
        fabricanteId: nodeId,
      });
      if (response.data.status === "Error") {
        setFuncao("");
        setSnackbar({
          children: "Error: Não foi possível adicionar a função",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setFuncao("");
        let aux = response.data;
        aux.Modelos = [];
        createFuncoes(aux);
      }
    } catch (error) {
      console.log(error);
      setFuncao("");
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };

  const handleContextMenu = (event, node) => {
    event.preventDefault();
    event.stopPropagation();
    if (!openCreateModal && !openEditModal && !openDeleteModal) {
      if (
        menuPosition === null ||
        (menuPosition?.top === 0 && menuPosition?.left === 0)
      ) {
        setAnchorEl(event.currentTarget);
        setMenuPosition({ top: event.pageY, left: event.pageX });
      } else {
        handleCloseMenu();
        setAnchorEl("");
      }
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setEditNode(null);
    setMenuPosition(null);
  };

  return (
    <div>
      {fabricante ? (
        <div onContextMenu={(event) => handleContextMenu(event, node.id)}>
          <TreeItem
            style={{ display: "inline-block" }}
            nodeId={nodeId}
            label={fabricante}
            id={nodeId}
            aria-controls="simple-menu"
          >
            {Array.isArray(funcoes)
              ? funcoes.map((funcao, index) => (
                  <TreeItemFuncao
                    key={index}
                    nodeId={funcao.id}
                    label={funcao.funcao}
                    node={funcao}
                    deleteFuncao={deleteFuncao}
                    updateFuncoes={updateFuncoes}
                    setPredefinicaoId={setPredefinicaoId}
                    setNomeModelo={setNomeModelo}
                    createPredefinicoes={createPredefinicoes}
                    expandeNovoModelo={expandeNovoModelo}
                  />
                ))
              : null}
          </TreeItem>

          <Menu
            id="simple-menu"
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorReference="anchorPosition"
            anchorPosition={menuPosition}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenCreateModal(true);
              }}
            >
              <ListItemIcon>
                <Add color="primary" />
              </ListItemIcon>
              <ListItemText>Adicionar Função </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setOpenEditModal(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText>Editar Fabricante</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setOpenDeleteModal(true);
                setAnchorEl(null);
              }}
            >
              {" "}
              <ListItemIcon>
                <Delete color="secondary" />
              </ListItemIcon>
              <ListItemText>Excluir Fabricante</ListItemText>
            </MenuItem>
          </Menu>
          <Dialog
            open={openCreateModal}
            onClose={() => {
              setOpenCreateModal(false);
              setMenuPosition(null);
            }}
          >
            <DialogTitle color={"primary"}>Adicionar Função</DialogTitle>
            <DialogContent>
              <TextField
                id="fabricante"
                variant="standard"
                label="Função"
                value={funcao}
                onChange={(event) => {
                  setFuncao(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenCreateModal(false);
                  setFuncao("");
                  setMenuPosition(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleApiCriaFuncao();
                  setOpenCreateModal(false);
                  setMenuPosition(null);
                }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openEditModal}
            onClose={() => {
              setOpenEditModal(false);
              setMenuPosition(null);
            }}
          >
            <DialogTitle color={"primary"}>Editar fabricante</DialogTitle>
            <DialogContent>
              <TextField
                id="fabricante"
                variant="standard"
                label="Fabricante"
                value={fabricante}
                onChange={(event) => {
                  setFabricante(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenEditModal(false);
                  setFabricante(label);
                  setMenuPosition(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleApiEdit();
                  setOpenEditModal(false);
                  setMenuPosition(null);
                }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <DeleteDialog
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            confirmarDelete={confirmarDelete}
            handleApiDelete={handleApiDelete}
          />
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </div>
      ) : null}
    </div>
  );
}
