import { Box, Divider, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "30px",
    flexDirection: "column",
  },
  // tabPaper:{ro}
};

export default function Clientes() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const pageName = window.location.pathname;

    const pages = [
      "/noc/clientes/acessos",
      "/noc/clientes/relatorios",
      "/noc/clientes/suporte",
      "/noc/clientes/dashboard",
      "/noc/clientes/areaDoCliente",
      "/noc/clientes/easyReport",

    ];
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setValue(index);
      }
    });
  }, [navigate]);
  return (
    <Box sx={styles.container}>
      <Paper
        sx={{
          borderRadius: "15px",
          width: "45vw",
          minWidth: "260px",
          maxWidth: "870px",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Acessos"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/acessos");
            }}
          />

          <Tab
            label={"Relatórios"}
            iconPosition="start"
            value={1}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/relatorios");
            }}
          />
          <Tab
            label={"Suporte"}
            iconPosition="start"
            //disabled={true}
            value={2}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/suporte");
            }}
          />
          <Tab
            label={"Dashboard"}
            iconPosition="start"
            value={3}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/dashboard");
            }}
          />
          <Tab
            label={"Área do Cliente"}
            iconPosition="start"
            value={4}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/areaDoCliente");
            }}
          />
          <Tab
            label={"EasyReport"}
            iconPosition="start"
            value={5}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/clientes/easyReport");
            }}
          />


          <Tab
            label={"Canais de Alerta"}
            iconPosition="start"
            disabled={true}
            value={6}
            sx={styles.tab}
          />
        </Tabs>
      </Paper>

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
