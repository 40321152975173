import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Collapse,
  Typography,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CheckCircle,
  Dangerous,
  Delete,
  Edit,
  Error,
  FilterList,
  Refresh,
  RemoveCircle,
  Settings,
} from "@mui/icons-material";
import { ptBR, DataGrid, GridToolbar } from "@mui/x-data-grid";

import fabricantes from "../fabricantes.json";
import ModalCreateHost from "./createHostModal";
import api from "../../../services/api";
import ModalEditHost from "./editeHostModal";
import mwIcon from "../../../assets/logo4.png";

const stylesInicalPage = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
    marginTop: "10px",
  },
  paper: {
    maxWidth: "1550px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    elevation: 3,
    gap: "20px",
    marginTop: "10px",
  },
  button: { height: "40px", minWidth: "120px", mt: 3, mb: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
};

export default function AreaDoCliente(params) {
  const {
    cliente,
    rows,
    setRows,
    predefinicoes,
    loadingPredefinicoes,
    loadingPage,
    handleApiGetHosts,
    disableButtonCreate,
    acessoProdutoId,
    acessoClientesId,
  } = params;
  const [nome, setNome] = useState("");
  const [ip, setIp] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [coletor, setColetor] = useState("");
  const [funcao, setFuncao] = useState("");
  const [error, setError] = useState("");
  const [proxys, setProxys] = useState([]);
  //
  const [selectedRow, setSelectedRow] = useState({});
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  const [disableButtonEdit, setDissableButtonEdit] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);
  //modal
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleApiDelete() {
    try {
      setDissableButtonDelete(true);
      const response = await api.post("/zabbixDeleteHost", {
        id: selectedRow.id,
        acessoClientesId,
        acessoProdutoId,
      });

      if (response.data.status === "Error") {
        setDissableButtonDelete(false);
        //console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluidos com sucesso",
          severity: "success",
        });

        setDissableButtonDelete(false);
        deleteRow(selectedRow.id);
        setOpenDeleteDialog(false);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      setDissableButtonDelete(false);

      console.error(error);
    }
  }
  const filteredRows = rows.filter((row, index) => {
    row.numero = index;
    //console.log("text", row);
    return (
      row.nome.toLowerCase().includes(nome.toLowerCase()) &&
      row.ip.toLowerCase().includes(ip.toLowerCase()) &&
      row.fabricante.toLowerCase().includes(fabricante.toLowerCase()) &&
      row.funcao.toLowerCase().includes(funcao.toLowerCase()) &&
      row.coletor.toLowerCase().includes(coletor.toLowerCase())
    );
  });

  function deleteRow(id) {
    const novoArray = rows.filter((obj) => obj.id !== id);
    setRows(novoArray);
  }

  function updateRow(hostUpdate) {
    const novoArray = rows.map((obj) => {
      if (obj.id === hostUpdate.id) {
        return hostUpdate;
      } else {
        return obj;
      }
    });
    setRows(novoArray);
  }

  function AddRow(newHost) {
    const novoArray = rows;
    novoArray.push(newHost);
    setRows(novoArray);
  }

  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }

  const handleChangeIp = (event) => {
    const newIp = event.target.value;
    setIp(newIp);
    //setError(validateIp(newIp));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    {
      field: "numero",
      headerName: "N°",
      width: 60,
    },
    {
      field: "nome",
      headerName: "NOME",
      width: 175,
    },

    {
      field: "ip",
      headerName: "IP",
      width: 120,
    },
    {
      field: "portaSnnmp",
      headerName: "PORTA SNMP",
      width: 100,
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 85,
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",

      width: 105,
    },
    {
      field: "fabricante",
      headerName: "FABRICANTE",

      width: 95,
    },
    { field: "funcao", headerName: "FUNÇÃO", width: 100 },
    { field: "modelo", headerName: "MODELO", width: 100, hide: true },
    {
      field: "coletor",
      headerName: "COLETOR",

      width: 130,
    },
    {
      field: "ping",
      headerName: "PING",
      width: 65,
      renderCell: (params) =>
        params.value ? (
          <CheckCircle color="success" />
        ) : (
          <Error color="error" />
        ),
    },
    {
      field: "snmp",
      headerName: "SNMP",
      width: 65,
      type: "",
      renderCell: (params) =>
        params.value === true ? (
          <CheckCircle color="success" />
        ) : params.value === false ? (
          <Error color="error" />
        ) : (
          <RemoveCircle color="disabled" />
        ),
    },
    {
      field: "userSsh",
      headerName: "USER SSH",

      width: 100,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",

      width: 100,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 80,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          hanldeUpDateSelectRow(thisRow);

          setAnchorEl(e.currentTarget);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }

        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Ações"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Settings color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ flexWrap: "wrap" }}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Stack
            direction={"row"}
            gap={"20px"}
            sx={{
              marginTop: 2,
            }}
            flexWrap={"wrap"}
            minWidth={"650px"}
          >
            <TextField
              id="host-nome"
              variant="standard"
              label="Nome"
              value={nome}
              onChange={(event) => {
                setNome(event.target.value);
              }}
              type="text"
            />
            <TextField
              id="ip"
              variant="standard"
              label="IP"
              value={ip}
              onChange={handleChangeIp}
              onBlur={() => setError(validateIp(ip))}
              error={Boolean(error)}
              helperText={error}
            />

            <Autocomplete
              inputValue={fabricante}
              onInputChange={(event, newInputValue) => {
                setFabricante(newInputValue);
              }}
              freeSolo
              options={fabricantes.map((options) => options.fabricante)}
              sx={stylesInicalPage.filter}
              renderInput={(params) => (
                <TextField {...params} label="Fabricante" variant="standard" />
              )}
            />

            <TextField
              id="host-funcao"
              variant="standard"
              label="Função"
              type="text"
              value={funcao}
              onChange={(event) => {
                setFuncao(event.target.value);
              }}
            />
            <FormControl variant="standard" label="Coletor">
              <InputLabel> Coletor</InputLabel>
              <Select
                fullWidth
                label="Coletor"
                value={coletor}
                onChange={(event) => {
                  setColetor(event.target.value);
                }}
                sx={stylesInicalPage.filter}
              >
                <MenuItem value={""}>TODOS</MenuItem>
                <MenuItem value={"ZABBIX SERVER"}>ZABBIX SERVER</MenuItem>
                <MenuItem value={"PROXY"}>PROXY</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Button
          variant="contained"
          sx={stylesInicalPage.button}
          onClick={() => {
            setOpenCreateModal(true);
          }}
          disabled={disableButtonCreate || loadingPredefinicoes}
        >
          CRIAR HOST
        </Button>
      </Stack>
      <Box sx={stylesInicalPage.paper}>
        <DataGrid
          getRowHeight={() => "auto"}
          columns={columns}
          rows={filteredRows}
          autoHeight={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{
            fontSize: 12,
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          initialState={{
            ...rows.initialState,
            pagination: {
              pageSize: 25,
            },
          }}
          //page={1}
          components={{
            Toolbar: GridToolbar,
          }}
          loading={loadingPage}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
        />
      </Box>

      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
        }}
      >
        <DialogTitle>
          {"Deseja realmente salvar os dados editados?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus disabled={disableButtonEdit}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
      >
        <DialogTitle sx={stylesInicalPage.dialogText}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={stylesInicalPage.boxDialogs}>
              <Dangerous color={"error"} sx={stylesInicalPage.dangerousIcon} />
            </Box>
            <span style={stylesInicalPage.dialogText}>
              Uma vez após excluidos os dados não podem ser recuperados. Tem
              certeza que de deseja fazer essa exclusão ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={stylesInicalPage.deleButton}
            disabled={disableButtonDelete}
            onClick={() => {
              handleApiDelete();
            }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      <ModalCreateHost
        predefinicoes={predefinicoes}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        setSnackbar={setSnackbar}
        AddRow={AddRow}
        acessoClientesId={acessoClientesId}
        acessoProdutoId={acessoProdutoId}
        proxys={proxys}
        handleApiGetHosts={handleApiGetHosts}
      />
      <ModalEditHost
        predefinicoes={predefinicoes}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setSnackbar={setSnackbar}
        selectedRow={selectedRow}
        updateRow={updateRow}
        cliente={cliente}
        acessoClientesId={acessoClientesId}
        acessoProdutoId={acessoProdutoId}
        proxys={proxys}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemIcon>
            <Refresh fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Atualizar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenEditModal(true);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Deletar</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
