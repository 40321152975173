import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box, Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Alert,
  Snackbar,
  Button,
  Icon,
  CircularProgress
} from "@mui/material";

import mwIcon from "../../assets/logo4.png";
import { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";


import AccordionGroup from "./accordionGroup";
import api from "../../services/api";
import ModalNovoCliente from "./modalNovoCliente";

const styles = {
  BoxCampo: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-start",
    gap: "4px",
    width: "300px",
    height: "48px",
    minWidth: "960px",
  },

  TitleCampo: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#5B2E87",
    display: "flex",
    alingItens: "center",
  },
  TextCampo: {
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "#5B2E87" },
  checkboxGroup: {
    display: "flex",
    width: "200px",
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "10%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
};



export default function Acessos(props) {
  const [tabValue, setTabValue] = useState("mw");
  const [snackbar, setSnackbar] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingPage, setLoadingPage] = useState(false);

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event, newValue) => {
    setFilterText(newValue || "");
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const filteredOParceiros = clientes.filter((cliente) =>
    cliente.parceiro.toLowerCase() === tabValue.toLowerCase()
  );

  const filteredClientes = Array.isArray(filteredOParceiros)
    ? filteredOParceiros.filter((cliente) =>
      `${cliente.numero} - ${cliente.empresa}`
        .toLowerCase()
        .includes(filterText.toLowerCase())
    )
    : [];

  const indexOfLastCard = currentPage * rowsPerPage;
  const indexOfFirstCard = indexOfLastCard - rowsPerPage;
  const paginatedClientes = filteredClientes.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  async function handleApi() {
    try {
      const response = await api.get("/acessos");
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel salvar dados",
          severity: "error",
        });
      } else {
        setClientes(response.data);
        setLoadingPage(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function atualizarCliente(clientesNovoProduto) {
    const novoArray = clientes.map((obj) => {
      if (obj.id === clientesNovoProduto.id) {
        return clientesNovoProduto;
      } else {
        return obj;
      }
    });
    setClientes(novoArray);
  }

  function deletarCliente(id) {
    const novosClientes = clientes.filter((objeto) => objeto.id !== id);
    setClientes(novosClientes);
  }

  useEffect(() => {
    handleApi();
  }, []);

  useEffect(() => {
    setFilterText("");
  }, [tabValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "flex-start",
          padding: "30px 50px",
        }}
      >
        <Box>
          <Stack justifyContent={"center"}>
            <Paper
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "calc(73vw + 230px)",
                minWidth: "877px",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="MW" value={"mw"} />
                <Tab label="CDM" value={"cdm"} />
                <Tab label="UAI" value={"uai"} />
                <Tab label="IPV7" value={"ipv7"} />
                <Tab label="PRO" value={"PRO"} />
                <Tab label="NTK" value={"ntk"} />
                <Tab label="K3G" value={"k3g"} />
                <Tab label="ADV" value={"adv"} />
                <Tab label="PEV7" value={"pev7"} />
                <Tab label="MM" value={"mm"} />
                <Tab label="PR" value={"pr"} />
                <Tab label="ISPTI" value={"ispti"} />
              </Tabs>

              <Box>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  paddingX="10px"
                >
                  <Autocomplete
                    disablePortal
                    freeSolo
                    options={filteredOParceiros.map(
                      (cliente) => `${cliente.numero} - ${cliente.empresa}`
                    )}
                    sx={{ m: 1, width: "300px" }}
                    value={filterText}
                    onChange={handleFilterChange}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label="Consultar Cliente"
                        fullWidth
                      //InputProps={{
                      //  ...params.InputProps,
                      //  endAdornment: (
                      //    <InputAdornment position="end">
                      //      <Search sx={{ mr: -10, width: 20 }} />
                      //   </InputAdornment>
                      // ),
                      //}}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    NOVO CLIENTE <Add sx={{ mt: -0.4 }} />
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Stack>
          {!loadingPage && (
            <Box sx={styles.LoadingLogo}>
              <Icon style={{ width: 80, height: 80 }}>
                <img
                  src={mwIcon}
                  style={{ width: 80, height: 80 }}
                  alt="mw icon"
                />
              </Icon>

              <CircularProgress
                size={100}
                sx={styles.CircularProgress}
                thickness={2.5}
              />
            </Box>
          )}
          {loadingPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                marginTop: "20px",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {paginatedClientes.map((cliente, index) => {
                return (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                    key={cliente.id}
                  >
                    <AccordionGroup
                      cliente={cliente}
                      atualizarCliente={atualizarCliente}
                      deletarCliente={deletarCliente}
                    />
                  </Stack>
                );
              })}
            </Box>
          )}
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ marginTop: "20px" }}
          >
            <TablePagination
              component="div"
              count={filteredClientes.length}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 40]}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Stack>
        </Box>

        <ModalNovoCliente
          setOpenModal={setOpenModal}
          openModal={openModal}
          setClientes={setClientes}
          tabValue={tabValue}
        />

        {!!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
}
