import React, { useEffect, useState, useContext, createContext } from "react";

import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Badge,
} from "@mui/material";
import checkToken from "../../services/CheckToken";
import mwIcon from "../../assets/logo.png";
import Notificacoes from "./notificacoes";
import api from "../../services/api";

import { Outlet, useNavigate } from "react-router-dom";
import {
  DarkMode,
  DarkModeOutlined,
  LightModeOutlined,
  Logout,
  Person,
  PersonOutline,
  SupervisorAccountOutlined,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import ModalPerfil from "../perfil";

function Layout(params) {
  const { colorMode, setColorMode, socket,setCheckLogin } = params;
  const [pageValue, setPageValeu] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [opacity, setOpacity] = useState("0%");
  const [baseImage, setBaseImage] = useState("");
  const [administrador, setAdministrador] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  //notificacoes
  const [notificacoes, setNotificacoes] = useState([]);
  const [snackbar, setSnackbar] = useState({});
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [anchorNotification, setAnchorNotification] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
      tokenValidate();
    }, 200);

    async function tokenValidate() {
      const data = await checkToken();

      if (!data) {
        navigate("/login");
      }
      setCheckLogin(true)
    }
  }, [pageValue]);

  useEffect(() => {
    const pageName = window.location.pathname;
    const pages = ["/noc", "/dev", "/iot", "/vendar", "/financeiro"];
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setPageValeu(index);
      }
    });
  }, []);

  useEffect(() => {
    if (!notificacoes.length > 0) {
      handleApiGetNotificacoes();
    }
  }, []);
  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("2");
      result = JSON.parse(result);
      if (
        result.codigoPermissao &&
        (result.codigoPermissao.indexOf("adm001") !== -1 ||
          result.codigoPermissao.indexOf("adm002") !== -1)
      ) {
        setAdministrador(true);
      }
      if (result) {
        setBaseImage(result.foto);
      } else {
        return "";
      }
    };
    handleUsuarioId();
  });

  const handleLogout = () => {
    socket.disconnect();
    localStorage.clear();
    navigate("/login");
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePerfil = () => {
    setOpenModal(true);
    //navigate("/perfil");
    handleClose();
  };
  const navigate = useNavigate();

  const handleAdm = () => {
    navigate("/administracao");
  };
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }

  function handleNovaNotificacao(novaDemanda) {
    let result = {};
    let usuarioId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioId = result.refreshToken.usuarioId;
    }
    if (novaDemanda.usuarioId !== usuarioId) {
      setCountNotificacoes(countNotificacoes + 1);
      let novoArray = notificacoes;
      let objDemanda = handleConteudoNotificacao(novaDemanda.conteudo);
      objDemanda.tipo = novaDemanda.tipo;
      objDemanda.acao = novaDemanda.acao;
      objDemanda.visto = false;
      objDemanda.notificacaoId = novaDemanda.id;
      objDemanda.usuario = novaDemanda.usuario
        ? novaDemanda.usuario.Perfil
        : "";
      objDemanda.usuarioCliente = novaDemanda.usuarioCliente
        ? novaDemanda.usuarioCliente.PerfilCliente
        : "";
      novoArray = [objDemanda, ...novoArray];
      setNotificacoes(novoArray);
    }
  }
  function updateVistoNotificacao(visto, notificacaoId) {
    const novoArray = notificacoes.map((notificacao) => {
      if (notificacao.notificacaoId === notificacaoId) {
        let auxNotificao = notificacao;
        auxNotificao.visto = visto;
        return { ...auxNotificao };
      } else {
        return notificacao;
      }
    });
    if (countNotificacoes > 0) {
      setCountNotificacoes(countNotificacoes - 1);
    } else {
      setCountNotificacoes(0);
    }
    setNotificacoes(novoArray);
  }
  function updateManyVistoNotificacao(visto) {
    const novoArray = notificacoes.map((notificacao) => {
      let auxNotificao = notificacao;
      auxNotificao.visto = visto;
      return { ...auxNotificao };
    });
    setCountNotificacoes(0);
    setNotificacoes(novoArray);
  }
  function handleConteudoNotificacao(demanda) {
    let newObj = {};
    if (newObj) {
      newObj.id = demanda.id;
      /**newObj.usuario = demanda.usuario ? demanda.usuario.Perfil : "";
      newObj.usuarioCliente = demanda.usuarioCliente
        ? demanda.usuarioCliente.PerfilCliente
        : "";*/
      newObj.descricao =
        demanda.descricao ||
        demanda.relatoriosCliente?.descricao ||
        demanda.topicoRelatorio?.relatoriosCliente?.descricao;

      newObj.relatoriosClienteId =
        demanda.topicoRelatorio?.relatoriosCliente?.id ||
        demanda.relatoriosCliente?.id ||
        demanda.id;

      newObj.fixar = demanda?.fixar ? demanda.fixar : false;
      newObj.status = demanda.status;

      if (demanda.updateAt) {
        newObj.tempo = handleTime(demanda.updateAt);
      } else {
        newObj.tempo = handleTime(demanda.createAt);
      }
      newObj.atualizada = demanda.updateAt ? true : false;
      return newObj;
    }
  }
  function handleNotificacoes(res) {
    let result = {};
    let usuarioId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioId = result.refreshToken.usuarioId;
    }
    let auxCount = 0;
    //setCountNotificacoes(response.data.length);
    let novoArray = [];
    res.map((statusNotificacao) => {
      if (statusNotificacao.notificacao.usuarioId !== usuarioId) {
        let objDemanda = handleConteudoNotificacao(
          statusNotificacao.notificacao.conteudo
        );
        objDemanda.tipo = statusNotificacao.notificacao.tipo;
        objDemanda.acao = statusNotificacao.notificacao.acao;
        objDemanda.visto = statusNotificacao.visto;
        objDemanda.notificacaoId = statusNotificacao.notificacao.id;
        objDemanda.usuario = statusNotificacao.notificacao.usuario
          ? statusNotificacao.notificacao.usuario.Perfil
          : "";
        objDemanda.usuarioCliente = statusNotificacao.notificacao.usuarioCliente
          ? statusNotificacao.notificacao.usuarioCliente.PerfilCliente
          : "";
        novoArray.push(objDemanda);
        if (!statusNotificacao.visto) {
          auxCount++;
        }
      }
    });
    setCountNotificacoes(auxCount);
    setNotificacoes(novoArray);
  }

  async function handleApiGetNotificacoes() {
    try {
      setLoadingNotifications(true);
      const response = await api.post("/notificacoes/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar notificacoes",
          severity: "error",
        });
        setLoadingNotifications(false);
      } else {
        setLoadingNotifications(false);
        if (response.data) {
          handleNotificacoes(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingNotifications(false);
    }
  }
  return (
    <Box>
      {!loadingPage && (
        <Box
          sx={{
            m: 1,
            position: "relative",
            marginLeft: "50%",
            marginTop: "20%",
            opacity: `${opacity}`,
          }}
        >
          <Icon style={{ width: "80px", height: "auto" }}>
            <img src={mwIcon} style={{ width: "80px", height: "auto" }} />
          </Icon>

          <CircularProgress
            size={100}
            sx={{
              color: "primary",
              position: "absolute",
              top: -9,
              left: -6,
              zIndex: 1,
            }}
            thickness={2.5}
          />
        </Box>
      )}
      {loadingPage && (
        <>
          <AppBar
            position="static"
            sx={{
              display: "relative",
              minWidth: "950px",
              width: "100%",
            }}
          >
            <Container maxWidth="100vw">
              <Toolbar sx={{ width: "96vw" }}>
                <Stack direction={"row"} width={"100%"}>
                  <IconButton href={"/noc/home"} sx={{ padding: 0 }}>
                    <Icon style={{ width: 300, height: 62 }}>
                      <img src={mwIcon} style={{ width: 300, height: 62 }} />
                    </Icon>
                  </IconButton>
                  <Box
                    sx={{
                      width: "50%",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <Tabs
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        onChange={(event, newValue) => {
                          setPageValeu(newValue);
                        }}
                        value={pageValue}
                      >
                        <Tab
                          label="NOC"
                          value={0}
                          onClick={() => {
                            navigate("/noc/home");
                          }}
                        />
                        <Tab
                          label="DEV"
                          value={1}
                          onClick={() => {
                            navigate("/dev");
                          }}
                        />
                        <Tab
                          label="IOT"
                          value={2}
                          onClick={() => {
                            navigate("/iot/administracao");
                          }}
                        />
                        <Tab
                          label="VENDAR"
                          value={3}
                          onClick={() => {
                            navigate("/vendar");
                          }}
                        />
                        <Tab
                          label="FINANCEIRO"
                          value={4}
                          onClick={() => {
                            navigate("/financeiro");
                          }}
                        />
                      </Tabs>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 62,
                      width: "40%",
                      marginRight: "-2vh",
                    }}
                  >
                    <Tooltip title="Notificações">
                      <IconButton
                        color="inherit"
                        aria-label="notificacoes"
                        onClick={(event) => {
                          setAnchorNotification(event.currentTarget);
                        }}
                      >
                        <Badge
                          color="secondary"
                          badgeContent={countNotificacoes}
                        >
                          <NotificationsNoneOutlined />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Abrir Opções">
                      <IconButton onClick={handleClick}>
                        <Avatar src={baseImage} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      disableScrollLock={true}
                      sx={{ marginLeft: "-5px" }}
                    >
                      <MenuItem onClick={handlePerfil}>
                        <ListItemIcon>
                          <PersonOutline />
                        </ListItemIcon>
                        <ListItemText>Perfil</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (colorMode) {
                            setColorMode(false);
                            localStorage.setItem(
                              "3",
                              JSON.stringify({ colorMode: false })
                            );
                          } else {
                            setColorMode(true);
                            localStorage.setItem(
                              "3",
                              JSON.stringify({ colorMode: true })
                            );
                          }
                        }}
                        disabled={false}
                      >
                        {!colorMode ? (
                          <ListItemIcon>
                            <DarkModeOutlined />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon>
                            <LightModeOutlined />
                          </ListItemIcon>
                        )}
                        <ListItemText>Modo Noturno</ListItemText>
                      </MenuItem>
                      {administrador && (
                        <MenuItem onClick={handleAdm}>
                          <ListItemIcon>
                            <SupervisorAccountOutlined />
                          </ListItemIcon>
                          <ListItemText>Administração</ListItemText>
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <Logout />
                        </ListItemIcon>
                        <ListItemText>Sair</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Toolbar>
            </Container>
          </AppBar>
          <Box>
            <Outlet />
          </Box>
        </>
      )}

      <ModalPerfil setOpenModal={setOpenModal} openModal={openModal} />
      <Notificacoes
        notificacoes={notificacoes}
        handleNovaNotificacao={handleNovaNotificacao}
        anchorNotification={anchorNotification}
        setAnchorNotification={setAnchorNotification}
        loadingNotification={loadingNotifications}
        updateVistoNotificacao={updateVistoNotificacao}
        updateManyVistoNotificacao={updateManyVistoNotificacao}
        socket={socket}
      />
    </Box>
  );
}

export default Layout;
