import { Edit, Save, Close } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";

import { useState } from "react";

export default function Links(props) {
  const [snackbar, setSnackbar] = useState(null);
  const { linkProduto, handleApiUpdateLink, index } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editable, setEditable] = useState(true);
  const [confirmEdit, setConfirEdit] = useState(false);
  const [tipo, setTipo] = useState(
    linkProduto && linkProduto.tipo ? linkProduto.tipo : ""
  );
  const [link, setLink] = useState(
    linkProduto && linkProduto.link ? linkProduto.link : ""
  );
  const [login, setLogin] = useState(
    linkProduto && linkProduto.login ? linkProduto.login : ""
  );
  const [senha, setSenha] = useState(
    linkProduto && linkProduto.senha ? linkProduto.senha : ""
  );

  const styles = {
    BoxCampo: {
      display: "flex",
      flexDirection: "column",
      alingItens: "flex-start",
      gap: "4px",
      width: "182px",
      height: "48px",
      padding: "10px 0px",
    },

    TitleCampo: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      color: "primary.main",
      display: "flex",
      alingItens: "center",
    },
    TextCampo: {
      fontSize: "16px",
      lineHeight: "22px",
      cursor: "pointer",
      marginTop: "6px",
    },
    textFiled: { width: "160px" },
    textFiledLink: { width: "345px" },
    cardContainer: {
      border: "1px solid #5b2e87",
      borderRadius: "5px",
      padding: "16px",
      gap: "4px",
      width: "400px",

      display: "flex",
      flexDirection: "column",
    },
    select: { width: "180px" },
  };

  const Campo = (props) => {
    const { title, text } = props;
    return (
      <Box sx={styles.BoxCampo}>
        <Box sx={styles.TitleCampo}>{title}</Box>
        <Box sx={styles.TextCampo} width={title === "Link" ? "360px" : "180px"}>
          <Tooltip title="Clique para copiar">
            <span>
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: title === "Link" ? "350px" : "175px",
                }}
                onClick={() => {
                  handleCopy(text);
                }}
              >
                {text}
              </Typography>
            </span>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  function cancelar() {
    setTipo(linkProduto.tipo);
    setLink(linkProduto.link);
    setLogin(linkProduto.login);
    setSenha(linkProduto.senha);
  }
  return (
    <Box>
      <Box sx={styles.cardContainer}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          marginBottom={"10px"}
        >
          {editable && (
            <>
              <Stack direction={"row"}>
                <Campo title={"Tipo"} text={tipo} />
              </Stack>
            </>
          )}
          {!editable && (
            <FormControl variant="standard">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={tipo}
                onChange={(event) => {
                  setTipo(event.target.value);
                }}
                disabled={editable}
                sx={styles.select}
              >
                <MenuItem value={"zabbix"}>Zabbix</MenuItem>
                <MenuItem value={"easymon"}>EasyMon</MenuItem>
                <MenuItem value={"easyflow"}>EasyFlow</MenuItem>
                <MenuItem value={"portainer"}>Portainer</MenuItem>
                <MenuItem value={"sistema"}>Sistema</MenuItem>
                <MenuItem value={""}>Nenhum</MenuItem>
              </Select>
            </FormControl>
          )}
          {editable && (
            <Stack direction={"row"}>
              <Tooltip
                title={"Editar"}
                onClick={() => {
                  setEditable(false);
                }}
              >
                <IconButton>
                  <Edit color={"primary"} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          {!editable && (
            <Stack direction={"row"}>
              <Tooltip
                title={"Salvar"}
                onClick={() => {
                  setOpenEditModal(true);
                }}
              >
                <IconButton>
                  <Save color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={"Cancelar"}
                onClick={() => {
                  setEditable(true);
                  cancelar();
                }}
              >
                <IconButton>
                  <Close color={"secondary"} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        {editable && (
          <>
            <Stack direction={"row"}>
              <Campo title={"Link"} text={link} />
            </Stack>
            <Stack direction={"row"} marginTop={"10px"} paddingBottom={"20px"}>
              <Campo title={"Login"} text={login} />
              <Campo title={"Senha"} text={senha} />
            </Stack>
          </>
        )}
        {!editable && (
          <>
            <Box sx={styles.BoxCampo}>
              <Box sx={styles.TitleCampo}>{"Link"}</Box>
              <TextField
                variant="standard"
                sx={styles.textFiledLink}
                value={link}
                onChange={(event) => {
                  setLink(event.target.value);
                }}
                disabled={editable}
              />
            </Box>

            <Stack direction={"row"} marginTop={"10px"} paddingBottom={"20px"}>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Login"}</Box>
                <TextField
                  variant="standard"
                  sx={styles.textFiled}
                  value={login}
                  onChange={(event) => {
                    setLogin(event.target.value);
                  }}
                  disabled={editable}
                />
              </Box>
              <Box sx={styles.BoxCampo}>
                <Box sx={styles.TitleCampo}>{"Senha"}</Box>
                <TextField
                  variant="standard"
                  sx={styles.textFiled}
                  value={senha}
                  onChange={(event) => {
                    setSenha(event.target.value);
                  }}
                  disabled={editable}
                />
              </Box>
            </Stack>
          </>
        )}
      </Box>
      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={confirmEdit}
            autoFocus
            onClick={async () => {
              const response = await handleApiUpdateLink(
                { link, login, senha, tipo },
                index
              );
              setConfirEdit(true);
              setOpenEditModal(response);
              setConfirEdit(false);
              setEditable(true);
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
