import { Add, Delete, Edit, ExpandMore, OpenInNew } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  AccordionDetails,
  Stack,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Snackbar,
  Alert,
  Paper,
  Card,
  CardActionArea,
  useMediaQuery,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CardAcessos from "../cardAcessos";
import zabbix from "../../../assets/acessosImg/zabbix.png";
import easymon from "../../../assets/acessosImg/easymon.png";
import easyflow from "../../../assets/acessosImg/easyflow.png";
import portainer from "../../../assets/acessosImg/portainer.png";
import iconeMw from "../../../assets/logo5.png";

import { useEffect, useMemo, useState } from "react";
import api from "../../../services/api";
import Links from "./links";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    width: "52vh",

    padding: "20px",
    justifyContent: "center",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "#5B2E87" },
};

export default function ProductRow(props) {
  const { atualizarProduto, produto, clienteId } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nomeDaMaquina, setNomeDaMaquina] = useState("");
  const [tipo, setTipo] = useState("");
  const [disableButton, setDissableButton] = useState(true);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const realocar = useMediaQuery("(max-width:870px)");
  const realocarAccordion = useMediaQuery("(max-width:1828px)");
  const [arrayLinks, setArrayLinks] = useState(
    Array.isArray(produto?.link)
      ? produto.link.length < 4
        ? [...produto.link, {}]
        : [...produto.link]
      : []
  );
  const open = Boolean(anchorEl);

  function atualizarMaquinas(novaMaquina) {
    let aux = produto;

    const indexProduto = aux.maquinas.findIndex(
      (maquina) => maquina.id === novaMaquina.id
    );

    aux.maquinas[indexProduto] = novaMaquina;

    atualizarProduto(aux);
  }

  function excluirMaquinas(novaMaquina) {
    let aux = produto;

    const indexProduto = aux.maquinas.findIndex(
      (maquina) => maquina.id === novaMaquina.id
    );

    aux.maquinas.splice(indexProduto, 1);

    atualizarProduto(aux);
  }

  useEffect(() => {
    if (nomeDaMaquina !== "" && tipo !== "") {
      setDissableButton(false);
    }
  }, [nomeDaMaquina, tipo]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleApiCreateMaquinaApi() {
    try {
      setDissableButton(true);
      const response = await api.post("/criarMaquinaAcesso", {
        acessoClientesId: clienteId,
        acessoProduto: produto.id,
        tipo,
        nomeDaMaquina,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });

        let aux = produto;

        aux.maquinas.push(response.data);

        atualizarProduto(aux);
        setOpenModal(false);
        setDissableButton(false);
        setTipo("");
        setNomeDaMaquina("");
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      setDissableButton(false);

      console.error(error);
    }
  }

  async function handleApiUpdateLink(link, index) {
    try {
      let novoLink = produto.link;
      novoLink[index] = link;

      const response = await api.put("/putLinkAcesso", {
        link: novoLink,
        acessoProduto: produto.id,
        acessoClientesId: clienteId,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        return false;
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        /* width: "1572px", */
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "245px",
          mt: "6px",
        }}
      >
        {Array.isArray(produto.link) &&
          produto.link.map((link, index) => {
            if (link) {
              if (link.tipo === "zabbix") {
                return (
                  <Card
                    key={index}
                    sx={{
                      textDecoration: "none",
                      width: "61px",
                      height: "51px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardActionArea
                      href={link.link}
                      target="_blank"
                      sx={{
                        display: "flex",
                        paddingInline: "5px",
                        width: "45px",
                        height: "35px",
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={zabbix}
                        style={{
                          width: "45px",
                          height: "auto",
                          paddingInline: "5px",
                          backgroundColor: "#D31F26",
                          borderRadius: 20,
                        }}
                        alt={"logo zabbix"}
                      />
                    </CardActionArea>
                  </Card>
                );
              }
              if (link.tipo === "easymon") {
                return (
                  <Card
                    key={index}
                    sx={{
                      textDecoration: "none",
                      width: "61px",
                      height: "51px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardActionArea
                      href={link.link}
                      target="_blank"
                      sx={{
                        display: "flex",
                        paddingInline: "5px",
                        width: "45px",
                        height: "35px",
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={easymon}
                        style={{
                          paddingInline: "5px",
                          width: "45px",
                          height: "auto",
                          borderRadius: 15,
                          backgroundColor: "#011E40",
                        }}
                        alt={"logo easymon"}
                      />
                    </CardActionArea>
                  </Card>
                );
              }
              if (link.tipo === "easyflow") {
                return (
                  <Card
                    key={index}
                    sx={{
                      textDecoration: "none",
                      width: "61px",
                      height: "51px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardActionArea
                      href={link.link}
                      target="_blank"
                      sx={{
                        display: "flex",
                        paddingInline: "5px",
                        width: "45px",
                        height: "35px",
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={easyflow}
                        style={{
                          paddingInline: "5px",
                          width: "45px",
                          height: "auto",
                          borderRadius: 15,
                          backgroundColor: "#011E40",
                        }}
                        alt={"logo easyflow"}
                      />
                    </CardActionArea>
                  </Card>
                );
              }
              if (link.tipo === "portainer") {
                return (
                  <Card
                    key={index}
                    sx={{
                      textDecoration: "none",
                      width: "61px",
                      height: "51px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardActionArea
                      href={link.link}
                      target="_blank"
                      sx={{
                        display: "flex",
                        paddingInline: "5px",
                        width: "45px",
                        height: "35px",
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={portainer}
                        style={{
                          paddingInline: "5px",
                          width: "45px",
                          height: "auto",
                          borderRadius: 20,
                          backgroundColor: "#024086",
                        }}
                        alt={"logo portainer"}
                      />
                    </CardActionArea>
                  </Card>
                );
              }
              if (link.tipo === "sistema") {
                return (
                  <Card
                    key={index}
                    sx={{
                      textDecoration: "none",
                      width: "61px",
                      height: "51px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      backgroundImage: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardActionArea
                      href={link.link}
                      target="_blank"
                      sx={{
                        display: "flex",
                        paddingInline: "5px",
                        width: "45px",
                        height: "35px",
                        borderRadius: 20,
                        backgroundColor: "#331451",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={iconeMw}
                        style={{
                          display: "flex",
                          width: "105%",
                          height: "auto",
                        }}
                        alt={"logo mw"}
                      />
                    </CardActionArea>
                  </Card>
                );
              } else {
                return <Box width={"60px"} key={index} />;
              }
            } else {
              return <Box width={"60px"} key={index} />;
            }
          })}
      </Box>
      <Box sx={{ display: "flex", marginTop: "6px", ml: "10px" }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{produto.nome}</Typography>
          </AccordionSummary>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <ListItemIcon>
                <Add fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Novo</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Editar</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Delete fontSize="small" color="secondary" />
              </ListItemIcon>
              <ListItemText>Deletar</ListItemText>
            </MenuItem>
          </Menu>

          <AccordionDetails>
            <Box sx={{
              width: "70vw",
            }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  minWidth: "650px",
                  maxWidth: "1280px",
                  width: realocarAccordion ? "min-content" : "auto",
                  padding: "10px",
                  gap: "20px",
                  justifyContent: "stretch",
                }}
              >
                {produto.maquinas &&
                  produto.maquinas.map((maquina, mqIndex) => {
                    return (
                      <Box key={maquina.id}>
                        <CardAcessos
                          atualizarMaquinas={atualizarMaquinas}
                          excluirMaquinas={excluirMaquinas}
                          maquina={maquina}
                          clienteId={clienteId}
                          produtoId={produto.id}
                        />
                      </Box>
                    );
                  })}
                <Box sx={{
                  width: "100%",
                }}>
                  <Stack
                    display={"flex"}
                    justifyContent={"flex-end"}
                    direction={"row"}
                    gap={"20px"}
                    sx={{ mr: realocarAccordion ? "10px" : "0px" }}
                  >
                    <Button
                      variant={"contained"}
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setOpenLinkModal(true);
                      }}
                    >
                      Links
                      <OpenInNew fontSize="small" sx={{ mt: -0.3, ml: 0.3 }} />
                    </Button>

                    <Button
                      sx={{ width: "100px" }}
                      variant={"contained"}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Novo
                      <Add sx={{ mt: -0.3 }} />
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Dialog
        id="dialog-create-modal"
        open={openModal}
        onClose={() => {
          setTipo("");
          setNomeDaMaquina("");
          setOpenModal(false);
        }}
        sx={styles.modal}
      >
        <DialogTitle color={"primary"}>{"Criar nova máquina"}</DialogTitle>
        <DialogContent>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap={"wrap"}
          >
            <TextField
              variant="standard"
              label="Nome"
              sx={styles.TextField}
              value={nomeDaMaquina}
              onChange={(event) => {
                setNomeDaMaquina(event.target.value);
              }}
            />
            <FormControl sx={styles.TextField} variant="standard">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={tipo}
                label="Tipo"
                onChange={(event) => {
                  setTipo(event.target.value);
                }}
              >
                <MenuItem value={"manager"}>Manager</MenuItem>
                <MenuItem value={"database"}>Data Base</MenuItem>
                <MenuItem value={"proxy"}>Proxy</MenuItem>
                <MenuItem value={"worker"}>Worker</MenuItem>
                <MenuItem value={"outro"}>Outro</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setTipo("");
              setNomeDaMaquina("");
              setOpenModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            onClick={handleApiCreateMaquinaApi}
            disabled={disableButton}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openLinkModal}
        onClose={() => setOpenLinkModal(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            padding: "20px",
            width: "100%",
            overflowY: "auto",
            maxWidth: realocar ? "448px" : "850px",
            maxHeight: realocar ? "calc(100% - 160px)" : "auto",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={{ mb: "10px" }}
          >
            Gerenciamento de links
          </Typography>
          <Stack
            gap={"10px"}
            direction={"row"}
            sx={{
              width: "100%",
              flexWrap: "wrap",
              flexDirection: realocar ? "column" : "row",
            }}
          >
            {Array.isArray(produto.link) &&
              Array.isArray(arrayLinks) &&
              arrayLinks.map((link, index) => (
                <div key={index}>
                  <Links
                    clienteId={clienteId}
                    linkProduto={produto?.link[index] || []}
                    index={index}
                    handleApiUpdateLink={handleApiUpdateLink}
                  />
                </div>
              ))}
          </Stack>
        </Paper>
      </Modal>
      {/*  <Dialog
        open={openLinkModal}
        onClose={() => {
          setOpenLinkModal(false);
        }}
        sx={{ width: "3000px" }}
      >
        <DialogTitle color={"primary"}>Gerenciamento de links</DialogTitle>
        <DialogContent>
          <Stack
            gap={"10px"}
            direction={"row"}
            sx={{
              width: "900px",
              flexWrap: "wrap",
            }}
          >
            {Array.isArray(produto.link) &&
              produto.link.map((link, index) => {
                return (
                  <div key={index}>
                    <Links
                      clienteId={clienteId}
                      linkProduto={link}
                      index={index}
                      handleApiUpdateLink={handleApiUpdateLink}
                    />
                  </div>
                );
              })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLinkModal(false)}>voltar</Button>
        </DialogActions>
      </Dialog> */}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
