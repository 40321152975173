import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import api from "../../../services/api";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

import { saveAs } from "file-saver";
import RelatorioPdf from "./relatorioPDF";

import { pdf } from "@react-pdf/renderer";
import DisponibilidadePdf from "./disponibilidadePDF";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import DisponibilidadeInterfacePDF95th from "./disponibilidadeInterface95thPDF";
import SessaoBgpPDF from "./sessaoBgpPDF";
import DisponibilidadeInterfacePDF from "./disponibilidadeInterfacePDF";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    marginTop: "30px",
  },
  paper: {
    width: "84vw",
    minWidth: "900px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  button: { height: "40px", minWidth: "120px" },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  relatorioName: { width: "432px" },
};

export default function EasyReport() {
  const [cliente, setCliente] = useState();
  const [easyMon, setEasyMon] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [sessionNames, setSessionNames] = useState([]);
  const [hostDisponibilidade, setHostDisponibilidade] = useState([]);
  const [host, setHost] = useState(null);
  const [sessionName, setSessionName] = useState(null);
  const [interfaces, setInterfaces] = useState(null);
  const [interfaceNome, setInterfaceNome] = useState(null);
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [acessoProdutoId, setAcessoProdutoId] = useState("");
  const [tipoRelatorio, setTipoRelatorio] = useState("interfaces");

  const [valor, setValor] = useState(0);

  //habilita o autocomple
  const [disableTextEasyMon, setDisableTextEasyMon] = useState(true);
  const [disableTextInterface, setTextButtonInterface] = useState(true);
  const [disableTextnHost, setTextButtonHost] = useState(true);
  const [disableTextSessionName, setDisableTextSessionName] = useState(true);

  const [loadingEasyMon, setLoadingEasyMon] = useState(false);
  const [loadingHost, setLoadingHost] = useState(false);
  const [loadingInterface, setButtonInterface] = useState(false);
  const [loadingSessionName, setLoadiongSessionName] = useState(false);
  //Desabilita botao de gerar relatorios
  const [disabelButton, setDisabelButton] = useState(true);

  const [hiddenInterfaces, setHiddenInterfaces] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [tipo, setTipo] = useState("easymon");
  const [snackbar, setSnackbar] = useState(null);
  const [dataInicial, setDataInicial] = useState(
    dayjs(new Date()).startOf("day").toDate()
  );
  const [dataFinal, setDataFinal] = useState(dayjs(new Date()).startOf("day"));

  const [relatorio, setRelatorio] = useState([]);
  const [nomeDoRelatorio, setNomeRelatorio] = useState("");

  const [loading, setLoading] = useState(false);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxOutlined fontSize="small" />;

  console.log(sessionName);

  //atualiza os dados do pdf
  const file = useMemo(() => relatorio, [relatorio]);

  useEffect(() => {
    if (file?.host_name || file?.session_name) {
      handleDownloadPDF();
    }
  }, [file]);

  useEffect(() => {
    if (cliente) {
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);

  //chama rota de interfaces
  useEffect(() => {
    if (host) {
      if (
        tipoRelatorio === "interfaces" ||
        tipoRelatorio === "disponibilidade_interfaces" ||
        tipoRelatorio === "disponibilidade_interfaces95th"
      ) {
        handleApiGetKeyItems(acessoClientesId, acessoProdutoId, host.id);
      }
      if (tipoRelatorio === "sessoesbgp") {
        handleApiSessionName(acessoClientesId, acessoProdutoId, host.id);
      }
    }
  }, [host]);

  useEffect(() => {
    handleApiGetClientes();
  }, []);

  //chama rota do de hosts
  useEffect(() => {
    if (cliente && easyMon) {
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
    } else {
      setHosts([]);
    }
  }, [cliente, easyMon, acessoClientesId, acessoProdutoId]);

  useEffect(() => {
    if (tipoRelatorio === "disponibilidade") {
      setHiddenInterfaces(false);
      setHost(null);
      setInterfaceNome(null);
      setValor(0);
    } else {
      setHiddenInterfaces(true);
      setHostDisponibilidade([]);
    }
  }, [tipoRelatorio]);

  //chama hosts
  async function handleApiGetHosts(acessoClientesId, acessoProdutoId) {
    {
      try {
        setLoadingHost(true);
        setLoadingPage(true);
        const response = await api.post("/zabbixHostName", {
          acessoClientesId,
          acessoProdutoId,
        });

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: não foi possivel buscar dados",
            severity: "error",
          });
          setLoadingHost(false);
          setLoadingPage(false);
        } else {
          setHosts(response.data);
          setTextButtonHost(false);
          setLoadingHost(false);
          setLoadingPage(false);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possivel se conectar com o servidor",
          severity: "error",
        });

        console.error(error);
        setLoadingHost(false);
        setLoadingPage(false);
      }
    }
  }
  //funcao de para chama os dados do relatorio
  //disponibilidade
  async function handleApiScript(params) {
    try {
      setLoading(true);

      let selectedInterface = interfaceNome?.nome;

      if (tipoRelatorio === "sessoesbgp" && sessionName.nome) {
        console.log("selectedInterface", selectedInterface);
        selectedInterface = sessionName.nome;
      }
      console.log("selectedInterface", selectedInterface);

      const response = await api.post("/execultScript", {
        tipoRelatorio: tipoRelatorio.replace("95th", ""),
        host: host.nome || "", //=== "interfaces" ? host.nome : hostDisponibilidade,
        interface: selectedInterface,
        acessoClientesId,
        acessoProdutoId,
        dataInicial: dataInicial.format("YYYY-MM-DD HH:mm:ss").toString(),
        dataFinal: dataFinal.format("YYYY-MM-DD HH:mm:ss").toString(),
        valor: parseFloat(valor),
        nomeDoRelatorio,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: não foi possivel buscar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setRelatorio(response.data);

        setTextButtonHost(false);
        setLoadingHost(false);
        //console.log(response.data);

        setSnackbar({
          children: "Busca de relatorio gerado com sucesso",
          severity: "success",
        });
        setLoadingPage(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
      setLoadingPage(false);
    } finally {
      setLoading(false);
    }
  }
  //desabilita/habilita o botao de gerar relatorio
  useEffect(() => {
    if (tipoRelatorio === "interfaces") {
      if (cliente && easyMon && host && interfaceNome) {
        setDisabelButton(false);
      } else {
        setDisabelButton(true);
      }
    }
    if (
      tipoRelatorio === "disponibilidade" ||
      tipoRelatorio === "disponibilidade_interfaces" ||
      tipoRelatorio === "disponibilidade_interfaces95th" ||
      tipoRelatorio === "sessoesbgp"
    ) {
      if (cliente && easyMon && host) {
        setDisabelButton(false);
      } else {
        setDisabelButton(true);
      }
    }
  }, [cliente, easyMon, host, interfaceNome, hostDisponibilidade]);

  //chama clientes
  async function handleApiGetClientes() {
    try {
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }

  //rota do easymon
  async function handleApiGetProdutos(acessoClientesId, tipo) {
    setLoadingEasyMon(true);
    setDisableTextEasyMon(true);
    try {
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar Clientes",
          severity: "error",
        });
        setLoadingEasyMon(false);
      } else {
        setProdutos(response.data);
        setLoadingEasyMon(false);
        setDisableTextEasyMon(false);
      }
    } catch (error) {
      setLoadingEasyMon(false);
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }

  //rota de interfaces

  async function handleApiGetKeyItems(
    acessoClientesId,
    acessoProdutoId,
    hostId
  ) {
    try {
      setButtonInterface(true);
      setTextButtonInterface(true);
      const response = await api.post("/zabbixKeyItems", {
        acessoClientesId,
        acessoProdutoId,
        hostId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar Interfaces",
          severity: "error",
        });
      } else {
        setInterfaces(response.data);
        setTextButtonInterface(false);
        setButtonInterface(false);
      }
    } catch (error) {
      console.log(error);
      setButtonInterface(false);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiSessionName(
    acessoClientesId,
    acessoProdutoId,
    hostId
  ) {
    try {
      setLoadiongSessionName(true);
      setDisableTextSessionName(true);
      const response = await api.post("/zabbixSessionName", {
        acessoClientesId,
        acessoProdutoId,
        hostId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival buscar Interfaces",
          severity: "error",
        });
      } else {
        setSessionNames(response.data);
        setLoadiongSessionName(false);
        setDisableTextSessionName(false);
      }
    } catch (error) {
      console.log(error);
      setDisableTextSessionName(false);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
    }
  }

  //controle do autocomplete
  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;

  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  const getHostsLabel = (hosts) => `${hosts.nome}`;

  const getInterfacesLabel = (interfaces) => `${interfaces.nome}`;

  const getSessionLabel = (session) => `${session.nome}`;

  //gera o pdf
  async function handleDownloadPDF() {
    if (tipoRelatorio === "interfaces") {
      const blob = await pdf(<RelatorioPdf file={file} />).toBlob();
      saveAs(blob, "mwRelatorio.pdf");
    }
    if (tipoRelatorio === "disponibilidade") {
      const blob = await pdf(
        <DisponibilidadePdf
          file={file}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
        />
      ).toBlob();
      saveAs(blob, "mwRelatorio.pdf");
    }
    if (tipoRelatorio === "disponibilidade_interfaces") {
      const blob = await pdf(
        <DisponibilidadeInterfacePDF
          file={file}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
        />
      ).toBlob();
      saveAs(blob, "mwRelatorio.pdf");
    }
    if (tipoRelatorio === "disponibilidade_interfaces95th") {
      const blob = await pdf(
        <DisponibilidadeInterfacePDF95th
          file={file}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
        />
      ).toBlob();
      saveAs(blob, "mwRelatorio.pdf");
    }
    if (tipoRelatorio === "sessoesbgp") {
      const blob = await pdf(
        <SessaoBgpPDF
          file={file}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
        />
      ).toBlob();
      saveAs(blob, "mwRelatorio.pdf");
    }
  }

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box>
          <Stack direction={"column"} gap={2}>
            <Typography color={"primary"} fontWeight={500}>
              Relatório
            </Typography>

            <Stack direction={"row"} gap={4}>
              <Box>
                <FormControl
                  variant="standard"
                  label="Relatorios"
                  required
                  disabled={loading}
                >
                  <InputLabel> Tipo de Relatório</InputLabel>
                  <Select
                    fullWidth
                    value={tipoRelatorio}
                    onChange={(event) => {
                      setTipoRelatorio(event.target.value);
                      if (event.target.value === "sessoesbgp") {
                      }
                    }}
                    sx={styles.filter}
                  >
                    <MenuItem value={"interfaces"}>
                      Cobrança 95th percentil
                    </MenuItem>
                    <MenuItem value={"disponibilidade"}>
                      Disponibilidade
                    </MenuItem>
                    <MenuItem value={"disponibilidade_interfaces95th"}>
                      Disponibilidade de interface_95th
                    </MenuItem>
                    <MenuItem value={"disponibilidade_interfaces"}>
                      Disponibilidade de interface
                    </MenuItem>
                    <MenuItem value={"sessoesbgp"}>Sessão BGP</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <TextField
                  label={"Título do relatório"}
                  id="titulo-relatorio"
                  variant="standard"
                  value={nomeDoRelatorio}
                  onChange={(event) => {
                    setNomeRelatorio(event.target.value);
                  }}
                  sx={styles.relatorioName}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} gap={4} alignItems={"center"}>
              <Box>
                <Typography color={"primary"} fontWeight={500} fontSize={15}>
                  Configure o Relatório
                </Typography>
                <Autocomplete
                  sx={{ mt: 1, width: "180px" }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                  }}
                  value={cliente}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione o Cliente"
                      variant="standard"
                    />
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={disableTextEasyMon}
                  sx={{ mt: 4, width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue.id);
                  }}
                  value={easyMon}
                  options={produtos}
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingEasyMon}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
              {!hiddenInterfaces && (
                <Box>
                  <Autocomplete
                    disabled={disableTextnHost}
                    sx={{ mt: 4, width: "663px" }}
                    onChange={(event, newValue) => {
                      setHost(newValue);
                    }}
                    value={host}
                    options={hosts}
                    getOptionLabel={getHostsLabel}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Selecione um Host"
                          variant="standard"
                        />
                        <Fade in={loadingHost}>
                          <LinearProgress />
                        </Fade>
                      </>
                    )}
                  />
                </Box>
              )}
              {hiddenInterfaces && (
                <Box>
                  <Autocomplete
                    disabled={disableTextnHost}
                    sx={{ mt: 4, width: "200px" }}
                    onChange={(event, newValue) => {
                      setHost(newValue);
                    }}
                    value={host}
                    options={hosts}
                    getOptionLabel={getHostsLabel}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Selecione um Host"
                          variant="standard"
                        />
                        <Fade in={loadingHost}>
                          <LinearProgress />
                        </Fade>
                      </>
                    )}
                  />
                </Box>
              )}
              {hiddenInterfaces && (
                <Stack direction={"row"} gap={4} alignItems={"center"}>
                  {tipoRelatorio === "sessoesbgp" ? (
                    <Box>
                      <Autocomplete
                        disabled={disableTextSessionName}
                        sx={{ mt: 4, width: "200px" }}
                        onChange={(event, newValue) => {
                          setSessionName(newValue);
                          //console.log(newValue.id);
                        }}
                        value={sessionName}
                        options={sessionNames}
                        getOptionLabel={getSessionLabel}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="Selecione a sessão"
                              variant="standard"
                            />
                            <Fade in={loadingSessionName}>
                              <LinearProgress />
                            </Fade>
                          </>
                        )}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Autocomplete
                        disabled={disableTextInterface}
                        sx={{ mt: 4, width: "200px" }}
                        onChange={(event, newValue) => {
                          setInterfaceNome(newValue);
                          //console.log(newValue.id);
                        }}
                        value={interfaceNome}
                        options={interfaces}
                        getOptionLabel={getInterfacesLabel}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="Selecione a Interface"
                              variant="standard"
                            />
                            <Fade in={loadingInterface}>
                              <LinearProgress />
                            </Fade>
                          </>
                        )}
                      />
                    </Box>
                  )}

                  <Box sx={{ mt: 3.63, width: "200px" }}>
                    {tipoRelatorio === "interfaces" && (
                      <TextField
                        label="Valor por Mbps"
                        variant="standard"
                        type="number"
                        fullWidth
                        value={valor}
                        onChange={(event) => {
                          setValor(event.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography>R$</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              )}

              <Box>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DateTimePicker
                    label="Data inicial"
                    value={dataInicial}
                    minDate={dayjs().subtract(59, "day").format("YYYY-MM-DD")}
                    maxDate={dayjs().format("YYYY-MM-DD")}
                    onChange={(newValue) => setDataInicial(newValue)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 3.63 }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DateTimePicker
                    label="Data final"
                    value={dataFinal}
                    minDate={dayjs().subtract(59, "day").format("YYYY-MM-DD")}
                    maxDate={dayjs().format("YYYY-MM-DD")}
                    onChange={(newValue) => setDataFinal(newValue)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 3.63 }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Stack>
            <Stack justifyContent={"end"} direction={"row"}>
              <Box>
                <Button
                  onClick={handleApiScript}
                  variant="contained"
                  sx={{ width: "200px" }}
                  color="primary"
                  disabled={disabelButton || loading}
                >
                  Gerar Relatório
                </Button>

                <Fade in={loading}>
                  <LinearProgress />
                </Fade>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
