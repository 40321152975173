import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Collapse, Paper, Tab, Tabs } from "@mui/material";

import { DataObjectOutlined } from "@mui/icons-material";

import Rodape from "../rodape";
import { Outlet, useNavigate } from "react-router-dom";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const styles = {
  tab: {
    minWidth: "60px",
    minHeight: "48px",
    maxWidth: "155px",
    justifyContent: "start",
  },
};

export default function NocPage() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (value === 0) {
      navigate("/dev/jsonServices");
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <Paper sx={{ height: "100vh", position: "sticky", top: 0, zIndex: 1 }}>
        <Collapse in={open} orientation="horizontal" collapsedSize={60}>
          <Box width={!open ? "60px" : "160px"} margin={0} padding={0}>
            <DrawerHeader>
              {!open ? (
                <IconButton onClick={handleDrawerOpen}>
                  <ChevronRightIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              )}
            </DrawerHeader>
            <Divider />

            <Tabs
              orientation="vertical"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <Tab
                icon={<DataObjectOutlined />}
                label={open ? "JSON Services" : ""}
                iconPosition="start"
                value={0}
                sx={styles.tab}
                onClick={() => {
                  navigate("/dev/jsonServices");
                }}
              />
            </Tabs>
          </Box>
        </Collapse>
      </Paper>

      <Box
        component="main"
        sx={{ flexGrow: 1, paddingTop: 5, paddingInline: 3 }}
      >
        <Outlet />
        <Rodape />
      </Box>
    </Box>
  );
}
