import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Modal,
  Divider,
  OutlinedInput,
  MenuItem,
  Chip,
  Icon,
  FormHelperText,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Select,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import "dayjs/locale/pt-br";
import api from "../../../services/api";
import mwIcon from "../../../assets/logo4.png";

import Editor from "../../../componentes/reactQuill";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Carousel from "react-material-ui-carousel";
import "react-quill/dist/quill.snow.css";
import { PhotoCamera } from "@mui/icons-material";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplyIcon from "@mui/icons-material/Reply";

const styles = {
  customScrollbar: {
    overflow: "auto",
    scrollbarWidth: "thin" /* For Firefox */,
    scrollbarColor: "#888888 #f0f0f0" /* For Firefox */,

    "&::-webkit-scrollbar": {
      width: "8px",
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: "4px",
    },
  },
  buttoTabelType: {
    borderRadius: "0px",
    fontSize: "15px",
    opacity: "0.3",
  },
  buttonPermissoa: {
    minWidth: "0px",
    background: "rgba(0,0,0,0.2)",
    height: "30px",
    marginTop: "5px",

    hover: {
      background: "red",
    },
  },

  ContainerBox: {
    padding: "1vw",
    width: "90%",
    marginRight: "auto",
    marginLeft: "auto",
    minWidth: "1200px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  boxPermissao: {
    borderRadius: "20px",
    background: "rgb(63, 32, 94)",
    textAlign: "center",

    height: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "white",
    margin: "5px",
  },
  paper: {
    alignItems: "center",
    width: "60%",
    padding: "20px",
    minHeight: "600px",
    minWidth: "800px",
    justifyContent: "center",
  },
  CabecalhoBox: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  StackContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "20%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
  TextCampo: {
    mt: 1,
    mb: 2,
    width: "200px",
  },
};

export default function IotAdministrcao() {
  const [snackbar, setSnackbar] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [searchProdutos, setSearchProdutos] = useState("");
  const [searchCategorias, setSearchcategorias] = useState("");
  const [selectTableShowProdutos, setSelectTableShowProdutos] =
    useState("full");
  const [openNew, setOpenNew] = React.useState(false);
  const [openNewCategoria, setOpenNewCategoria] = React.useState(false);
  const [Null, setNull] = useState(false);
  const [NullEsp, setNullEsp] = useState(false);

  const [produtos, setProdutos] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  const apiRef = React.useRef(null);

  const [showDialogStatus, setshowDialogStatus] = React.useState({
    open: false,
    id: "",
  });
  const [foto, setFoto] = useState([]);
  const [fotoC, setFotoC] = useState([]);
  const [fotoSelecionada, setfotoSelecionada] = useState({ url: "", id: 0 });

  const [nomeProdutoModal, setNomeProdutoModal] = useState("");
  const [modeloProdutoModal, setmodeloProdutoModal] = useState("");
  const [precoAVistaProdutoModal, setPrecoAVistaProdutoModal] = useState("");
  const [precoAPrazoProdutoModal, setPrecoAPrazoProdutoModal] = useState("");
  const [statusProdutoModal, setStatusProdutoModal] = useState(true);
  const [cateoriaProdutoModal, setCategoriaProdutoModal] = useState("");
  const [descricaoProdutoModal, setDescricaoProdutoModal] = useState("");
  const [especificacoesProdutoModal, setEspecificacoesProdutoModal] = useState(
    []
  );
  const [idProdutoModal, setIdProdutoModal] = useState("");
  const [idCategoriaModal, setIdCategoriaModal] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [nomeCategoriaModal, setNomeCategoriaModal] = useState("");
  const [newDescricaoProdutoDescModal, setnewDescricaoProdutoDescModal] =
    useState("");
  const [newDescricaoProdutoValueModal, setDescricaoProdutoValueModal] =
    useState("");

  const handleAddEspecificacao = () => {
    setNullEsp(true);
    if (!newDescricaoProdutoValueModal || !newDescricaoProdutoDescModal) return;
    setEspecificacoesProdutoModal([
      ...especificacoesProdutoModal,
      {
        caracteristica: newDescricaoProdutoDescModal,
        value: newDescricaoProdutoValueModal,
      },
    ]);
    setnewDescricaoProdutoDescModal("");
    setDescricaoProdutoValueModal("");
    setNullEsp(false);
  };
  const handleEditeOpen = (params) => {
    setOpenNew(true);
    setEditModal(true);
    setNomeProdutoModal(params.nome);
    setmodeloProdutoModal(params.modelo);
    setPrecoAVistaProdutoModal(params.desconto);
    setPrecoAPrazoProdutoModal(params.preco);
    setCategoriaProdutoModal(params.RlProdutoCategoria[0]?.categoriaProdutoId);
    setDescricaoProdutoModal(params.descricao);
    setEspecificacoesProdutoModal(params.especificacoes);
    setStatusProdutoModal(params.status);
    setIdProdutoModal(params.id);

    setfotoSelecionada({ url: "", id: 0 });
    setFoto(params.MidiaProduto);
  };
  const handleNewOpen = () => {
    setOpenNew(true);

    setStatusProdutoModal(true);
    setCategoriaProdutoModal(categorias[0].id);
  };
  const handleEditOpenCategoria = (params) => {
    setEditModal(true);
    setIdCategoriaModal(params.id);
    setNomeCategoriaModal(params.categoria);
    setOpenNewCategoria(true);
  };
  const handleNewClose = () => {
    setEditModal(false);
    setOpenNew(false);
    setOpenNewCategoria(false);
    setNull(false);
    setNomeProdutoModal("");
    setmodeloProdutoModal("");
    setPrecoAVistaProdutoModal("");
    setPrecoAPrazoProdutoModal("");
    setCategoriaProdutoModal("");
    setDescricaoProdutoModal("");
    setEspecificacoesProdutoModal([]);
    setnewDescricaoProdutoDescModal("");
    setDescricaoProdutoValueModal("");
    setfotoSelecionada({ url: "", id: 0 });
    setFoto([]);
  };

  const filterCategoria = categorias.filter((categoria) =>
    categoria.categoria.toLowerCase().startsWith(searchCategorias.toLowerCase())
  );

  const filterProduto = produtos.filter(
    (produto) =>
      (produto.modelo.toLowerCase().startsWith(searchProdutos.toLowerCase()) ||
        produto.nome.toLowerCase().startsWith(searchProdutos.toLowerCase())) &&
      (selectTableShowProdutos === "full" ||
        produto.RlProdutoCategoria[0]?.categoriaProdutoId ===
          selectTableShowProdutos)
  );

  const openDialogStatus = () => {
    function handleAccept() {
      handleApiDeleteProduto(showDialogStatus.id);
      setshowDialogStatus({ open: false, id: "" });
    }
    function handleNoAccept() {
      setshowDialogStatus({ open: false, id: "" });
    }

    return (
      <Dialog
        maxWidth="xs"
        open={showDialogStatus.open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <DialogTitle variant="h6">Atenção</DialogTitle>
        <DialogContent>
          {
            " Ao clicar em sim você apagará esse produto definitivamente do banco, deseja continuar?"
          }
        </DialogContent>

        <DialogActions>
          <Button color="secondary" variant="text" onClick={handleNoAccept}>
            Não
          </Button>
          <Button background="primary" variant="text" onClick={handleAccept}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const navigate = useNavigate();
  useEffect(() => {
    async function permissionValidate() {
      try {
        const response = await api.get("/authPermissionClient");

        if (response.data.message !== "Permission is valid") {
          //navigate("/homeIn");
        }
      } catch (error) {
        console.error(error);
      }
    }
    permissionValidate();
  }, []);

  useEffect(() => {
    async function apiget(params) {
      try {
        const response = await api.get("/getIotProdutos");
        setProdutos(response.data);
        setLoadingPage(true);
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    }
    async function apiGetCategorias(params) {
      try {
        const response = await api.get("/getCategoriasProduto");
        setCategorias(response.data);
        setLoadingPage(true);
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    }
    apiGetCategorias();
    apiget();
  }, []);

  useEffect(() => {
    // Função para agrupar as fotos em grupos de cinco
    const agruparFotos = () => {
      const novoGruposDeFotos = [];
      for (let i = 0; i < foto.length; i += 5) {
        novoGruposDeFotos.push(foto.slice(i, i + 5));
      }
      return novoGruposDeFotos;
    };

    // Atualiza o estado dos grupos de fotos
    setFotoC(agruparFotos());
    setfotoSelecionada({ url: foto[0]?.foto, id: 0 });
  }, [foto]);

  const removerFoto = (indexToRemove) => {
    const novaListaDeFotos = [...foto];
    novaListaDeFotos.splice(indexToRemove, 1);
    setFoto(novaListaDeFotos);
  };
  const removerespecificacao = (indexToRemove) => {
    const novaLista = [...especificacoesProdutoModal];
    novaLista.splice(indexToRemove, 1);
    setEspecificacoesProdutoModal(novaLista);
  };
  const removerProduto = (idToRemove) => {
    const novaLista = produtos.filter((produto) => produto.id !== idToRemove);
    setProdutos(novaLista);
  };

  const colunaCategorias = [
    { width: 320, field: "id", headerName: "id" },
    { flex: 1, field: "categoria", headerName: "Categoria" },
    {
      width: 100,
      field: ".",
      headerName: "Produtos",
      renderCell: (params) => {
        const quant = produtos.filter(
          (objeto) =>
            objeto.RlProdutoCategoria[0]?.categoriaProdutoId === params.row.id
        ).length;
        return (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              color: quant == 0 ? "#ED6C02" : null,
            }}
          >
            {quant}
            <WarningIcon sx={{ display: quant == 0 ? null : "none" }} />
          </Typography>
        );
      },
    },
    {
      width: 130,
      field: "..",
      headerName: "Produtos Ativos",
      renderCell: (params) => {
        const quant = produtos.filter(
          (objeto) =>
            objeto.RlProdutoCategoria[0]?.categoriaProdutoId ===
              params.row.id && objeto.status === true
        ).length;
        return (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              color: quant == 0 ? "#ED6C02" : null,
            }}
          >
            {quant}
            <WarningIcon sx={{ display: quant == 0 ? null : "none" }} />
          </Typography>
        );
      },
    },
    {
      width: 190,
      field: "...",
      headerName: "Produtos com pendências",
      renderCell: (params) => {
        const quant = produtos.filter(
          (objeto) =>
            objeto.RlProdutoCategoria[0]?.categoriaProdutoId ===
              params.row.id &&
            (objeto.descricao === "<p><br></p>" ||
              objeto.descricao === "" ||
              !objeto.especificacoes[0])
        ).length;
        return (
          <Tooltip title="Produtos com descrição ou especificação em branco">
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: quant == 0 ? null : "#ED6C02",
              }}
            >
              {quant}
              <WarningIcon sx={{ display: quant == 0 ? "none" : null }} />
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerAlign: "center",
      sortable: false,
      field: "a",
      headerName: "Ação",
      width: 60,
      renderCell: (params) => (
        <>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEditOpenCategoria(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const coluna = [
    { flex: 1, field: "nome", headerName: "Nome" },
    { width: 100, field: "modelo", headerName: "Modelo" },
    {
      width: 105,
      field: "preco",
      headerName: "Preço à vista",
      renderCell: (params) => <Typography>R${params.value}</Typography>,
    },
    {
      width: 110,
      field: "desconto",
      headerName: "Preço à prazo",
      renderCell: (params) => <Typography>R${params.value}</Typography>,
    },
    {
      width: 160,
      headerAlign: "center",
      field: "RlProdutoCategoria",
      headerName: "Categoria",
      renderCell: (params) => {
        const idCategoria =
          params.row.RlProdutoCategoria[0]?.categoriaProdutoId;
        const nomeCategoria = categorias.find(
          (cat) => cat.id === idCategoria
        )?.categoria;
        return (
          <Button
            variant="text"
            onClick={() => setSearchcategorias(nomeCategoria)}
            sx={{
              color: "inherit",
              mixBlendMode: "difference",
              textTransform: "none",
              width: "100%",
              padding: "0px",
            }}
          >
            {nomeCategoria}
          </Button>
        );
      },
    },
    {
      headerAlign: "center",
      field: "especificacoes",
      headerName: "Especificações",
      width: 120,
      renderCell: (params) =>
        params.value[0] ? (
          <Tooltip title="Produto com especificação/ões">
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "chip.completed",
              }}
            >
              <CheckIcon />
              Contém
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip title="Produto sem especificação/õe">
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "#ED6C02",
              }}
            >
              <WarningIcon /> Vazio
            </Typography>
          </Tooltip>
        ),
    },
    {
      headerAlign: "center",
      field: "descricao",
      headerName: "Descrição",
      width: 120,
      renderCell: (params) =>
        params.value != "<p><br></p>" && params.value != "" ? (
          <Tooltip title="Produto com descrição">
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "chip.completed",
              }}
            >
              <CheckIcon />
              Contém
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip title="Produto sem descrição">
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "#ED6C02",
              }}
            >
              <WarningIcon /> Vazio
            </Typography>
          </Tooltip>
        ),
    },
    {
      headerAlign: "center",
      field: "status",
      headerName: "Status",
      width: 85,
      renderCell: (params) =>
        params.value ? (
          <Chip
            label="Ativo"
            variant="contained"
            sx={{
              width: "100px",
              backgroundColor: "chip.completed",
              color: "white",
            }}
          />
        ) : (
          <Chip
            label="Inativo"
            variant="contained"
            sx={{
              width: "100px",
              backgroundColor: "chip.pending",
              color: "white",
            }}
          />
        ),
    },
    {
      headerAlign: "center",
      sortable: false,
      field: "a",
      headerName: "Ação",
      width: 135,
      renderCell: (params) => (
        <>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEditeOpen(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir">
            <IconButton
              onClick={() => setshowDialogStatus({ open: true, id: params.id })}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview">
            <IconButton
              onClick={() => navigate(`/iot/previewProduto/${params.id}`)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  async function handleApiEditProduto(id) {
    setNull(true);
    try {
      if (
        !nomeProdutoModal ||
        !modeloProdutoModal ||
        !precoAPrazoProdutoModal ||
        !precoAVistaProdutoModal ||
        parseFloat(precoAVistaProdutoModal) < 0 ||
        parseFloat(precoAPrazoProdutoModal) < 0
      )
        return;

      const response = await api.put("/putIotProduto", {
        id: id,
        nome: nomeProdutoModal,
        modelo: modeloProdutoModal,
        desconto: parseFloat(precoAVistaProdutoModal),
        especificacoes: especificacoesProdutoModal,
        categoriaProdutoId: cateoriaProdutoModal,
        descricao: descricaoProdutoModal,
        preco: parseFloat(precoAPrazoProdutoModal),
        status: statusProdutoModal,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Ocorreu um erro ao realizar alteração do produto, tente novamente.",
          severity: "error",
        });
      } else {
        const responseMidia = await api.post("/postManyMidiaProduto", {
          iotProdutosId: id,
          midia: foto,
        });

        if (responseMidia.data.status === "Error") {
          setSnackbar({
            children:
              "Error: Ocorreu um erro ao realizar alteração das imagens do produto, tente novamente.",
            severity: "error",
          });
        }

        const listaProdutos = produtos;

        listaProdutos.map((produto) => {
          if (produto.id == response.data.iotProduto.id) {
            produto.nome = response.data.iotProduto.nome;
            produto.modelo = response.data.iotProduto.modelo;
            produto.desconto = response.data.iotProduto.desconto;
            produto.especificacoes = response.data.iotProduto.especificacoes;
            produto.descricao = response.data.iotProduto.descricao;
            produto.preco = response.data.iotProduto.preco;
            produto.status = response.data.iotProduto.status;
            if (responseMidia.data.status === "Error")
              produto.MidiaProduto = response.data.iotProduto.MidiaProduto;
            else produto.MidiaProduto = foto;
            produto.RlProdutoCategoria = [response.data.Categoria];
          }
        });

        setProdutos(listaProdutos);
        handleNewClose();
        setSnackbar({
          children: "Produto atualizado com sucesso",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        children:
          "Error: Não foi possível concluir a operação, tente novamente.",
        severity: "error",
      });
    }
  }
  async function handleApiDeleteProduto(id) {
    try {
      const response = await api.post("/deleteIotProduto", {
        id: id,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Ocorreu um erro ao apagar o produto, tente novamente.",
          severity: "error",
        });
      } else {
        removerProduto(id);
        setSnackbar({
          children: "Produto deletado com sucesso",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        children:
          "Error: Não foi possível concluir a operação, tente novamente.",
        severity: "error",
      });
    }
  }
  async function handleApiEditCategoria(idCategoriaModal) {
    setNull(true);
    try {
      if (!nomeCategoriaModal) return;

      const response = await api.put("/putCategoriaProduto", {
        categoria: nomeCategoriaModal,
        id: idCategoriaModal,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Ocorreu um erro ao cadastrar a nova categoria, tente novamente.",
          severity: "error",
        });
      } else {
        const lista = categorias;
        lista.map((cat) => {
          if (cat.id == response.data.id) {
            cat.categoria = response.data.categoria;
          }
        });
        setCategorias(lista);
        handleNewClose();
        setSnackbar({
          children: "Categoria alterada com sucesso",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        children:
          "Error: Não foi possível concluir a operação, tente novamente.",
        severity: "error",
      });
    }
  }
  async function handleApiNovaCategoria() {
    setNull(true);
    try {
      if (!nomeCategoriaModal) return;

      const response = await api.post("/postCategoriaProduto", {
        categoria: nomeCategoriaModal,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Ocorreu um erro ao cadastrar a nova categoria, tente novamente.",
          severity: "error",
        });
      } else {
        const lista = categorias;
        lista.push(response.data);
        setCategorias(lista);
        handleNewClose();
        setSnackbar({
          children: "Categoria criada com sucesso",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        children:
          "Error: Não foi possível concluir a operação, tente novamente.",
        severity: "error",
      });
    }
  }
  async function handleApiNovoProduto() {
    setNull(true);
    if (
      !nomeProdutoModal ||
      !modeloProdutoModal ||
      !precoAPrazoProdutoModal ||
      !precoAVistaProdutoModal
    )
      return;

    try {
      const response = await api.post("/postIotProduto", {
        nome: nomeProdutoModal,
        modelo: modeloProdutoModal,
        desconto: parseFloat(precoAVistaProdutoModal),
        categoriaProdutoId: cateoriaProdutoModal,
        especificacoes: especificacoesProdutoModal,
        descricao: descricaoProdutoModal,
        preco: parseFloat(precoAPrazoProdutoModal),
        status: statusProdutoModal,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Ocorreu um erro ao cadastrar o produto, tente novamente.",
          severity: "error",
        });
      } else {
        const listaProdutos = produtos;
        let data = response.data;

        const responseMidia = await api.post("/postManyMidiaProduto", {
          iotProdutosId: response.data.id,
          midia: foto,
        });
        if (responseMidia.data.status === "Error") {
          setSnackbar({
            children:
              "Error: Ocorreu um erro ao salvar as imagens do produto, tente editar o produto.",
            severity: "error",
          });
          data["MidiaProduto"] = [];
        } else {
          data["MidiaProduto"] = foto;
        }

        listaProdutos.push(data);
        setProdutos(listaProdutos);
        handleNewClose();
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        children: "Error: Desculpe, houve um erro interno, tente novamente.",
        severity: "error",
      });
    }
  }

  async function processarFotos(files) {
    const novasFotos = [];

    for (let i = 0; i < files.length; i++) {
      const uri = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          files[i],
          525,
          350,
          "JPEG",
          80,
          0,
          (resizedUri) => {
            resolve(resizedUri);
          },
          "base64",
          350,
          350
        );
      });

      novasFotos.push({ foto: uri });
    }
    setFoto((fotosAntigas) => [...fotosAntigas, ...novasFotos]);
  }

  return (
    <>
      {!loadingPage && (
        <Box sx={styles.LoadingLogo}>
          <Icon style={{ width: 80, height: 80 }}>
            <img src={mwIcon} style={{ width: 80, height: 80 }} alt="mw icon" />
          </Icon>

          <CircularProgress
            size={100}
            sx={styles.CircularProgress}
            thickness={2.5}
          />
        </Box>
      )}
      {loadingPage && (
        <Box sx={styles.ContainerBox}>
          <Box sx={styles.CabecalhoBox}>
            <Typography width="100%" variant="h5">
              Painel de Administração de Produtos
            </Typography>
          </Box>

          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginBottom: "10px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl size="small">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Busca
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={searchProdutos}
                  onChange={(e) => setSearchProdutos(e.target.value)}
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Busca"
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                    marginRight: "25px",
                    display: "flex",
                  }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">
                    Categoria
                  </InputLabel>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        display: "flex!important",
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectTableShowProdutos}
                    label="Categoria"
                    onChange={(e) => setSelectTableShowProdutos(e.target.value)}
                  >
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value="full"
                    >
                      <PersonIcon sx={{ marginRight: "5px" }} />
                      Todas
                    </MenuItem>
                    {categorias.map((cat) => (
                      <MenuItem
                        key={cat.categoria}
                        sx={{ display: "flex", alignItems: "center" }}
                        value={cat.id}
                      >
                        {cat.categoria}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  endIcon={<AddIcon sx={{ marginTop: "-3px" }} />}
                  onClick={handleNewOpen}
                  sx={{ fontSize: "13px", marginRight: "10px" }}
                  variant="contained"
                >
                  Novo Produto
                </Button>
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <DataGrid
                experimentalFeatures={{ newEditingApi: true }}
                editMode="row"
                rowsPerPageOptions={[25, 50, 100]}
                apiRef={apiRef}
                getRowHeight={() => "auto"}
                rows={filterProduto}
                columns={coluna}
                initialState={{
                  ...filterProduto.initialState,
                  pagination: {
                    pageSize: 25,
                  },
                }}
                loading={!loadingPage}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                autoHeight={true}
                sx={{
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "2px",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          </Paper>
          <Box mt="20px" sx={styles.CabecalhoBox}>
            <Typography width="100%" variant="h5">
              Painel de Administração de Categorias
            </Typography>
          </Box>

          <Paper
            elevation={3}
            sx={{
              padding: "10px",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginBottom: "10px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl size="small">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Busca
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={searchCategorias}
                  onChange={(e) => setSearchcategorias(e.target.value)}
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Busca"
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  endIcon={<AddIcon sx={{ marginTop: "-3px" }} />}
                  onClick={() => setOpenNewCategoria(true)}
                  sx={{ fontSize: "13px", marginRight: "10px" }}
                  variant="contained"
                >
                  Nova Categoria
                </Button>
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <DataGrid
                experimentalFeatures={{ newEditingApi: true }}
                editMode="row"
                rowsPerPageOptions={[25, 50, 100]}
                apiRef={apiRef}
                getRowHeight={() => "auto"}
                rows={filterCategoria}
                columns={colunaCategorias}
                initialState={{
                  ...filterCategoria.initialState,
                  pagination: {
                    pageSize: 25,
                  },
                }}
                loading={!loadingPage}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                autoHeight={true}
                sx={{
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "2px",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          </Paper>
        </Box>
      )}

      <Modal
        open={openNewCategoria}
        onClose={handleNewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "500px",
            margin: "auto",

            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <DialogTitle color="primary.main">
            Cadastrar nova categoria
          </DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-login">
                Nome da categoria
              </InputLabel>
              <OutlinedInput
                error={!nomeCategoriaModal && Null}
                id="outlined-adornment-login"
                type="text"
                value={nomeCategoriaModal}
                onChange={(e) => setNomeCategoriaModal(e.target.value)}
                label="Nome da categoria"
              />
              {!nomeCategoriaModal && Null && (
                <FormHelperText error id="accountId-error">
                  O nome da categoria não pode ser em branco.
                </FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleNewClose}
              sx={{ color: "secondary.main" }}
              variant="text"
            >
              {" "}
              Cancelar
            </Button>
            {!editModal && (
              <Button onClick={handleApiNovaCategoria} variant="text">
                {" "}
                Cadastrar
              </Button>
            )}
            {editModal && (
              <Button
                onClick={() => handleApiEditCategoria(idCategoriaModal)}
                variant="text"
              >
                {" "}
                Atualizar
              </Button>
            )}
          </DialogActions>
        </Paper>
      </Modal>
      <Modal
        open={openNew}
        onClose={handleNewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: "auto" }}
      >
        <Paper
          sx={{
            width: "1400px",
            height: "650px",
            margin: "auto",
            mt: "5vh",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <DialogTitle color="primary.main">Cadastro de Produtos</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={[
                styles.customScrollbar,
                {
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  overflowY: "auto",
                },
              ]}
            >
              <FormControl
                sx={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-login">Nome</InputLabel>
                <OutlinedInput
                  error={!nomeProdutoModal && Null}
                  id="outlined-adornment-login"
                  type="text"
                  value={nomeProdutoModal}
                  onChange={(e) => setNomeProdutoModal(e.target.value)}
                  label="Nome"
                />
                {!nomeProdutoModal && Null && (
                  <FormHelperText error id="accountId-error">
                    Nome não pode ser em branco.
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "100%", marginTop: "10px" }}
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-login">
                  Nº do modelo
                </InputLabel>
                <OutlinedInput
                  error={!modeloProdutoModal && Null}
                  id="outlined-adornment-login"
                  type="text"
                  value={modeloProdutoModal}
                  onChange={(e) => setmodeloProdutoModal(e.target.value)}
                  label="Nº do modelo"
                />
                {!modeloProdutoModal && Null && (
                  <FormHelperText error id="accountId-error">
                    Nome não pode ser em branco.
                  </FormHelperText>
                )}
              </FormControl>
              <Box>
                <FormControl
                  sx={{ width: "49%", marginTop: "10px" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-login">
                    Preço à vista
                  </InputLabel>
                  <OutlinedInput
                    error={
                      (!precoAVistaProdutoModal ||
                        parseFloat(precoAVistaProdutoModal) < 0) &&
                      Null
                    }
                    id="outlined-adornment-login"
                    type="number"
                    value={precoAVistaProdutoModal}
                    onChange={(e) => setPrecoAVistaProdutoModal(e.target.value)}
                    label="Preço à vista"
                  />
                  {(!precoAVistaProdutoModal ||
                    parseFloat(precoAVistaProdutoModal) < 0) &&
                    Null && (
                      <FormHelperText error id="accountId-error">
                        preço não pode ser em branco ou negativo.
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  sx={{ ml: "2%", width: "49%", marginTop: "10px" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-login">
                    Preço à prazo
                  </InputLabel>
                  <OutlinedInput
                    error={
                      (!precoAPrazoProdutoModal ||
                        parseFloat(precoAPrazoProdutoModal) < 0) &&
                      Null
                    }
                    id="outlined-adornment-login"
                    type="number"
                    value={precoAPrazoProdutoModal}
                    onChange={(e) => setPrecoAPrazoProdutoModal(e.target.value)}
                    label="Preço à prazo"
                  />
                  {(!precoAPrazoProdutoModal ||
                    parseFloat(precoAPrazoProdutoModal) < 0) &&
                    Null && (
                      <FormHelperText error id="accountId-error">
                        preço não pode ser em branco ou negativo.
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  sx={{ width: "49%", marginTop: "10px" }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">
                    Categoria
                  </InputLabel>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        display: "flex!important",
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={cateoriaProdutoModal}
                    label="Categoria"
                    onChange={(e) => setCategoriaProdutoModal(e.target.value)}
                  >
                    {categorias.map((cat) => (
                      <MenuItem
                        key={cat.categoria}
                        sx={{ display: "flex", alignItems: "center" }}
                        value={cat.id}
                      >
                        {cat.categoria}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ ml: "2%", width: "49%", marginTop: "10px" }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label2">Status</InputLabel>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        display: "flex!important",
                      },
                    }}
                    labelId="demo-select-small-label2"
                    id="demo-select-smalls"
                    value={statusProdutoModal}
                    label="Status"
                    onChange={(e) => setStatusProdutoModal(e.target.value)}
                  >
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value={true}
                    >
                      <VisibilityIcon sx={{ marginRight: "5px" }} />
                      Ativo
                    </MenuItem>
                    <MenuItem
                      sx={{ display: "flex", alignItems: "center" }}
                      value={false}
                    >
                      <VisibilityOffIcon sx={{ marginRight: "5px" }} />
                      Inativo
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Typography sx={{ m: 1, color: "" }}>
                Descrição do produto:
              </Typography>

              <Editor
                value={descricaoProdutoModal}
                onChange={(value) => {
                  setDescricaoProdutoModal(value);
                }}
              />
              <Typography sx={{ mt: 7, color: "" }}>
                Especificações do produto:
              </Typography>
              {especificacoesProdutoModal[0] && (
                <TableContainer
                  sx={{
                    marginTop: "10px",
                    overflowX: "visible",
                    "& .MuiTableContainer-root": { overflowX: "visible" },
                  }}
                >
                  <Table>
                    <TableBody>
                      {especificacoesProdutoModal.map((row, i) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: "none",
                            },
                          }}
                        >
                          <TableCell
                            width="30%"
                            sx={{
                              fontWeight: "600",
                              color: "primary.main",
                              background: "rgba(0,0,0,0.2)",
                              fontFamily: "Rubik",
                              marginBottom: "1px",
                            }}
                            align="left"
                          >
                            {row.caracteristica}
                          </TableCell>
                          <TableCell
                            width="60%"
                            sx={{ fontFamily: "Rubik", fontWeight: "500" }}
                            align="left"
                          >
                            {row.value}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "Rubik",
                              fontWeight: "500",
                              padding: "0px 5px",
                            }}
                            align="right"
                          >
                            <IconButton
                              onClick={() => removerespecificacao(i)}
                              sx={{ m: "0px" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {!especificacoesProdutoModal[0] && (
                <Box
                  sx={{
                    mt: 1,
                    background: "rgba(0,0,0,0.1)",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.5)",
                      mixBlendMode: "difference",
                    }}
                  >
                    Nenhuma especificação cadastrada{" "}
                  </Typography>
                </Box>
              )}

              <Box sx={{ mt: "10px" }}>
                <FormControl
                  sx={{ width: "30%", marginTop: "10px" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-login">
                    Caracteristica
                  </InputLabel>
                  <OutlinedInput
                    error={!newDescricaoProdutoDescModal && NullEsp}
                    id="outlined-adornment-login"
                    type="text"
                    value={newDescricaoProdutoDescModal}
                    onChange={(e) =>
                      setnewDescricaoProdutoDescModal(e.target.value)
                    }
                    label="Caracteristica"
                  />
                  {!newDescricaoProdutoDescModal && NullEsp && (
                    <FormHelperText error id="accountId-error">
                      Descrição não pode ser em branco.
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  sx={{ ml: "2%", width: "60%", marginTop: "10px" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-login">
                    Valor
                  </InputLabel>
                  <OutlinedInput
                    error={!newDescricaoProdutoValueModal && NullEsp}
                    id="outlined-adornment-login"
                    type="text"
                    value={newDescricaoProdutoValueModal}
                    onChange={(e) =>
                      setDescricaoProdutoValueModal(e.target.value)
                    }
                    label="Valor"
                  />
                  {!newDescricaoProdutoValueModal && NullEsp && (
                    <FormHelperText error id="accountId-error">
                      Valor não pode ser em branco.
                    </FormHelperText>
                  )}
                </FormControl>
                <IconButton
                  onClick={handleAddEspecificacao}
                  sx={{
                    ml: "10px",
                    mt: "10px",
                    mb: "10px",
                    backgroundColor: "chip.waitingReturn",
                  }}
                  variant="contained"
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                alignItems: "center",
              }}
            >
              <Typography width="100%" m={1}>
                Fotos do produto:
              </Typography>
              {foto[0] && (
                <>
                  <Box
                    sx={{ background: "black" }}
                    key={"fotoselectq"}
                    component="img"
                    src={fotoSelecionada.url}
                    alt="Imagem"
                    maxWidth="450px"
                    height="300px"
                  />

                  <Box
                    sx={{
                      borderRadius: "5px",
                      background: "rgba(0,0,0,0.2)",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    width="100%"
                    height="76px"
                  >
                    <Carousel
                      visibleItems={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      animation="slide"
                      autoPlay={false}
                      cycleNavigation={false}
                      navButtonsAlwaysVisible={true}
                      indicators={false}
                    >
                      {fotoC.map((grupos, i) => (
                        <Grid
                          key={"nuuc" + i}
                          container
                          spacing={1}
                          sx={{ marginLeft: "50px" }}
                        >
                          {fotoC[i].map((f, index) => (
                            <Grid key={"nuc" + index} item xs={2}>
                              <Button
                                onClick={() =>
                                  setfotoSelecionada({
                                    url: foto[i * 5 + index].foto,
                                    id: i * 5 + index,
                                  })
                                }
                                sx={{ width: "100px", height: "75px" }}
                              >
                                <Box
                                  component="img"
                                  src={f.foto}
                                  alt="Imagem"
                                  maxWidth="90px"
                                  height="60px"
                                />
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      ))}
                    </Carousel>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button variant="contained" component="label">
                      <PhotoCamera sx={{ mr: "5px" }} /> Adicionar mais
                      <input
                        hidden
                        multiple
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => {
                          const files = e.target.files;
                          processarFotos(files);
                        }}
                      />{" "}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => removerFoto(fotoSelecionada.id)}
                    >
                      <DeleteIcon />
                      Remover Atual
                    </Button>
                    <Button variant="contained" onClick={() => setFoto([])}>
                      <DeleteSweepIcon />
                      Remover todas
                    </Button>
                  </Box>
                </>
              )}
              {!foto[0] && (
                <>
                  <Box
                    width="300px"
                    height="300px"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <BurstModeIcon
                      sx={{ fontSize: "200px", color: "rgba(0,0,0,0.3)" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "25px",
                        marginTop: "-40px",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Preview
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={styles.ButtonFoto}
                  >
                    <PhotoCamera /> Adicionar
                    <input
                      hidden
                      multiple
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      onChange={(e) => {
                        const files = e.target.files;
                        processarFotos(files);
                      }}
                    />{" "}
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleNewClose}
              sx={{ color: "secondary.main" }}
              variant="text"
            >
              {" "}
              Cancelar
            </Button>
            {!editModal && (
              <Button onClick={handleApiNovoProduto} variant="text">
                {" "}
                Cadastrar
              </Button>
            )}
            {editModal && (
              <Button
                onClick={() => handleApiEditProduto(idProdutoModal)}
                variant="text"
              >
                {" "}
                Atualizar
              </Button>
            )}
          </DialogActions>
        </Paper>
      </Modal>

      {openDialogStatus()}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
