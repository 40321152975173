import { Close, FileUploadOutlined, Search, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Snackbar,
  Typography,
  Alert,
  Fade,
  LinearProgress,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  IconButton,
  DialogContentText,
} from "@mui/material";
import { useEffect, useState } from "react";
import TableJson from "./tableJson";
import EditorJson from "./editorJson";
import api from "../../../services/api";
const styles = {
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: "30px",
    minWidth: "70vw",
    display: "flex",
  },
  boxFiles: {
    padding: "0 3.5vw",
    display: "flex",
    position: "sticky",
    mt: "88px",
    top: 0,
    zIndex: 1,
  },
  paperFiles: {
    width: "300px",
    height: "calc(105vh - 95px)",

    //height: "100%",
  },
  stackFiles: { gap: "20px", padding: "20px" },
  tabTop: {
    alignItems: "center",
    justifyContent: "center",
    direction: "column",
    width: "100%",
    height: "auto",
    paddingBottom: 6,
  },
  paperCreateModal: {
    width: "500px",
    margin: "auto",
    mt: "30vh",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "10px",
  },
  boxContainerCreateModal: { width: "85%", ml: "5%", mt: 1 },
  boxChildrenCreateModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: "-17px",
    mb: "-17px",
  },
  select: { width: "240px" },
  textField: { width: "220px" },
  typographyCreateModal: { fontSize: "0.75rem" },
  tab: {
    maxWidth: "300px",
    justifyContent: "start",
    paddingLeft: "30px",
    paddingRight: "30px",
    textTransform: "none",
    fontSize: "1rem",
  },
};
export default function JsonServices(params) {
  const [snackbar, setSnackbar] = useState(null);
  const [value, setValue] = useState(0);
  const [conteudo, setConteudo] = useState("");
  const [titulo, setTitulo] = useState("");
  const [valueTypeView, setValueTypeView] = useState(0);
  const [rows, setRows] = useState([]);
  const [fileName, setFileName] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const [valueIndex, setValueIndex] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [confirmarCreate, setConfirmarCreate] = useState(false);
  const [open, setOpenModalUpload] = useState(false);
  const [editar, setEditar] = useState(false);
  const maxCharLength = 25;
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDecideDialog, setOpenDecideDialog] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");
  const [filterText, setFilterText] = useState("");
  const [arquivos, setArquivos] = useState([{}]);
  useEffect(() => {
    handleApiGetJsons();
  }, []);
  const handleFilterChange = (event, newValue) => {
    setFilterText(newValue || "");
  };
  const filteredArquivos = Array.isArray(arquivos)
    ? arquivos.filter((arquivo) =>
        `${arquivo.title}`.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  const handleSetId = (array) => {
    return array.map((value, index) => {
      return { ...value, id: index };
    });
  };
  const handleFindFile = (jsonFile, title) => {
    return jsonFile.find((value) => value.title === title);
  };
  const handleFileUpload = (event) => {
    const uploadedFiles = event.target.files;

    Array.from(uploadedFiles).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        try {
          const jsonObject = {
            title: file.name.slice(0, file.name.lastIndexOf(".")),
            content: JSON.parse(fileContent),
          };
          setUploadFiles((arquivos) => [...arquivos, jsonObject]);
        } catch (error) {
          setSnackbar({
            children: "Error: Não foi possivel ler o(s) arquivo(s)",
            severity: "error",
          });
          console.log(error);
        }
      };
      reader.readAsText(file);
    });
    setOpenModalUpload(true);
  };
  const handleFilesCreate = (novosArquivos) => {
    let novoArray = [...arquivos];
    novosArquivos.map((novoArquivo, index) => {
      const arquivoExistenteIndex = arquivos.findIndex(
        (arquivo) => novoArquivo.title === arquivo.title
      );
      if (arquivoExistenteIndex !== -1) {
        novoArray[arquivoExistenteIndex] = novoArquivo;
      } else {
        novoArray.push(novoArquivo);
      }
    });
    setArquivos(novoArray);
  };
  const removerArquivo = (indexToRemove) => {
    const novaListaDeArquivos = [...uploadFiles];
    novaListaDeArquivos.splice(indexToRemove, 1);
    setUploadFiles(novaListaDeArquivos);
    if (indexToRemove === 0) {
      setOpenModalUpload(false);
    }
  };
  function deleteArquivo(title) {
    const newArray = arquivos.filter((arquivo) => arquivo.title !== title);
    setArquivos(newArray);
    const atualizaIndex = valueIndex - 1 >= 0 ? valueIndex - 1 : 0;
    setValue(atualizaIndex);
    setConteudo(arquivos[atualizaIndex].content);
    setTitulo(arquivos[atualizaIndex].title);
  }
  async function handleApiGetJsons() {
    try {
      setLoadingPage(true);
      const response = await api.get("/jsonServices/findMany");

      if (response.data && response.data.length > 0) {
        const jsonFind = response.data;
        setArquivos(jsonFind);
        setConteudo(jsonFind[0].content);
        setTitulo(jsonFind[0].title);
        setRows(handleSetId(jsonFind[0].content));
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível consultar as imagens",
        severity: "error",
      });
    } finally {
      setLoadingPage(false);
    }
  }
  async function handleApiCreateJson(files) {
    try {
      setLoadingUpload(true);
      setConfirmarCreate(true);
      const response = await api.post("/jsonServices/upload", { files });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Ocorreu um erro ao adicionar o arquivo",
          severity: "error",
        });
      }
      if (response.data) {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        handleFilesCreate(files);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível adicionar o arquivo",
        severity: "error",
      });
    } finally {
      setLoadingUpload(false);
      setOpenCreateDialog(false);
      setOpenDecideDialog(false);
      setConfirmarCreate(false);
      setOpenModalUpload(false);
      setUploadFiles("");
      setFileName("");
    }
  }
  function updateArquivos(novoConteudo, titulo) {
    const newArray = arquivos.map((arquivo) => {
      if (arquivo.title === titulo) {
        return { title: arquivo.title, content: novoConteudo };
      }
      return arquivo;
    });
    setArquivos(newArray);
  }
  async function handleApiUpdateJson(file) {
    try {
      setLoadingUpload(true);
      const response = await api.post("/jsonServices/upload", { files: file });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Ocorreu um erro ao atualizar o arquivo",
          severity: "error",
        });
      }
      if (response.data) {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updateArquivos(file[0].content, file[0].title);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível adicionar o arquivo",
        severity: "error",
      });
    } finally {
      setLoadingUpload(false);
      setEditar(false);
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.boxFiles}>
          <Stack direction={"column"} sx={{ position: "relative" }}>
            <Paper
              sx={{
                width: "300px",
                height: "100vh",
              }}
            >
              <Stack direction={"column"} sx={styles.stackFiles}>
                <Typography color={"primary"} fontWeight={500}>
                  Arquivos
                </Typography>

                <Autocomplete
                  disablePortal
                  freeSolo
                  options={arquivos.map((arquivo) => arquivo.title)}
                  value={filterText}
                  onChange={handleFilterChange}
                  renderInput={(params) => (
                    <>
                      <TextField
                        id="Arquivos"
                        variant="standard"
                        {...params}
                        label="Filtrar arquivos"
                        fullWidth
                      />
                      <Fade in={loadingPage}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
                <Button
                  variant="contained"
                  disabled={loadingPage}
                  onClick={() => {
                    setOpenCreateDialog(true);
                  }}
                >
                  Novo Arquivo{" "}
                </Button>
                <Button
                  disabled={loadingPage}
                  component="label"
                  startIcon={<FileUploadOutlined />}
                >
                  Fazer Upload de arquivo
                  <input
                    hidden
                    multiple
                    accept=".json"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </Button>
              </Stack>

              <Tabs
                orientation="vertical"
                variant="scrollable"
                scrollButtons={true}
                value={value}
                sx={{ maxHeight: " calc(100vh - 260px)" }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                {Array.isArray(filteredArquivos)
                  ? filteredArquivos.map((arquivo, index) => (
                      <Tab
                        key={index}
                        //label={arquivo.title}
                        label={
                          arquivo.title?.length > maxCharLength
                            ? `${arquivo.title?.substring(0, maxCharLength)}...`
                            : arquivo.title
                        }
                        iconPosition="start"
                        value={index}
                        sx={styles.tab}
                        onClick={() => {
                          setValueIndex(index);
                          setConteudo(arquivo.content);
                          setRows(handleSetId(arquivo.content));
                          setTitulo(arquivo.title);
                          setEditar(false);
                        }}
                      />
                    ))
                  : null}
              </Tabs>
            </Paper>
          </Stack>
        </Box>
        <Stack gap={"40px"} sx={styles.tabTop}>
          <Paper
            sx={{
              borderRadius: "15px",
              minWidth: "260px",
              maxWidth: "870px",
            }}
          >
            <Tabs
              variant="scrollable"
              scrollButtons={true}
              value={valueTypeView}
              onChange={(event, newValue) => {
                setValueTypeView(newValue);
              }}
            >
              <Tab label={"Tabela"} iconPosition="start" value={0} />
              <Tab label={"editor"} iconPosition="start" value={1} />
            </Tabs>
          </Paper>
          {valueTypeView === 0 && (
            <TableJson rows={rows} titulo={titulo} loadingPage={loadingPage} />
          )}
          {valueTypeView === 1 && (
            <EditorJson
              conteudo={conteudo}
              setConteudo={setConteudo}
              setRows={setRows}
              handleSetId={handleSetId}
              titulo={titulo}
              editar={editar}
              setEditar={setEditar}
              loadingPage={loadingPage}
              loadingUpload={loadingUpload}
              deleteArquivo={deleteArquivo}
              handleApiUpdateJson={handleApiUpdateJson}
            />
          )}
        </Stack>
      </Box>
      <Dialog
        open={openCreateDialog}
        onClose={() => {
          setOpenCreateDialog(false);
        }}
      >
        <DialogTitle color={"primary"}>Adicionar Arquivo</DialogTitle>
        <DialogContent>
          <TextField
            id="fabricante"
            variant="standard"
            label="Nome do arquivo"
            value={fileName}
            onChange={(event) => {
              setFileName(event.target.value);
            }}
            sx={styles.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenCreateDialog(false);
              setFileName("");
            }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={confirmarCreate}
            onClick={() => {
              if (handleFindFile(arquivos, fileName)) {
                setOpenDecideDialog(true);
              } else {
                handleApiCreateJson([
                  {
                    title: fileName,
                    content: [{ "{#SERVICE}": "", "{#URL}": "", "{#TIPO}": 0 }],
                  },
                ]);
              }
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDecideDialog}
        onClose={() => {
          setOpenDecideDialog(false);
        }}
      >
        <DialogTitle color={"primary"}>
          Substituir o arquivo "{fileName}"?
        </DialogTitle>

        <DialogContent>
          {" "}
          <DialogContentText>
            {
              "Um outro arquivo com o mesmo nome já existe em /services. Substituir irá sobrescrever o conteúdo."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenDecideDialog(false);
              setOpenCreateDialog(false);
              setFileName("");
            }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={confirmarCreate}
            onClick={() => {
              handleApiCreateJson([
                {
                  title: fileName,
                  content: [{ "{#SERVICE}": "", "{#URL}": "", "{#TIPO}": 0 }],
                },
              ]);
            }}
          >
            Substituir
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={() => {
          setOpenModalUpload(false);
          setUploadFiles("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: "auto" }}
      >
        <Paper sx={styles.paperCreateModal}>
          <DialogTitle color="primary.main">Upload de arquivos</DialogTitle>
          <DialogContent>
            {Array.isArray(uploadFiles) && uploadFiles.length > 0
              ? uploadFiles.map((arquivo, index) => (
                  <Box key={index} sx={styles.boxContainerCreateModal}>
                    <Typography sx={styles.typographyCreateModal}>
                      {arquivo.title ? arquivo.title : `Arquivo ${index + 1}`}
                    </Typography>
                    <Box sx={styles.boxChildrenCreateModal}>
                      <LinearProgress
                        sx={{ width: "100%" }}
                        variant="determinate"
                        value={100}
                      />
                      <IconButton onClick={() => removerArquivo(index)}>
                        <Close sx={{ width: "20px" }} />
                      </IconButton>
                    </Box>
                    <Typography sx={styles.typographyCreateModal}>
                      Completo
                    </Typography>
                  </Box>
                ))
              : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalUpload(false);
                setUploadFiles("");
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={loadingUpload}
              endIcon={<Send />}
              variant="contained"
              onClick={() => {
                handleApiCreateJson(uploadFiles);
              }}
            >
              Enviar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
