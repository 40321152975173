import * as React from "react";
import Box from "@mui/material/Box";

import {
  Alert,
  Snackbar,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Modal,
  Paper,
  Autocomplete,
  Dialog,
  DialogContentText,
} from "@mui/material";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import { Delete, Edit, Dangerous } from "@mui/icons-material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import api from "../../services/api";
import { Rect } from "@react-pdf/renderer";

export default function AskConfirmationBeforeSave(prop) {
  const { rows, setRows, categorias, subCategorias, colaboradores, clientes } =
    prop;
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [categoria, setCategoria] = React.useState("");
  const [subcategoria, setSubcategoria] = React.useState("");
  const [codigo, setCodigo] = React.useState("");
  const [colaborador, setColaborador] = React.useState("");
  const [cliente, setCliente] = React.useState(null);
  const [parceiro, setParceiro] = React.useState(null);
  const [data, setData] = React.useState(dayjs(new Date()));
  const [confirmarLoading, setConfirmarLoading] = React.useState(false);

  const [snackbar, setSnackbar] = React.useState(null);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [rowId, setRowId] = React.useState("");
  const [confirmarDelete, setConfirmarDelete] = React.useState(false);

  const handleCloseSnackbar = () => setSnackbar(null);

  async function editarRelatorio() {
    try {
      setConfirmarLoading(true);
      const response = await api.put("/updateRelatorio", {
        id,
        nome: colaborador,
        codigo: codigo,
        categoria: categoria,
        subCategoria: subcategoria,
        parceiro: parceiro.parceiro.toUpperCase(),
        cliente: `${cliente.numero} ${cliente.cliente}`,
        AcessoClienteId: cliente.id,
        data: data.toString(),
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival editar os dados",
          severity: "error",
        });
        setConfirmarLoading(false);
      } else {
        const responseRelatorios = await api.get("/relatorios");
        setRows(responseRelatorios.data);
        setConfirmarLoading(false);
        setOpen(false);
        setSnackbar({
          children: "Dados editado com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setConfirmarLoading(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar ao servidor",
        severity: "error",
      });
      console.error();
    }
  }
  async function deleteRow() {
    try {
      setConfirmarDelete(true);
      const response = await api.post("/delete", { id: rowId });

      if (response.data.status === "Error") {
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
        setRowId("");
        setSnackbar({
          children: "Error: Não foi possível deletar os dados selecionados",
          severity: "error",
        });
      } else {
        const responseRelatorios = await api.get("/relatorios");
        setRows(responseRelatorios.data);
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
        setRowId("");
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setOpenDeleteModal(false);
      setConfirmarDelete(false);
      setRowId("");
      setSnackbar({
        children: "Error: Não foi possível se conectar ao servidor",
        severity: "error",
      });
      console.error();
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 60,
    },

    {
      field: "nome",
      headerName: "Nome",
      width: 120,
    },
    {
      field: "codigo",
      headerName: "Código",
      width: 100,
    },
    {
      field: "categoria",
      headerName: "Categoria",

      width: 130,
    },
    {
      field: "subcategoria",
      headerName: "Sub-Categoria",

      width: 240,
    },
    { field: "parceiro", headerName: "parceiro", width: 100 },
    { field: "cliente", headerName: "cliente", width: 150 },

    {
      field: "data",
      headerName: "Data de criação",
      type: "date",

      width: 140,
    },

    {
      field: "actions",
      headerName: "ações",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          let parceiroAux = clientes.filter(
            (obj) =>
              obj.parceiro.toLowerCase() === thisRow.parceiro.toLowerCase()
          );
          let clienteAux = parceiroAux[0].clientes?.filter(
            (obj) => `${obj.numero} ${obj.cliente}` === thisRow.cliente
          );

          setColaborador(thisRow.nome);
          setCodigo(thisRow.codigo);
          setData(dayjs(thisRow.data, "DD-MM-YYYY").toDate());
          setCategoria(thisRow.categoria);
          setSubcategoria(thisRow.subcategoria);
          if (Array.isArray(parceiroAux)) {
            setParceiro(parceiroAux[0]);
          }
          if (Array.isArray(clienteAux)) {
            setCliente(clienteAux[0]);
          }

          // setCliente({ cliente: thisRow.cliente });
          setId(thisRow.id);
          setOpen(true);
        };

        const onDelete = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          setOpenDeleteModal(true);
          setRowId(thisRow.id);
        };

        return (
          <Box>
            <IconButton onClick={onClick}>
              <Edit color={"primary"} />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        autoHeight={true}
        rowsPerPageOptions={[25, 50, 100]}
        initialState={{
          ...rows.initialState,
          pagination: {
            pageSize: 25,
          },
        }}
        sx={{
          fontSize: 14,
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
        getRowHeight={() => "auto"}
        disableColumnMenu
      />

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ width: "580px", padding: "10px", paddingRight: "25px" }}>
          <Box>
            <DialogTitle>Preencha o relatorio</DialogTitle>
            <DialogContent>
              <Stack direction={"row"}>
                <Box>
                  <FormControl variant="standard" sx={{ m: 1, width: "180px" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Colaborador
                    </InputLabel>
                    <Select
                      label={"Colaborador"}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={colaborador}
                      onChange={(event) => {
                        setColaborador(event.target.value);
                      }}
                    >
                      {colaboradores?.map((colaboradores, index) => (
                        <MenuItem key={index} value={colaboradores.nome}>
                          {colaboradores.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: "140px" }}
                    variant="standard"
                    label={"Código"}
                    value={codigo}
                    onChange={(event) => {
                      setCodigo(event.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"pt-br"}
                  >
                    <DatePicker
                      value={data}
                      onChange={(newValeu) => {
                        setData(newValeu);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{ m: 1, width: "140px" }}
                          label="data"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack direction={"row"}>
                <Box>
                  <FormControl variant="standard" sx={{ m: 1, width: "180px" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Categorias
                    </InputLabel>
                    <Select
                      label={"Categorias"}
                      value={categoria}
                      onChange={(event) => {
                        setCategoria(event.target.value);
                        setSubcategoria("");
                      }}
                    >
                      {categorias.map((name, index) => (
                        <MenuItem key={index} value={name.categoria}>
                          {name.categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Autocomplete
                    sx={{ m: 1, width: "300px" }}
                    onChange={(event, newValue) => {
                      setSubcategoria(newValue);
                    }}
                    value={subcategoria}
                    options={subCategorias
                      .filter((val) => val.categoria.includes(categoria))
                      .map((name, index) => name.subCategoria)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub-categoria"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Stack>
              <Stack direction={"row"}>
                <Box>
                  <Autocomplete
                    sx={{ m: 1, width: "180px" }}
                    onChange={(event, newValue) => {
                      setParceiro(newValue);
                      setCliente(null);
                    }}
                    value={parceiro}
                    options={clientes.map((name, index) => name)}
                    getOptionLabel={(option) =>
                      option ? option.parceiro?.toUpperCase() : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parceiro"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Autocomplete
                    disabled={!parceiro}
                    sx={{ m: 1, width: "180px" }}
                    onChange={(event, newValue) => {
                      setCliente(newValue);
                    }}
                    value={cliente}
                    options={
                      parceiro ? parceiro.clientes.map((name) => name) : null
                    }
                    getOptionLabel={(option) =>
                      `${option.numero} ${option.cliente}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color={"secondary"}
                onClick={() => {
                  setOpen(false);
                  setCategoria("");
                  setSubcategoria("");
                  setCodigo("");
                  setColaborador("");
                  setCodigo("");
                  setParceiro(null);
                  setCliente(null);
                }}
              >
                CANCELAR
              </Button>
              <Button
                onClick={() => {
                  editarRelatorio();
                }}
                variant="contained"
                disabled={confirmarLoading}
              >
                CONFIRMAR
              </Button>
            </DialogActions>
          </Box>
        </Paper>
      </Modal>
      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
      >
        <DialogTitle sx={{ color: "#D32F2F" }}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Dangerous color={"error"} sx={{ fontSize: 60 }} />
            </Box>
            <span style={{ color: "#D32F2F" }}>
              Uma vez após excluídos os dados não podem ser recuperados. Tem
              certeza que deseja fazer essa exclusão ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={{ width: "100px", fontWeight: "800" }}
            onClick={() => {
              deleteRow();
            }}
            disabled={confirmarDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
