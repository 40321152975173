import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";

/*import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";*/
import MyPieChart from "../../../componentes/MyPieChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Add, Delete, Edit } from "@mui/icons-material";
import api from "../../../services/api";

const columns = [
  { field: "categoria", headerName: "Categoria", width: 130 },
  {
    field: "subCategoria",
    headerName: "Sub-categoria",
    width: 400,
  },
  {
    field: "value",
    headerName: "Qtd",
    width: 80,
  },
];

const styles = {
  cardPie: { width: "620px", height: "320px" },
  cardTable: {
    width: "620px",
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    paddingTop: "40px",
  },
  boxDataGrid: { height: 280, width: "620px" },
};

export default function DashboardPage() {
  const [dataFiltroInicial, setDataFiltroInicial] = useState(new Date());
  const [dataFiltroFinal, setDataFiltroFinal] = useState(new Date());
  const [data, setData] = useState(dayjs(new Date()));
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [qtdPorPessoa, setQtdPorPessoa] = useState([]);
  const [qtdCategorias, setQtdCategorias] = useState([]);
  const [qtdSubCategoria, setQtdSubCategoria] = useState([]);
  const [qtdClientes, setQtdClientes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [qtdSubCategoriaPorData, setQtdSubCategoriaPorData] = useState();
  /* eslint-disable */

  //Demandas diáiras
  const [rowsDemandas, setRowsDemandas] = useState([]);
  const [qtdDemandasConcluidas, setQtdDemandasConcluidas] = useState(0);
  ////Demandas totais
  const [demandasTotaisInicial, setDemandasTotaisInicial] = useState(0);
  const [demandasTotaisFinal, setDemandasTotaisFinal] = useState(0);
  ////Demandas NOC
  const [demandasNOCInicial, setDemandasNOCInicial] = useState(0);
  const [demandasNOCFinal, setDemandasNOCFinal] = useState(0);
  const [comentario, setComentario] = useState("");
  const [demandaIdUpdate, setDemandaIdUpdate] = useState(0);

  useEffect(() => {
    async function handleDados() {
      const response = await api.post("/dashboard", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });

      if (response.data.qtdPorPessoa) {
        setQtdPorPessoa(response.data.qtdPorPessoa);
        setQtdCategorias(response.data.qtdCategorias);
        setQtdSubCategoria(response.data.qtdSubCategoria);
        setQtdClientes(response.data.qtdClientes);

        if (response.data.qtdSubCategoriaPorData) {
          const auxQtdSubCategoriaPorData =
            response.data.qtdSubCategoriaPorData.map((item) => {
              const formmatedDate = dayjs(item.date).format("DD/MM/YYYY");
              return { ...item, name: formmatedDate };
            });
          setQtdSubCategoriaPorData(auxQtdSubCategoriaPorData);
        }
      }
    }
    async function handleDemandas() {
      try {
        const response = await api.post("/demandas", {
          dataInicial: dataFiltroInicial.toString(),
          dataFinal: dataFiltroFinal.toString(),
        });
        if (response.data) {
          setRowsDemandas(response.data);
        }
      } catch (error) {
        console.error();
      }
    }

    handleDados();

    handleDemandas();
  }, [dataFiltroInicial, dataFiltroFinal]);

  useEffect(() => {
    const initialValue = 0;
    const sumWithInitial = qtdPorPessoa.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      initialValue
    );

    setQtdDemandasConcluidas(sumWithInitial);
  }, [qtdPorPessoa]);

  const clearModal = () => {
    ////demandas totais
    setDemandasTotaisInicial(0);
    setDemandasTotaisFinal(0);
    ////deemandas NOC
    setDemandasNOCInicial(0);
    setDemandasNOCFinal(0);
    setComentario("");
  };

  const sendRequest = async () => {
    try {
      const newElement = {
        demandasTotais_inicial: demandasTotaisInicial,
        demandasNoc_inicial: demandasNOCInicial,
        demandasTotais_final: demandasTotaisFinal,
        demandasNoc_final: demandasNOCFinal,
        comentario: comentario,
        demandasConcluidas: 0,
        data: data.toString(),
      };

      const response = await api.post("/createDemanda", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModal(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: não foi possival salvar dados",
        severity: "error",
      });
    }
  };

  const sendUpdate = async () => {
    try {
      const newElement = {
        demandasTotais_inicial: demandasTotaisInicial,
        demandasNoc_inicial: demandasNOCInicial,
        demandasTotais_final: demandasTotaisFinal,
        demandasNoc_final: demandasNOCFinal,
        comentario: comentario,
        demandasConcluidas: 0,
        data: new Date(data),
        id: demandaIdUpdate,
      };

      const response = await api.put("/updateDemandas", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModalUpdate(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: não foi possival salvar dados",
        severity: "error",
      });
    }
  };

  const sendRequestDelete = async (id) => {
    try {
      const newElement = {
        id: id,
      };

      const response = await api.post("/deleteDemandas", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModalUpdate(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: não foi possival salvar dados",
        severity: "error",
      });
    }
  };

  const columnsDemandas = [
    { field: "id", headerName: "Id", width: 60 },
    {
      field: "demandasNoc_inicial",
      headerName: "Demandas inicio do dia",
      width: 160,
    },

    {
      field: "demandasNoc_final",
      headerName: "demandas final do dia",
      width: 170,
    },

    {
      field: "demandasConcluidas",
      headerName: "Demandas concluidas",
      width: 170,
    },

    {
      field: "data",
      headerName: "Data",
      width: 170,
    },
    {
      field: "comentario",
      headerName: "Comentários",
      width: 370,
    },
    {
      field: "actions",
      headerName: "ações",
      width: 100,
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          setDemandasNOCInicial(thisRow.demandasNoc_inicial);
          setDemandasNOCFinal(thisRow.demandasNoc_final);
          setComentario(thisRow.comentario);
          setDemandaIdUpdate(thisRow.id);
          setOpenModalUpdate(true);
          setData(dayjs(thisRow.data, "DD-MM-YYYY").toDate());
        };

        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          setDemandaIdUpdate(thisRow.id);
          sendRequestDelete(thisRow.id);
        };
        return (
          <Box>
            <IconButton onClick={onClick}>
              <Edit color={"primary"} />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "30px",
      }}
    >
      <Box
        style={{
          width: "80vw",
          padding: "20px",
          minWidth: "900px",
        }}
      >
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          gap={"20px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              width: "615px",
              padding: "0px 20px",
              height: "200px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography component={"div"} variant={"h5"}>
              Filtros
            </Typography>
            <Stack direction={"row"}>
              <Stack>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    value={dataFiltroInicial}
                    onChange={(newValeu) => {
                      setDataFiltroInicial(newValeu);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        sx={{ m: 1, width: "150px" }}
                        label="Data Inicial"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack marginLeft={"20px"}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    value={dataFiltroFinal}
                    onChange={(newValeu) => {
                      setDataFiltroFinal(newValeu);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        sx={{ m: 1, width: "150px" }}
                        label="Data final"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Box>
          <Card
            sx={{
              width: "615px",
              height: "200px",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={2}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color={"primary"}
              >
                {"Quantidade de demandas concluidas"}
              </Typography>
              <Typography variant="h4" color={"TextPrimary"}>
                {qtdDemandasConcluidas}
              </Typography>
              <Typography color={"TextSecondary"}>
                entre {dayjs(dataFiltroInicial).format("DD/MM/YYYY")} e{" "}
                {dayjs(dataFiltroFinal).format("DD/MM/YYYY")}
              </Typography>
            </CardContent>
          </Card>

          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card
              sx={
                {
                  width: "1250px",
                  height: "358px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 0px",
                  paddingTop: "40px",
                } //styles.cardTable
              }
              elevation={2}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                      width: "1250px",
                      height: "40px",
                      padding: "0 20px",
                      gap: "418px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      color={"primary"}
                    >
                      {"Controle de Demandas"}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      endIcon={<Add sx={{ marginTop: "-3px" }} />}
                      sx={{
                        height: "25px",
                        marginTop: -1,
                      }}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      NOVO
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={
                    { height: 280, width: "100%" } //styles.boxDataGrid
                  }
                >
                  <DataGrid
                    rows={rowsDemandas}
                    columns={columnsDemandas}
                    density="compact"
                    pageSize={5}
                    disableSelectionOnClick
                    rowsPerPageOptions={[5]}
                    sx={{
                      fontSize: 12,
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                    }}
                    disableColumnMenu={true}
                  />
                </Box>
              </CardContent>
            </Card>
          </Stack>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card sx={styles.cardTable} elevation={2}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  {"Demandas por categoria"}
                </Typography>

                <Box sx={styles.boxDataGrid}>
                  <DataGrid
                    rows={qtdSubCategoria}
                    columns={columns}
                    density="compact"
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    sx={{
                      fontSize: 12,
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                    }}
                    disableColumnMenu={true}
                  />
                </Box>
              </CardContent>
            </Card>
            <Card elevation={2} sx={styles.cardPie}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Demandas por cliente
                </Typography>
                <Box sx={{ width: "100%", height: "320px" }}>
                  <MyPieChart data={qtdClientes} />
                </Box>
              </CardContent>
            </Card>
          </Stack>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card elevation={2} sx={styles.cardPie}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Demandas por colaborador
                </Typography>
                <Box sx={{ width: "100%", height: "320px" }}>
                  <MyPieChart data={qtdPorPessoa} />
                </Box>
              </CardContent>
            </Card>
            <Card elevation={2} sx={styles.cardPie}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Quantidade por categoria
                </Typography>
                <Box sx={{ width: "100%", height: "450px" }}>
                  <ResponsiveContainer>
                    <MyPieChart data={qtdCategorias} />
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Stack>

          {/* <Card
            sx={{
              width: { lg: "620px", xl: "1280px" },
              height: "260px",
              padding: "10px",
            }}
            elevation={2}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color={"primary"}
              >
                Categorias por Dia
              </Typography>
              <Box sx={{ width: "100%", height: "200px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={qtdSubCategoriaPorData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={13} />
                    <YAxis fontSize={13} />
                    <Tooltip />

                    <Legend
                      wrapperStyle={{
                        fontSize: "14px",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="ZABBIX"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="GRAFANA" stroke="#82ca9d" />
                    <Line
                      type="monotone"
                      dataKey="TROUBLESHOOT"
                      stroke="#D86F0C"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
                    </Card>*/}
        </Stack>
      </Box>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ padding: "15px" }}>
          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"h6"}>
              Demandas diárias
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DatePicker
                value={data}
                onChange={(newValeu) => {
                  setData(newValeu);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ m: 1, width: "150px" }}
                    label="Data"
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Inicio do dia
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  variant="standard"
                  label="Demandas Noc"
                  sx={{ width: "150px", m: 1 }}
                  value={demandasNOCInicial}
                  onChange={(event) => {
                    setDemandasNOCInicial(event.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Fim do dia
                </Typography>
              </Box>
              <TextField
                type="number"
                variant="standard"
                label="Demandas Noc"
                value={demandasNOCFinal}
                onChange={(event) => {
                  setDemandasNOCFinal(event.target.value);
                }}
                sx={{ width: "150px", m: 1 }}
              />
            </Box>
          </Box>

          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"subtitle1"}>
              Comentários
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextareaAutosize
              value={comentario}
              onChange={(event) => {
                setComentario(event.target.value);
              }}
              minRows={3}
              style={{
                width: "330px",
                resize: "none",
                borderColor: "primary",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                clearModal();
                setOpenModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={sendRequest}>
              Salvar
            </Button>
          </Box>
        </Paper>
      </Modal>

      <Modal
        open={openModalUpdate}
        onClose={() => {
          setOpenModalUpdate(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ padding: "15px" }}>
          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"h6"}>
              Demandas diárias
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DatePicker
                value={data}
                onChange={(newValeu) => {
                  setData(newValeu);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ m: 1, width: "150px" }}
                    label="Data"
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Inicio do dia
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  variant="standard"
                  label="Demandas Noc"
                  sx={{ width: "150px", m: 1 }}
                  value={demandasNOCInicial}
                  onChange={(event) => {
                    setDemandasNOCInicial(event.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Fim do dia
                </Typography>
              </Box>
              <TextField
                type="number"
                variant="standard"
                label="Demandas Noc"
                value={demandasNOCFinal}
                onChange={(event) => {
                  setDemandasNOCFinal(event.target.value);
                }}
                sx={{ width: "150px", m: 1 }}
              />
            </Box>
          </Box>

          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"subtitle1"}>
              Comentários
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextareaAutosize
              value={comentario}
              onChange={(event) => {
                setComentario(event.target.value);
              }}
              minRows={3}
              style={{
                width: "330px",
                resize: "none",
                borderColor: "primary",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                clearModal();
                setOpenModalUpdate(false);
              }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={sendUpdate}>
              Salvar
            </Button>
          </Box>
        </Paper>
      </Modal>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </div>
  );
}
