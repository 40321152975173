import { Delete, Edit } from "@mui/icons-material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import DeleteDialog from "../../../../../../componentes/deleteDialog";
import api from "../../../../../../services/api";
import { TreeItem } from "@mui/x-tree-view";

export default function TreeItemModelo(props) {
  const {
    nodeId,
    label,
    node,
    updateModelos,
    setPredefinicaoId,
    setNomeModelo,
    deleteModelo,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  //
  const [modelo, setModelo] = useState(label);
  const [funcao, setFuncao] = useState("");
  //
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const confirmarDelete = false;
  //
  const [snackbar, setSnackbar] = useState(null);

  const handleApiDelete = async () => {
    try {
      const response = await api.post("/deleteModelo", {
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setOpenDeleteModal(false);
        setSnackbar({
          children: "Error: Não foi possível excluir o modelo",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setModelo(null);
        setOpenDeleteModal(false);
        deleteModelo(node);
      }
    } catch (error) {
      console.log(error);
      setOpenDeleteModal(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };
  const handleApiEditModelo = async () => {
    try {
      const response = await api.put("/putModelo", {
        modelo,
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível editar o modelo",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updateModelos(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };

  const handleContextMenu = (event, node) => {
    event.preventDefault();
    event.stopPropagation();
    if (!openCreateModal && !openEditModal && !openDeleteModal) {
      if (
        menuPosition === null ||
        (menuPosition?.top === 0 && menuPosition?.left === 0)
      ) {
        setAnchorEl(event.currentTarget);
        setMenuPosition({ top: event.pageY, left: event.pageX });
      } else {
        handleCloseMenu();
        setAnchorEl("");
      }
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };

  return (
    <div>
      {" "}
      {modelo ? (
        <div onContextMenu={(event) => handleContextMenu(event, node.id)}>
          <TreeItem
            style={{ display: "flex", flexDirection: "column" }}
            nodeId={nodeId}
            label={modelo}
            id={nodeId}
            onClick={() => {
              setPredefinicaoId(node.Predefinicoes.id);
              setNomeModelo(modelo);
            }}
            aria-controls="simple-menu"
          />

          <Menu
            id="simple-menu"
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorReference="anchorPosition"
            anchorPosition={menuPosition}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenEditModal(true);
              }}
            >
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText>Editar Modelo</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenDeleteModal(true);
              }}
            >
              {" "}
              <ListItemIcon>
                <Delete color="secondary" />
              </ListItemIcon>
              <ListItemText>Excluir Modelo</ListItemText>
            </MenuItem>
          </Menu>
          <Dialog
            open={openCreateModal}
            onClose={() => {
              setOpenCreateModal(false);
            }}
          >
            <DialogTitle color={"primary"}>Adicionar Função</DialogTitle>
            <DialogContent>
              <TextField
                id="funcao"
                variant="standard"
                label="Função"
                value={funcao}
                onChange={(event) => {
                  setFuncao(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenCreateModal(false);
                  setFuncao("");
                }}
              >
                Cancelar
              </Button>
              <Button color="primary">Confirmar</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openEditModal}
            onClose={() => {
              setOpenEditModal(false);
              setMenuPosition(null);
            }}
          >
            <DialogTitle color={"primary"}>Editar modelo</DialogTitle>
            <DialogContent>
              <TextField
                id="modelo"
                variant="standard"
                label="Modelo"
                value={modelo}
                onChange={(event) => {
                  setModelo(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenEditModal(false);
                  setModelo(label);
                  setMenuPosition(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleApiEditModelo();
                  setOpenEditModal(false);
                  setMenuPosition(null);
                }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <DeleteDialog
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            confirmarDelete={confirmarDelete}
            handleApiDelete={handleApiDelete}
          />
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </div>
      ) : null}
    </div>
  );
}
