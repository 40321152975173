import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};

export default function EasyFlow() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const pageName = window.location.pathname;
    const pages = ["/noc/easyFlow/relatoriosFlow"];
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setValue(index);
      }
    });
  }, []);

  return (
    <Box sx={styles.container}>
      <Paper
        sx={{
          borderRadius: "15px",

          minWidth: "260px",
          maxWidth: "870px",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Relatorios Flow"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/easyFlow/relatoriosFlow");
            }}
          />
        </Tabs>
      </Paper>

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
