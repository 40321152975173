import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Modal,
  Paper,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();
const styles = {
  filter: { width: "300px" },
  textFiled: { width: "180px" },
  comunidade: { width: "375px", mt: 0.3 },
  porta: { width: "82px" },
};

export default function ModalCreateHost(props) {
  const {
    openCreateModal,
    setOpenCreateModal,
    setSnackbar,
    acessoClientesId,
    acessoProdutoId,
    predefinicoes,
    proxys,
    handleApiGetHosts,
  } = props;
  //dados host
  const [nome, setNome] = useState("");
  const [comunidade, setComunidade] = useState("public");
  const [fabricante, setFabricante] = useState([]);
  const [coletor, setColetor] = useState("0");
  const [funcao, setFuncao] = useState([]);
  const [userSsh, setUserSsh] = useState("");
  const [portaSsh, setPortaSsh] = useState(22);
  const [senhaSsh, setSenhaSsh] = useState("");
  const [userTelnet, setUserTelnet] = useState("");
  const [portaTelnet, setPortaTelnet] = useState(23);
  const [senhaTelnet, setSenhaTelnet] = useState("");
  const [ip, setIp] = useState("");
  const [portaSnmp, setPortaSnmp] = useState(161);
  const [tipoSnmp, setTipoSnmp] = useState(2);
  const [modelo, setModelo] = useState("");
  const [interfaces, setInterfaces] = useState([
    { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" },
  ]);
  //pagina
  const [error, setError] = useState("");
  const [loadingConfirmar, setLoadingConfirmar] = useState(true);

  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }

  const handleInterfaceChange = (index, event) => {
    const { name, value } = event.target;
    const newInterfaces = [...interfaces];
    newInterfaces[index][name] = value;
    setInterfaces(newInterfaces);
  };

  const addInterface = () => {
    if (interfaces.length < 2) {
      setInterfaces([
        ...interfaces,
        { ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" },
      ]);
    }
  };

  const removeInterface = (index) => {
    const newInterfaces = [...interfaces];
    newInterfaces.splice(index, 1);
    setInterfaces(newInterfaces);
  };

  const handleHosts = () => {
    let nomeHost = nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    nomeHost = `${nomeHost?.trim()} - ${interfaces[0].ip.trim()}`;
    let arr = [
      {
        nome: nomeHost,
        fabricante: fabricante?.fabricante?.toUpperCase().trim(),
        funcao: funcao?.funcao?.toUpperCase().trim(),
        modelo: modelo,
        coletor: coletor,
        userSsh,
        portaSsh: parseInt(portaSsh),
        senhaSsh,
        userTelnet,
        portaTelnet: parseInt(portaTelnet),
        senhaTelnet,
        interfaces,
      },
    ];
    return arr;
  };

  const createHosts = async () => {
    try {
      setLoadingConfirmar(true);
      const dados = handleHosts();
      const response = await api.post("/easyAdd/createMany", {
        hosts: dados,
        acessoClientesId,
        acessoProdutoId,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
        setLoadingConfirmar(false);
      } else {
        setLoadingConfirmar(false);
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
        clearModal();
        setOpenCreateModal(false);
        handleApiGetHosts(acessoClientesId, acessoProdutoId);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingConfirmar(false);
      console.error(error);
    } finally {
      setLoadingConfirmar(false);
    }
  };
  useEffect(() => {
    if (
      nome !== "" &&
      ip !== "" &&
      portaSnmp !== "" &&
      tipoSnmp !== "" &&
      comunidade !== "" &&
      fabricante !== "" &&
      coletor !== "" &&
      funcao !== "" &&
      modelo !== ""
    ) {
      setLoadingConfirmar(false);
    } else {
      setLoadingConfirmar(true);
    }
  }, [
    nome,
    ip,
    portaSnmp,
    tipoSnmp,
    comunidade,
    fabricante,
    coletor,
    funcao,
    modelo,
    interfaces,
  ]);

  function clearModal() {
    setNome("");
    setIp("");
    setPortaSnmp(161);
    setComunidade("public");
    setFabricante(null);
    setModelo("");
    setColetor("0");
    setFuncao([]);
    setUserSsh("");
    setPortaSsh(22);
    setSenhaSsh("");
    setUserTelnet("");
    setPortaTelnet(23);
    setSenhaTelnet("");
    setInterfaces([{ ip: "", portaSnmp: 161, tipoSnmp: 2, comunidade: "" }]);
  }
  return (
    <Modal
      open={openCreateModal}
      onClose={() => {
        setOpenCreateModal(false);
      }}
      sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
    >
      <Paper sx={{ minWidth: "871px" }}>
        <DialogTitle color="primary">Preencha os dados</DialogTitle>
        <DialogContent>
          <Stack gap={"12px"} direction={"column"}>
            <Stack gap={"16px"} direction={"row"}>
              <Autocomplete
                value={fabricante}
                onChange={(event, newInputValue) => {
                  setFabricante(newInputValue);
                  setFuncao([]);
                  setModelo([]);
                }}
                freeSolo
                options={predefinicoes.map((options) => options)}
                getOptionLabel={(options) => options.fabricante || ""}
                sx={styles.textFiled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fabricante"
                    variant="standard"
                    required
                  />
                )}
              />

              <Autocomplete
                disabled={!fabricante?.fabricante}
                value={funcao}
                onChange={(event, newInputValue) => {
                  setFuncao(newInputValue);
                  setModelo([]);
                }}
                freeSolo
                options={fabricante?.Funcoes?.map((options) => options)}
                getOptionLabel={(options) => options.funcao || ""}
                sx={styles.textFiled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Funções"
                    variant="standard"
                    required
                  />
                )}
              />
              <Autocomplete
                disabled={!funcao?.funcao}
                value={modelo}
                onChange={(event, newInputValue) => {
                  setModelo(newInputValue);
                }}
                freeSolo
                options={funcao?.Modelos?.map((options) => options)}
                getOptionLabel={(options) => options.modelo || ""}
                sx={styles.textFiled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Modelo"
                    variant="standard"
                    required
                  />
                )}
              />
              <TextField
                id="nome-host"
                variant="standard"
                label="Nome"
                value={nome}
                onChange={(event) => {
                  setNome(event.target.value);
                }}
                required
                sx={styles.textFiled}
              />
            </Stack>
            <Stack mb={-2} direction={"row"} alignItems={"center"}>
              <Typography
                color={"primary"}
                fontWeight={500}
                ml={-0.2}
                fontSize={12}
              >
                Interfaces
              </Typography>
              <IconButton size="small" onClick={addInterface}>
                {interfaces.length >= 2 ? null : <Add color="primary" />}
              </IconButton>
            </Stack>

            {interfaces.map((value, index) => (
              <Stack key={index} gap={"16px"} direction={"row"}>
                <TextField
                  id="ip"
                  name="ip"
                  variant="standard"
                  label="IP"
                  value={interfaces[index].ip}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                    setIp(event.target.value);
                  }}
                  onBlur={() => setError(validateIp(ip))}
                  error={Boolean(error)}
                  helperText={error}
                  required
                  sx={styles.textFiled}
                />{" "}
                <TextField
                  id="host-porta"
                  name="portaSnmp"
                  variant="standard"
                  label="Porta SNMP"
                  type="number"
                  value={interfaces[index].portaSnmp}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                    setPortaSnmp(event.target.value);
                  }}
                  required
                  sx={styles.porta}
                />
                <FormControl variant="standard" sx={styles.porta}>
                  <InputLabel shrink> Tipo SNMP</InputLabel>
                  <Select
                    input={""}
                    fullWidth
                    name="tipoSnmp"
                    value={interfaces[index].tipoSnmp}
                    onChange={(event) => {
                      handleInterfaceChange(index, event);
                      setTipoSnmp(event.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>V1</MenuItem>
                    <MenuItem value={2}>V2</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="comunidade-hosts"
                  sx={styles.comunidade}
                  variant="standard"
                  label="Comunidade"
                  name="comunidade"
                  required
                  value={interfaces[index].comunidade}
                  onChange={(event) => {
                    handleInterfaceChange(index, event);
                    setComunidade(event.target.value);
                  }}
                />
                {interfaces.length > 1 && (
                  <IconButton onClick={() => removeInterface(index)}>
                    <Delete color="secondary" />
                  </IconButton>
                )}
              </Stack>
            ))}

            <Typography
              color={"primary"}
              fontWeight={500}
              mb={-1}
              ml={-0.2}
              fontSize={12}
            >
              Monitorado por proxy
            </Typography>

            <FormControl variant="standard" label="Coletor" required>
              <InputLabel> Coletor</InputLabel>
              <Select
                fullWidth
                value={coletor}
                onChange={(event) => {
                  setColetor(event.target.value);
                }}
                sx={styles.filter}
              >
                <MenuItem value={"0"}>ZABBIX SERVER</MenuItem>;
                {Array.isArray(proxys) &&
                  proxys.map((p, index) => {
                    return <MenuItem value={p.proxyid}>{p.host}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <Typography color={"primary"} fontWeight={500} mt={2}>
              Acesso SSH
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="standard"
                label="USER SSH"
                value={userSsh}
                onChange={(event) => {
                  setUserSsh(event.target.value);
                }}
                sx={styles.textFiled}
              />
              <TextField
                id="host-portaAcesso"
                variant="standard"
                label="Porta SSH"
                type="number"
                value={portaSsh}
                onChange={(event) => {
                  setPortaSsh(event.target.value);
                }}
                sx={styles.textFiled}
              />
              <TextField
                id="host-senha"
                variant="standard"
                label="Senha SSH"
                value={senhaSsh}
                onChange={(event) => {
                  setSenhaSsh(event.target.value);
                }}
                sx={styles.textFiled}
              />
            </Stack>
            <Typography color={"primary"} fontWeight={500} mt={2}>
              Acesso TELNET
            </Typography>
            <Stack gap={"16px"} direction={"row"}>
              <TextField
                id="host-user"
                variant="standard"
                label="USER TELNET"
                value={userTelnet}
                onChange={(event) => {
                  setUserTelnet(event.target.value);
                }}
                sx={styles.textFiled}
              />
              <TextField
                id="host-portaAcesso"
                variant="standard"
                label="Porta TELNET"
                type="number"
                value={portaTelnet}
                onChange={(event) => {
                  setPortaTelnet(event.target.value);
                }}
                sx={styles.textFiled}
              />
              <TextField
                id="host-senha"
                variant="standard"
                label="Senha TELNET"
                value={senhaTelnet}
                onChange={(event) => {
                  setSenhaTelnet(event.target.value);
                }}
                sx={styles.textFiled}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenCreateModal(false);
              clearModal();
            }}
            color="secondary"
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            disabled={loadingConfirmar}
            onClick={() => {
              createHosts();
            }}
          >
            confirmar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
