import { Close, Delete, ModeEdit, Save } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Fade,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteDialog from "../../../../componentes/deleteDialog";
import { useState, useEffect } from "react";
import api from "../../../../services/api";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
const stylesEditor = {
  paperParent: {
    minWidth: "650px",
    width: "50vw",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  stackParent: { alignItems: "center", width: "100%" },
};
export default function EditorJson(params) {
  const {
    titulo,
    conteudo,
    setConteudo,
    setRows,
    handleSetId,
    editar,
    setEditar,
    loadingPage,
    loadingUpload,
    deleteArquivo,
    handleApiUpdateJson,
  } = params;
  const [inputContent, setInputContent] = useState(
    JSON.stringify(conteudo, null, 2)
  );
  const [snackbar, setSnackbar] = useState(null);
  const [updateFile, setUpdateFile] = useState("");
  const [errorJson, setErrorJson] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  useEffect(() => {
    setInputContent(JSON.stringify(conteudo, null, 2));
    setErrorJson(false);
  }, [conteudo]);
  function Editor() {
    const colorsStyles = {
      default: `${colorMode === "light" ? "#8d8d8d" : "#6d6d6d"}`,
      background_warning: "#FFDCDB",
      string: `${colorMode === "light" ? "#E83750" : "#ACC96F"}`,
      //string: "#2c8c49", // overrides theme colors with whatever color value you want
      number: `${colorMode === "light" ? "#0288D1" : "#077CB1"}`, //"#FF514A",
      colon: "#5A12CC",
      keys: `${colorMode === "light" ? "#5B2E87" : "#BDAFE7"}`,
      primitive: "#FF746E",
      background: `${
        colorMode === "light"
          ? editar
            ? "#f2f2f2"
            : "#ffffff"
          : editar
          ? "#171717"
          : "#1E1E1E"
      }`,
    };
    return (
      <JSONInput
        id="a_unique_id"
        placeholder={conteudo}
        locale={locale}
        style={{ body: { "font-size": "1.2rem" } }}
        colors={colorsStyles}
        viewOnly={true}
        onchange
        height="100%"
        width="100%"
      />
    );
  }
  const styleJsonText = (jsonText) => {
    return jsonText
      .replace(/"([^"]+)":/g, '"$1":')
      .replace(/: "([^"]+)"/g, ': "$1"');
  };

  const jsonWithStyles = styleJsonText(JSON.stringify(conteudo, null, 2));
  const lines = parseInt(jsonWithStyles.split("\n"));

  const handleUpdate = () => {
    try {
      const parsedJson = JSON.parse(inputContent);
      if (parsedJson) {
        setConteudo(parsedJson);
        setRows(handleSetId(parsedJson));
        const editFile = [{ title: titulo, content: JSON.parse(inputContent) }];
        handleApiUpdateJson(editFile);
      }
    } catch (error) {
      console.error("Erro ao analisar JSON:", error);
      setErrorJson(true);
    }
  };

  async function handleApiDeleteJson() {
    try {
      setConfirmarDelete(true);
      const response = await api.post("/jsonServices/delete", {
        file: titulo + ".json",
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Ocorreu um erro ao deletar os arquivos",
          severity: "error",
        });
      }
      if (response.data) {
        setSnackbar({
          children: "Arquivo deletado com sucesso",
          severity: "success",
        });
        deleteArquivo(titulo);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível deletar os arquivos",
        severity: "error",
      });
    } finally {
      setConfirmarDelete(false);
      setOpenDeleteModal(false);
    }
  }

  return (
    <>
      <Stack
        direction={"column"}
        alignItems={"center"}
        width={"100%"}
        sx={stylesEditor.stackParent}
        gap={"40px"}
      >
        {" "}
        <Paper sx={stylesEditor.paperParent}>
          {loadingPage ? (
            <>
              {" "}
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
              <Skeleton variant="rounded" height={"30vh"} />
            </>
          ) : (
            <Fade in={loadingPage} {...(loadingPage ? { timeout: 500 } : {})}>
              <>
                <Stack
                  direction={"row"}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{
                      width: "83%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="h5"
                  >
                    {titulo}
                  </Typography>
                  {editar ? (
                    <Box>
                      {" "}
                      {loadingUpload ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          {" "}
                          <Tooltip title="salvar" placement="bottom">
                            <Button onClick={handleUpdate}>
                              <Save />
                            </Button>
                          </Tooltip>{" "}
                          <Tooltip title="cancelar" placement="bottom">
                            <Button
                              onClick={() => {
                                setEditar(false);
                                setErrorJson(false);
                              }}
                            >
                              <Close color="secondary" />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <Tooltip title="editar" placement="bottom">
                        <Button
                          onClick={() => {
                            setEditar(true);
                          }}
                        >
                          <ModeEdit />
                        </Button>
                      </Tooltip>
                      <Tooltip title="deletar arquivo" placement="bottom">
                        <Button
                          onClick={() => {
                            setEditar(false);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <Delete color="secondary" />
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                </Stack>
                {errorJson ? (
                  <Alert severity="error">Formato de JSON inválido!</Alert>
                ) : null}

                {editar ? (
                  <TextField
                    multiline
                    fullWidth
                    disabled={loadingUpload}
                    rows={lines}
                    variant="outlined"
                    defaultValue={jsonWithStyles}
                    value={inputContent}
                    onChange={(e) => {
                      setInputContent(e.target.value);
                      setErrorJson(false);
                    }}
                    InputProps={{
                      style: {
                        fontSize: "1.2em",
                        fontFamily: "monospace", // Para manter a formatação do JSON
                      },
                    }}
                  />
                ) : (
                  <Editor />
                )}
              </>
            </Fade>
          )}
        </Paper>
      </Stack>
      <DeleteDialog
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        confirmarDelete={confirmarDelete}
        handleApiDelete={handleApiDeleteJson}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
