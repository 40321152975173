import { useEffect, useState } from "react";
import {
  Add,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  ChevronRight,
  Delete,
  ExpandMore,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Chip,
  Tooltip,
} from "@mui/material";

import TreeItemFabricante from "./treeItemFabricante";

import Disquete from "@mui/icons-material/Save";
import Caneta from "@mui/icons-material/ModeEdit";
import Close from "@mui/icons-material/Close";
import api from "../../../services/api";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { TreeView } from "@mui/x-tree-view";

const filter = createFilterOptions();
const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
    minWidth: "1000px",
  },
  predefinicoesColumn: {
    padding: "0 3.5vw",
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  predefinicoesPaper: {
    padding: "20px",
    width: "300px",
    height: "calc(100vh - 95px)",
  },
  select: { width: "240px" },
  textfield: { width: "220px" },
  formPaper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "600px",
    maxWidth: "880px",
    width: "47vw",
  },
  MacroCampo: {
    fontSize: "16px",
    width: "220px",
    marginTop: "20px",
  },
  StyleButtons: {
    width: "40px",
    height: "40px",
  },
};

export default function Predifinicoes(params) {
  const [groupHosts, setGroupHosts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [macros, setMacros] = useState([{ macro: "", valor: "" }]);
  const [filtro, setFiltro] = useState("");
  //
  const [lastGroupHosts, setLastGroupHosts] = useState([]);
  const [lastTemplates, setLastTemplates] = useState([]);
  const [lastMacros, setLastMacros] = useState([{ macro: "", valor: "" }]);
  //
  const [predefinicaoId, setPredefinicaoId] = useState("");
  const [nomeModelo, setNomeModelo] = useState("selecione o modelo");
  const [fabricantes, setFabricantes] = useState([]);
  const [fabricante, setFabricante] = useState("");
  const [predefinicoes, setPredefinicoes] = useState([]);
  //
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxOutlined fontSize="small" />;
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selecionado, setSelecionado] = useState(false);

  const [editar, setEditar] = useState(false);

  const fabricanteFiltred = fabricantes.filter((fabricante) =>
    fabricante.fabricante.toUpperCase().includes(filtro.toUpperCase())
  );
  //
  function createFabricantes(novoFabricante) {
    const novoArray = [...fabricantes];
    novoArray.push(novoFabricante);
    setFabricantes(novoArray);
  }
  function updateFabricantes(novoFabricante) {
    const novoArray = fabricantes.map((obj) => {
      if (obj.id === novoFabricante.id) {
        return { ...obj, ...novoFabricante };
      } else {
        return obj;
      }
    });
    setFabricantes(novoArray);
  }

  function deleteFabricante(removeFabricante) {
    const novoArray = fabricantes.map((obj) => {
      if (obj.id !== removeFabricante) {
        return obj;
      } else {
        return { id: null, fabricante: null, Funcoes: [] };
      }
    });
    setFabricantes(novoArray);
    setPredefinicaoId("");
  }

  function updatePredefinicoes(novaPredefinicao) {
    const novoArray = predefinicoes.map((value) => {
      if (value.id === novaPredefinicao.id) {
        return novaPredefinicao;
      } else {
        return value;
      }
    });
    setPredefinicoes(novoArray);
  }
  function createPredefinicoes(novaPredefinicao) {
    const novoArray = predefinicoes;
    novoArray.push(novaPredefinicao);
    setPredefinicoes(novoArray);
  }

  const handleMacroChange = (index, event) => {
    const { name, value } = event.target;
    const newMacros = [...macros];
    newMacros[index][name] = value;
    setMacros(newMacros);
  };

  const addMacro = () => {
    setMacros([...macros, { macro: "", valor: "" }]);
  };

  const removeMacro = (index) => {
    const newMacros = [...macros];
    newMacros.splice(index, 1);
    setMacros(newMacros);
  };

  const handleExpand = () => {
    const funcoesExpanded = fabricantes.flatMap((value) =>
      value.Funcoes.map((funcao) => funcao.id)
    );
    const fabricantesExpanded = fabricantes.map((value) => value.id);
    const aux = [...funcoesExpanded, ...fabricantesExpanded];
    setExpanded(fabricantesExpanded);
  };

  function savePredefinicoes(newFabricantes) {
    if (newFabricantes) {
      let auxArray = [];
      newFabricantes.map((obj) => {
        obj.Funcoes.map((obj) => {
          obj.Modelos.map((obj) => {
            auxArray.push(obj.Predefinicoes);
          });
        });
      });
      setPredefinicoes(auxArray);
    }
  }

  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetTemplates();
  }, []);

  useEffect(() => {
    if (predefinicaoId) {
      //  setSelecionado(false);
      // setTimeout(() => {
      setSelecionado(true);
      // }, 200);
    } else {
      setSelecionado(false);
    }
  }, [predefinicaoId]);

  useEffect(() => {
    if (predefinicaoId) {
      const newPredefinicao = predefinicoes.find(
        ({ id }) => id === predefinicaoId
      );
      setMacros(
        newPredefinicao.macros
          ? JSON.parse(newPredefinicao.macros)
          : [{ macro: "", valor: "" }]
      );
      setTemplates(
        newPredefinicao.templates ? JSON.parse(newPredefinicao.templates) : []
      );
      setGroupHosts(
        newPredefinicao.groups ? JSON.parse(newPredefinicao.groups) : []
      );

      setLastTemplates(
        newPredefinicao.templates ? JSON.parse(newPredefinicao.templates) : []
      );
      setLastGroupHosts(
        newPredefinicao.groups ? JSON.parse(newPredefinicao.groups) : []
      );
      setLastMacros(
        newPredefinicao.macros
          ? JSON.parse(newPredefinicao.macros)
          : [{ macro: "", valor: "" }]
      );
    }
  }, [predefinicaoId]);

  async function handleApiGetPredefinicoes() {
    try {
      const response = await api.get("/getPredefinicoes");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Predefinicoes",
          severity: "error",
        });
      } else {
        setFabricantes(response.data);
        savePredefinicoes(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleEditaPredefinicoes() {
    try {
      const response = await api.put("/putPredefinicao", {
        id: predefinicaoId,
        templates: JSON.stringify(templates),
        macros: JSON.stringify(macros),
        groups: JSON.stringify(groupHosts),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children:
            "Error: Não foi possível salvar as predefinicoes, verifique se você selecionou um modelo corretamente",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updatePredefinicoes(response.data);
        setLastGroupHosts(groupHosts);
        setLastTemplates(templates);
        setLastMacros(macros);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  const handleApiCriaFabricante = async () => {
    try {
      const response = await api.post("/postFabricante", {
        fabricante,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível adicionar o fabricante",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        let aux = response.data;
        aux.Funcoes = [];
        createFabricantes(aux);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };

  async function handleApiGetTemplates() {
    try {
      const response = await api.get("/getTemplates");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar templates",
          severity: "error",
        });
      } else {
        setTemplatesList(response.data || []);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  function expandeNovoModelo(novoModelo, funcaoId) {
    let aux = expanded;
    const funcaoExpandida = expanded.find((value) => value === funcaoId);
    if (!funcaoExpandida) {
      aux.push(funcaoId);
      setExpanded(aux);
    }
    setSelected(novoModelo.id);
    setPredefinicaoId(novoModelo.Predefinicoes.id);
    setNomeModelo(novoModelo.modelo);
  }

  function expandeNovaFuncao(novoValor) {
    let aux = expanded;
    const funcaoExpandida = expanded.find((value) => value === novoValor.id);
    if (!funcaoExpandida) {
      aux.push(novoValor.id);
      setExpanded(aux);
    }
    setSelected(novoValor.id);
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };
  return (
    <Box sx={styles.container}>
      <Stack direction={"row"}>
        <Box sx={styles.predefinicoesColumn}>
          <Paper sx={styles.predefinicoesPaper}>
            {" "}
            <Stack direction={"column"} gap={"20px"}>
              <Typography color={"primary"} fontWeight={500}>
                Predefinições
              </Typography>
              <TextField
                id="Fabricante"
                variant="standard"
                {...params}
                label="Filtra fabricante"
                fullWidth={true}
                value={filtro}
                onChange={(event) => {
                  setFiltro(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  setOpenEditModal(true);
                }}
              >
                Adicionar Fabricante
              </Button>
              <Divider />
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Button
                  onClick={handleExpand}
                  startIcon={<KeyboardDoubleArrowDown />}
                >
                  {" "}
                  Expandir
                </Button>
                <Button
                  onClick={() => {
                    setExpanded([]);
                  }}
                  startIcon={<KeyboardDoubleArrowUp />}
                >
                  {" "}
                  Recolher
                </Button>
              </Stack>
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                onNodeToggle={(event, nodeIds) => {
                  setExpanded(nodeIds);
                }}
                onNodeSelect={handleSelect}
                expanded={expanded}
                selected={selected}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                  flexGrow: 1,
                  maxHeight: "calc(100vh - 430px)",
                  width: "250px",
                }}
                defaultExpanded={["root"]}
              >
                {Array.isArray(fabricantes)
                  ? fabricanteFiltred.map((node, index) => (
                      <TreeItemFabricante
                        key={node.id}
                        nodeId={node.id}
                        label={node.fabricante}
                        node={node}
                        deleteFabricante={deleteFabricante}
                        updateFabricantes={updateFabricantes}
                        createPredefinicoes={createPredefinicoes}
                        expandeNovoModelo={expandeNovoModelo}
                        expandeNovaFuncao={expandeNovaFuncao}
                        setPredefinicaoId={setPredefinicaoId}
                        setNomeModelo={setNomeModelo}
                      />
                    ))
                  : null}
              </TreeView>
            </Stack>
          </Paper>
        </Box>
        <Fade in={selecionado} {...(selecionado ? { timeout: 500 } : {})}>
          {nomeModelo ? (
            <Box>
              <Paper sx={styles.formPaper}>
                <Stack
                  direction={"row"}
                  width={"500px"}
                  justifyContent="space-between"
                >
                  <Typography
                    color={"primary"}
                    mt={3}
                    mb={3}
                    fontWeight={500}
                    fontSize={20}
                  >
                    {`Modelo: ${nomeModelo}`}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    {editar ? (
                      <Tooltip title="salvar">
                        <IconButton
                          variant="contained"
                          color="primary"
                          sx={{
                            width: "40px",
                            height: "40px",
                          }}
                          onClick={async () => {
                            handleEditaPredefinicoes();
                            setEditar(false);
                          }}
                        >
                          <Disquete />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="editar">
                        <IconButton
                          variant="contained"
                          color="primary"
                          sx={styles.StyleButtons}
                          onClick={(e) => {
                            setEditar(true);
                          }}
                        >
                          <Caneta />
                        </IconButton>
                      </Tooltip>
                    )}
                    {editar && (
                      <Tooltip title="cancelar">
                        <IconButton
                          variant="contained"
                          color="secondary"
                          sx={styles.StyleButtons}
                          onClick={(e) => {
                            setEditar(false);
                            setMacros(lastMacros);
                            setGroupHosts(lastGroupHosts);
                            setTemplates(lastTemplates);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
                <Stack direction={"column"} gap={"10px"} width={"500px"}>
                  <Typography color={"primary"} fontWeight={500}>
                    Grupo de Hosts
                  </Typography>

                  {!editar ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      gap={"5px"}
                      component="ul"
                    >
                      {groupHosts.map((grupo, index) => (
                        <li margin={0.5} key={index}>
                          <Chip color="primary" label={grupo.name} />
                        </li>
                      ))}
                    </Box>
                  ) : null}
                  {editar ? (
                    <Autocomplete
                      multiple
                      id="grupo-hosts-autocomplete"
                      isOptionEqualToValue={(option, value) =>
                        option.groupid === value.groupid
                      }
                      disableCloseOnSelect
                      value={groupHosts}
                      defaultValue={[]}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            groupid: `${
                              options.length + groupHosts.length + 2
                            }`,
                            name: inputValue,
                          });
                        }
                        return filtered;
                      }}
                      onChange={(event, newValue) => {
                        {
                          /* const isExisting = groupHostList.some(
                          (option) =>
                            option.name === newValue[newValue.length - 1].name
                        );
                        if (!isExisting) {
                          // adcionar novo grupo de host a lista de grupos de hosts
                        }*/
                        }
                        if (newValue && newValue.name) {
                          setGroupHosts({
                            nome: newValue.inputValue,
                          });
                        } else {
                          setGroupHosts(newValue);
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option.name;
                      }}
                      sx={styles.filter}
                      options={[]}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Grupos"
                          variant="standard"
                        />
                      )}
                    />
                  ) : null}

                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography color={"primary"} fontWeight={500}>
                      Templates
                    </Typography>
                  </Stack>
                  {!editar ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      gap={"5px"}
                      component="ul"
                    >
                      {templates.map((template, index) => (
                        <li margin={0.5} key={index}>
                          <Chip color="primary" label={template.name} />
                        </li>
                      ))}
                    </Box>
                  ) : null}
                  {editar ? (
                    <Autocomplete
                      multiple
                      id="templates-autocomplete"
                      isOptionEqualToValue={(option, value) =>
                        option.templateid === value.templateid
                      }
                      disableCloseOnSelect
                      value={templates}
                      defaultValue={[]}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.inputValue) {
                          setTemplates({
                            nome: newValue.inputValue,
                          });
                        } else {
                          setTemplates(newValue);
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        return option.name;
                      }}
                      sx={styles.filter}
                      options={templatesList}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Templates"
                          variant="standard"
                        />
                      )}
                    />
                  ) : null}
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography color={"primary"} fontWeight={500}>
                      Macros
                    </Typography>

                    {editar && (
                      <IconButton onClick={addMacro}>
                        {editar && <Add color="primary" />}
                      </IconButton>
                    )}
                  </Stack>
                  {!editar ? (
                    <Stack
                      direction="column"
                      justifyContent={"space-around"}
                      // alignItems={"flex-start"}
                      minWidth={"400px"}
                      // gap={"10px"}
                      marginBottom={"10px"}
                    >
                      {macros.map((macro, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          key={index}
                          gap={"10px"}
                        >
                          <Box sx={styles.MacroCampo}>{macro.macro}</Box>
                          <Box sx={styles.MacroCampo}>{macro.valor}</Box>
                        </Stack>
                      ))}
                    </Stack>
                  ) : null}
                  {editar ? (
                    <>
                      {macros.map((macro, index) => (
                        <Stack
                          key={index}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <TextField
                            id="macro-textfield"
                            name="macro"
                            variant="standard"
                            label="Macro"
                            sx={styles.textfield}
                            value={macros[index].macro}
                            onChange={(event) =>
                              handleMacroChange(index, event)
                            }
                          />
                          <TextField
                            id="valor-textfield"
                            name="valor"
                            variant="standard"
                            label="Valor"
                            value={macros[index].valor}
                            sx={styles.textfield}
                            onChange={(event) =>
                              handleMacroChange(index, event)
                            }
                          />
                          {macros.length > 1 && (
                            <IconButton
                              onClick={() => removeMacro(index)}
                              sx={{ mt: 3 }}
                            >
                              <Delete color="secondary" />
                            </IconButton>
                          )}
                        </Stack>
                      ))}
                    </>
                  ) : null}
                </Stack>
              </Paper>
            </Box>
          ) : null}
        </Fade>
      </Stack>
      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle color={"primary"}>Adicionar fabricante</DialogTitle>
        <DialogContent>
          <TextField
            id="fabricante"
            variant="standard"
            label="Fabricante"
            value={fabricante}
            onChange={(event) => {
              setFabricante(event.target.value);
            }}
            sx={{ width: "240px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenEditModal(false);
              setFabricante("");
            }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              handleApiCriaFabricante();
              setFabricante("");
              setOpenEditModal(false);
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
