import {
  Add,
  Close,
  Dangerous,
  Delete,
  DesignServices,
  DesignServicesOutlined,
  Edit,
  GppMaybe,
  GppMaybeOutlined,
  MoreVert,
  Save,
  Star,
  StarBorder,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";

import ProductRow from "../productRow";
import EditProduct from "./editProduct";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    width: "52vh",

    padding: "20px",
    justifyContent: "center",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "primary.main" },
};

export default function AccordionGroup(props) {
  const { cliente, atualizarCliente, deletarCliente } = props;

  const [snackbar, setSnackbar] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [nome, setNome] = useState("");
  const [tipo, setTipo] = useState("");
  const [nomeEdit, setNomeEdit] = useState(cliente.empresa);
  const [numero, setNumero] = useState(cliente.numero);
  const [editable, setEditable] = useState(true);
  const [confirmEditModal, setconfirmEditModal] = useState(false);
  const [confirmarLoading, setConfirmarLoading] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [proritario, setProritario] = useState(
    cliente?.classificacao?.proritario || false
  );
  const [exigente, setExigente] = useState(
    cliente?.classificacao?.exigente || false
  );
  const [customizado, setCustomizado] = useState(
    cliente?.classificacao?.customizado || false
  );

  const usuarioId = JSON.parse(localStorage.getItem("1")).refreshToken
    .usuarioId;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function atualizarProduto(novoProduto) {
    let aux = cliente;
    const indexProduto = aux.produto.findIndex(
      (produto) => produto.id === novoProduto.id
    );
    aux.produto[indexProduto] = novoProduto;

    atualizarCliente(aux);
  }

  function excluirProduto(novoProduto) {
    let aux = cliente;

    const indexProduto = aux.produto.findIndex(
      (produto) => produto.id === novoProduto.id
    );

    aux.produto.splice(indexProduto, 1);

    atualizarProduto(aux);
  }

  function clearModal() {
    setNome("");
    setTipo("");
  }

  useEffect(() => {
    if (nome !== "" && tipo !== "") {
      setConfirmarLoading(false);
    } else {
      setConfirmarLoading(true);
    }
  }, [nome, tipo]);

  async function handleApi() {
    try {
      setConfirmarLoading(true);

      const response = await api.post("/criarProdutoAcesso", {
        nome,
        tipo,
        acessoClientesId: cliente.id,
        usuarioId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival salvar os dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });

        if (response.data.id) {
          let aux = cliente;
          aux.produto.push(response.data);

          atualizarCliente(aux);
        }

        setConfirmarLoading(false);
        clearModal();
        setOpenModal(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });

      console.error(error);

      setConfirmarLoading(false);
    }
  }

  async function handleApiDelete() {
    try {
      setConfirmarDelete(true);
      const response = await api.post("/deleteAcesso", { cliente, usuarioId });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possival excluir os dados",
          severity: "error",
        });
        setConfirmarDelete(false);
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });

        setConfirmarDelete(false);
        deletarCliente(response.data.clientes.id);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      setConfirmarDelete(false);
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor ",
        severity: "error",
      });

      handleClose();

      console.error(error);
    }
  }

  async function handleApiEdit() {
    try {
      setconfirmEditModal(false);
      const response = await api.put("/putAcesso", {
        id: cliente.id,
        empresa: nomeEdit,
        numero: numero,
        usuarioId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: `Error: não foi possival salvar dados, numero ja existente `,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });

        let aux = {
          ...cliente,
          empresa: response.data.empresa,
          numero: response.data.numero,
        };
        atualizarCliente(aux);
      }

      setEditable(true);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }

  async function handleUpdateClassificacao(classificacaoTipo) {
    try {
      const response = await api.put("/acessos/classificacao", {
        id: cliente.id,
        classificacao: {
          proritario:
            classificacaoTipo == "proritario" ? !proritario : proritario,
          exigente: classificacaoTipo == "exigente" ? !exigente : exigente,
          customizado:
            classificacaoTipo == "customizado" ? !customizado : customizado,
        },
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: `Error: não foi possival salvar dados, numero ja existente `,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados atualizados com sucesso",
          severity: "success",
        });
        let aux = cliente;
        aux.classificacao = {
          proritario,
          exigente,
          customizado,
        };
        atualizarCliente(aux);

        switch (classificacaoTipo) {
          case "prioritario":
            setProritario(!proritario);

            break;
          case "exigente":
            setExigente(!exigente);
            break;
          case "customizado":
            setCustomizado(!customizado);
            break;

          default:
            break;
        }
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }
  return (
    <>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Stack direction={"row"} alignItems="center">
          <Typography component="div" variant="h6">
            {cliente.numero}
            {" - "}
            {cliente.empresa}
          </Typography>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Tooltip title="Prioritário">
            {proritario ? (
              <Star fontSize="large" sx={{ color: "iconsAcesso.proritario" }} />
            ) : (
              <StarBorder fontSize="large" color="disabled" />
            )}
          </Tooltip>
          <Tooltip title="Exigente">
            {exigente ? (
              <GppMaybe
                fontSize="large"
                sx={{ color: "iconsAcesso.exigente" }}
              />
            ) : (
              <GppMaybeOutlined fontSize="large" color="disabled" />
            )}
          </Tooltip>
          <Tooltip title="Customizado">
            {customizado ? (
              <DesignServices
                fontSize="large"
                sx={{ color: "iconsAcesso.customizado" }}
              />
            ) : (
              <DesignServicesOutlined
                fontSize="large"
                //sx={{ color: "iconsAcesso.customizado" }}
                color="disabled"
              />
            )}
          </Tooltip>
        </Stack>
      </Stack>

      {cliente.produto &&
        cliente.produto.map((produto, index) => {
          return (
            <Box key={produto.id}>
              <ProductRow
                produto={produto}
                clienteId={cliente.id}
                atualizarProduto={atualizarProduto}
                key={index}
              />
            </Box>
          );
        })}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenModal(true);
          }}
        >
          <ListItemIcon>
            <Add fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Novo</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenEditModal(true);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        id="dialog-create-modal"
        open={openModal}
        onClose={() => {
          clearModal();
          setOpenModal(false);
        }}
        sx={styles.modal}
      >
        <DialogTitle sx={styles.DialogTitle}>
          {"Criar novo acesso !"}
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap={"wrap"}
          >
            <TextField
              variant="standard"
              label="Nome"
              value={nome}
              required
              onChange={(event) => {
                setNome(event.target.value);
              }}
              sx={styles.TextField}
            />
            <FormControl sx={styles.TextField} variant="standard" required>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={tipo}
                label="Tipo"
                onChange={(event) => {
                  setTipo(event.target.value);
                }}
              >
                <MenuItem value={"easymon"}>EasyMon</MenuItem>
                <MenuItem value={"easyflow"}>EasyFlow</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              clearModal();
              setOpenModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus onClick={handleApi} disabled={confirmarLoading}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="dialog-edit-modal"
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sx={styles.modal}
      >
        <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
          <DialogTitle sx={styles.DialogTitle}>
            {"Editar dados da empresa."}
          </DialogTitle>
          <DialogContent>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems="center"
              justifyContent="flex-start"
            >
              <TextField
                variant="standard"
                label="Nome"
                sx={styles.TextField}
                value={nomeEdit}
                onChange={(event) => {
                  setNomeEdit(event.target.value);
                }}
                disabled={editable}
              />

              <TextField
                variant="standard"
                label="Número"
                type={"number"}
                sx={{ width: "100px" }}
                value={numero}
                onChange={(event) => {
                  setNumero(event.target.value);
                }}
                disabled={editable}
              />
              <Box>
                {editable && (
                  <>
                    <Tooltip
                      title={"Editar"}
                      onClick={() => {
                        setEditable(!editable);
                      }}
                    >
                      <IconButton>
                        <Edit color={"primary"} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {!editable && (
                  <>
                    <Tooltip
                      title={"Salvar"}
                      onClick={() => {
                        setconfirmEditModal(true);
                      }}
                    >
                      <IconButton>
                        <Save color={"primary"} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={"Cancelar"}
                      onClick={() => {
                        setNumero(cliente.numero);
                        setNomeEdit(cliente.empresa);
                        setEditable(true);
                      }}
                    >
                      <IconButton>
                        <Close color={"secondary"} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            </Stack>
          </DialogContent>
          <DialogTitle sx={styles.DialogTitle}>
            Classifique a empresa.
          </DialogTitle>
          <DialogContent>
            <Tooltip title="Prioritário">
              <IconButton
                onClick={() => {
                  handleUpdateClassificacao("prioritario");
                }}
              >
                {proritario ? (
                  <Star
                    fontSize="large"
                    sx={{ color: "iconsAcesso.proritario" }}
                  />
                ) : (
                  <StarBorder fontSize="large" color="disabled" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Exigente">
              <IconButton
                onClick={() => {
                  handleUpdateClassificacao("exigente");
                }}
              >
                {exigente ? (
                  <GppMaybe
                    fontSize="large"
                    sx={{ color: "iconsAcesso.exigente" }}
                  />
                ) : (
                  <GppMaybeOutlined fontSize="large" color="disabled" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Customizado">
              <IconButton
                onClick={() => {
                  handleUpdateClassificacao("customizado");
                }}
              >
                {customizado ? (
                  <DesignServices
                    fontSize="large"
                    sx={{ color: "iconsAcesso.customizado" }}
                  />
                ) : (
                  <DesignServicesOutlined
                    fontSize="large"
                    //sx={{ color: "iconsAcesso.customizado" }}
                    color="disabled"
                  />
                )}
              </IconButton>
            </Tooltip>
          </DialogContent>
          <DialogTitle sx={styles.DialogTitle}>
            {"Editar dados dos produtos."}
          </DialogTitle>
          <DialogContent>
            {cliente.produto
              .sort((a, b) => b.tipo.localeCompare(a.tipo))
              .map((produto, index) => {
                return (
                  <Box key={index} sx={{ marginBottom: "10px" }}>
                    <EditProduct
                      produto={produto}
                      atualizarProduto={atualizarProduto}
                      excluirProduto={excluirProduto}
                      key={index}
                    />
                  </Box>
                );
              })}
          </DialogContent>
        </Box>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditModal(false);
              setNumero(cliente.numero);
              setNomeEdit(cliente.empresa);
              setEditable(true);
            }}
          >
            voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Dangerous color={"error"} sx={{ fontSize: 60 }} />
            </Box>
            Uma vez após excluidos os dados não podem ser recuperados. Tem
            certeza que de deseja fazer essa exclusão ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={{ width: "100px", fontWeight: "800" }}
            onClick={handleApiDelete}
            disabled={confirmarDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmEditModal}
        onClose={() => {
          setconfirmEditModal(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setconfirmEditModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus onClick={handleApiEdit}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
