import {
  Box,
  Paper,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  Typography,
  Stack,
  TextField,
  Divider,
  Autocomplete,
  Fade,
  LinearProgress,
} from "@mui/material";

import { useEffect, useState } from "react";
import PaginaInicial from "./paginaInicial";
import AdicaoEmMassa from "./adicaoEmMassa";
import ImportarCsv from "./importarCsv";
import api from "../../services/api";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    marginTop: "30px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "84vw",
    maxWidth: "1550px",
    flexDirection: "column",
  },
  paperHosts: {
    padding: "0px 20px",
    paddingBottom: "20px",
    borderRadius: 1,
    width: "84vw",
    maxWidth: "1550px",
  },
  box: { width: "100%" },
  titles: { fontWeight: 500 },
  tab: { minWidth: 0, padding: 0, mr: 2, ml: 2 },
  tabs: { gap: 2, display: "flex" },
};

export default function AreaDoCliente(props) {
  const [tabValue, setTabValue] = useState(0);
  //cliente e produto
  const [cliente, setCliente] = useState();
  const [clientes, setClientes] = useState([]);
  const [predefinicoes, setPredefinicoes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [tipo, setTipo] = useState("easymon");
  const [easyMon, setEasyMon] = useState(null);
  const [proxys, setProxys] = useState([]);
  const [rows, setRows] = useState([]);
  //pagina
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(true);
  //acessos id's
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [acessoProdutoId, setAcessoProdutoId] = useState("");
  //buttons
  const [disableButtonEasyMon, setDisableButtonEasyMon] = useState(true);
  const [disableButtonCreate, setDisableButtonCreate] = useState(true);
  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetClientes();
  }, []);
  useEffect(() => {
    if (tabValue === 0) {
      handleApiGetPredefinicoes();
    }
  }, [tabValue]);

  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;

  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyMon(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
    if (cliente && easyMon) {
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
    } else {
      setRows([]);
    }
  }, [cliente, easyMon]);
  async function handleApiGetHosts(acessoClientesId, acessoProdutoId) {
    {
      try {
        setDisableButtonCreate(true);
        setLoadingPage(true);
        const response = await api.post("/zabbix/hosts/findManyPingSnmp", {
          acessoClientesId,
          acessoProdutoId,
        });

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível buscar dados do zabbix",
            severity: "error",
          });
          setLoadingPage(false);
        } else {
          //console.log(response.data);
          setRows(response.data.hosts);
          setProxys(response.data.proxys);
          setDisableButtonCreate(false);
          setSnackbar({
            children: "Dados encontrados com sucesso",
            severity: "success",
          });
          setLoadingPage(false);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });

        console.error(error);
        setLoadingPage(false);
      }
    }
  }

  useEffect(() => {
    if (cliente && easyMon) {
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
    } else {
      setRows([]);
      setDisableButtonCreate(true);
    }
  }, [cliente, easyMon, acessoClientesId, acessoProdutoId]);

  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/getPredefinicoes");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os dados",
          severity: "error",
        });
        setLoadingPredefinicoes(false);
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data);
        }
        setLoadingPredefinicoes(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
      setLoadingPredefinicoes(false);
    }
  }
  //busca clientes
  async function handleApiGetClientes() {
    try {
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperCliente}>
          <Box>
            <Stack direction={"row"} gap={4}>
              <Box>
                <Typography
                  color={"primary"}
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  Selecione um Cliente
                </Typography>
                <Autocomplete
                  sx={{ m: 1, width: "180px", ml: 0 }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    //console.log(newValue.id);
                    setEasyMon(null);
                    setAcessoProdutoId("");
                    setRows([]);
                  }}
                  value={cliente}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={disableButtonEasyMon}
                  sx={{ m: 1, mt: 4.8, width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue.id);
                    //console.log(newValue.id);
                  }}
                  value={easyMon}
                  options={produtos}
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingPage}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Box>{" "}
        </Paper>
        <Paper sx={{ borderRadius: 4, padding: "0px 10px" }}>
          {" "}
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Tabs
              scrollButtons={true}
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
              sx={styles.tabs}
            >
              <Tab
                label={"Hosts"}
                iconPosition="start"
                value={0}
                sx={styles.tab}
              />
              <Tab
                label={"Adição Em Massa"}
                iconPosition="start"
                value={1}
                sx={styles.tab}
              />
              <Tab
                label={"Importar"}
                iconPosition="start"
                value={2}
                sx={styles.tab}
              />
            </Tabs>
          </Stack>
        </Paper>
        <Paper sx={styles.paperHosts}>
          <Stack direction={"column"} justifyContent={"space-between"}>
            <Box sx={styles.box}>
              {tabValue === 0 && (
                <PaginaInicial
                  loadingPage={loadingPage}
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  rows={rows}
                  setRows={setRows}
                  cliente={cliente}
                  handleApiGetHosts={handleApiGetHosts}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                  key={1}
                />
              )}
              {tabValue === 1 && (
                <AdicaoEmMassa
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  key={2}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                />
              )}
              {tabValue === 2 && (
                <ImportarCsv
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                  key={3}
                />
              )}
            </Box>
          </Stack>
        </Paper>
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
